import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Form, Button, message } from 'antd'
import { unsubscribeEmail } from '../../redux/common'

class UnsubscribeEmail extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)
  }

  get token () {
    return queryString.parse(this.props.location.search).token
  }

  componentDidMount () {
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickUnsubscribeEmail()
        }
      }
    }
  }

  componentWillUnmount () {
    if (window) {
      window.onkeypress = null
    }
  }

  handleClickUnsubscribeEmail = () => {
    const token = this.token

    this.props.dispatch(unsubscribeEmail({token}))
      .then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          message.success({
            content: '邮件退订成功',
            onClose: () => this.props.history.replace('/')
          })
        }
      }).catch((error) => {
        return message.error(`未知异常:${error}`)
      })
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  render () {
    return (
      <div className="area unsubscribe">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Button type='primary' block onClick={this.handleClickUnsubscribeEmail}>点击退订邮件</Button>
          </Form>
        </div>
      </div>
    )
  }
}

UnsubscribeEmail = connect()(UnsubscribeEmail)

export default UnsubscribeEmail
