const GET_WATCHES_ING = 'WATCH/GET_WATCHES_ING'
const GET_WATCHES_SUCCESS = 'WATCH/GET_WATCHES_SUCCESS'
const GET_WATCHES_FAILURE = 'WATCH/GET_WATCHES_FAILURE'

const GET_WATCH_DETAIL_ING = 'WATCH/GET_WATCH_DETAIL_ING'
const GET_WATCH_DETAIL_SUCCESS = 'WATCH/GET_WATCH_DETAIL_SUCCESS'
const GET_WATCH_DETAIL_FAILURE = 'WATCH/GET_WATCH_DETAIL_FAILURE'

const CREATE_WATCH_ING = 'WATCH/CREATE_WATCH_ING'
const CREATE_WATCH_SUCCESS = 'WATCH/CREATE_WATCH_SUCCESS'
const CREATE_WATCH_FAILURE = 'WATCH/CREATE_WATCH_FAILURE'

const REMOVE_WATCH_ING = 'WATCH/REMOVE_WATCH_ING'
const REMOVE_WATCH_SUCCESS = 'WATCH/REMOVE_WATCH_SUCCESS'
const REMOVE_WATCH_FAILURE = 'WATCH/REMOVE_WATCH_FAILURE'

const CHANGE_WATCH_ALIAS_ING = 'WATCH/CHANGE_WATCH_ALIAS_ING'
const CHANGE_WATCH_ALIAS_SUCCESS = 'WATCH/CHANGE_WATCH_ALIAS_SUCCESS'
const CHANGE_WATCH_ALIAS_FAILURE = 'WATCH/CHANGE_WATCH_ALIAS_FAILURE'

const CHANGE_WATCH_STATUS_ING = 'WATCH/CHANGE_WATCH_STATUS_ING'
const CHANGE_WATCH_STATUS_SUCCESS = 'WATCH/CHANGE_WATCH_STATUS_SUCCESS'
const CHANGE_WATCH_STATUS_FAILURE = 'WATCH/CHANGE_WATCH_STATUS_FAILURE'

const defaultState = {
  watches: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_WATCHES_SUCCESS:
      return {
        ...state,
        watches: action.payload
      }
    case GET_WATCHES_FAILURE:
      return {
        ...state,
        watches: {
          count: 0,
          rows: []
        }
      }
    case GET_WATCH_DETAIL_SUCCESS:
      return {
        ...state,
        watchDetail: action.payload
      }
    case GET_WATCH_DETAIL_FAILURE:
      return {
        ...state,
        watchDetail: null
      }
    default:
      return { ...state }
  }
}

export const getWatches = ({ searchContent, offset, limit, sortKey, sortOrder }) => {
  return {
    types: [GET_WATCHES_ING, GET_WATCHES_SUCCESS, GET_WATCHES_FAILURE],
    promise: (apiClient) => apiClient.get('/api/watches', { params: { searchContent, offset, limit, sortKey, sortOrder } }),
    offset,
    limit
  }
}

export const getWatchDetail = ({ watchId }) => {
  return {
    types: [GET_WATCH_DETAIL_ING, GET_WATCH_DETAIL_SUCCESS, GET_WATCH_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/watches/${watchId}`),
    watchId
  }
}

export const createWatch = ({ domain, ip, port = 443 }) => {
  const body = {domain, port}
  if (ip) {
    body.ip = ip
  }
  return {
    types: [CREATE_WATCH_ING, CREATE_WATCH_SUCCESS, CREATE_WATCH_FAILURE],
    promise: (apiClient) => apiClient.post('/api/watches', body)
  }
}

export const removeWatch= ({watchId}) => {
  return {
    types: [REMOVE_WATCH_ING, REMOVE_WATCH_SUCCESS, REMOVE_WATCH_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/watches/${watchId}`)
  }
}

export const changeWatchStatus = (watchId, status) => {
  return {
    types: [CHANGE_WATCH_STATUS_ING, CHANGE_WATCH_STATUS_SUCCESS, CHANGE_WATCH_STATUS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/watches/${watchId}/status`, {status})
  }
}

export const changeWatchAlias = (watchId, alias) => {
  return {
    types: [CHANGE_WATCH_ALIAS_ING, CHANGE_WATCH_ALIAS_SUCCESS, CHANGE_WATCH_ALIAS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/watches/${watchId}/alias`, {alias})
  }
}
