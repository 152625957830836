import Default from './views/index/Default'
import Signin from './views/index/Signin'
import Signup from './views/index/Signup'
import Forget from './views/index/Forget'
import ResetPwd from './views/index/ResetPwd'
import OAuth from './views/index/OAuth'
import UnsubscribeEmail from './views/index/UnsubscribeEmail'

import Dashboard from './views/monitor/Dashboard'
import Certificates from './views/monitor/Certificates'
import DnsServers from './views/monitor/DnsServers'
import CloudServers from './views/monitor/CloudServers'
import CertificateDetail from './views/monitor/CertificateDetail'
import DnsServerDetail from './views/monitor/DnsServerDetail'
import CloudServerDetail from './views/monitor/CloudServerDetail'
import CertificateVersion from './views/monitor/CertificateVersion'
import BeanTransactions from './views/monitor/BeanTransactions'
import MyInvitations from './views/monitor/MyInvitations'
import Watches from './views/monitor/Watches'
import WatchDetail from './views/monitor/WatchDetail'
import WatchRecord from './views/monitor/WatchRecord'
import Notifications from './views/monitor/Notifications'

import CreateCertificate from './views/guide/CreateCertificate'
import CreateCloudServer from './views/guide/CreateCloudServer'

const routes = {
  index: [
    {
      path: '/signin',
      name: '登录',
      component: Signin,
      layout: ''
    },
    {
      path: '/signup',
      name: '注册',
      component: Signup,
      layout: ''
    },
    {
      path: '/forget',
      name: '忘记密码',
      component: Forget,
      layout: ''
    },
    {
      path: '/reset',
      name: '重置密码',
      component: ResetPwd,
      layout: ''
    },
    {
      path: '/oauth',
      name: '第三方登录',
      component: OAuth,
      layout: ''
    },
    {
      path: '/unsubscribe',
      name: '邮件退订',
      component: UnsubscribeEmail,
      layout: ''
    },
    {
      path: '/',
      name: '主页',
      component: Default,
      layout: ''
    }
  ],
  monitor: [
    {
      path: '/dashboard',
      name: '用户中心',
      icon: 'pie-chart',
      component: Dashboard,
      layout: '/monitor',
      menuKey: '/dashboard'
    },
    {
      path: '/certificates/:certificateId/versions/:versionId',
      name: '证书更新详情',
      component: CertificateVersion,
      layout: '/monitor',
      menuKey: '/certificates'
    },
    {
      path: '/certificates/:certificateId',
      name: '证书详情',
      component: CertificateDetail,
      layout: '/monitor',
      menuKey: '/certificates'
    },
    {
      path: '/certificates',
      name: '证书管理',
      icon: 'audit',
      component: Certificates,
      layout: '/monitor',
      menuKey: '/certificates'
    },
    {
      path: '/dnsservers/:dnsServerId',
      name: 'DNS授权详情',
      component: DnsServerDetail,
      layout: '/monitor',
      menuKey: '/dnsservers'
    },
    {
      path: '/dnsservers',
      name: 'DNS授权',
      icon: 'global',
      component: DnsServers,
      layout: '/monitor',
      menuKey: '/dnsservers'
    },
    {
      path: '/cloudservers/:cloudServerId',
      name: '部署节点详情',
      component: CloudServerDetail,
      layout: '/monitor',
      menuKey: '/cloudservers'
    },
    {
      path: '/cloudservers',
      name: '部署节点',
      icon: 'cloud-upload',
      component: CloudServers,
      layout: '/monitor',
      menuKey: '/cloudservers'
    },
    {
      path: '/watches/:watchId/records/:recordId',
      name: '证书监测详情',
      component: WatchRecord,
      layout: '/monitor',
      menuKey: '/watches'
    },
    {
      path: '/watches/:watchId',
      name: '证书监控详情',
      component: WatchDetail,
      layout: '/monitor',
      menuKey: '/watches'
    },
    {
      path: '/watches',
      name: '证书监控',
      icon: 'eye',
      component: Watches,
      layout: '/monitor',
      menuKey: '/watches'
    },
    {
      path: '/notifications',
      name: '通知管理',
      icon: 'message',
      component: Notifications,
      layout: '/monitor',
      menuKey: '/notifications'
    },
    {
      path: '/beantransactions',
      name: '费用明细',
      icon: 'account-book',
      component: BeanTransactions,
      layout: '/monitor',
      menuKey: '/beantransactions'
    },
    {
      path: '/invitations',
      name: '我的邀请',
      icon: 'share-alt',
      component: MyInvitations,
      layout: '/monitor',
      menuKey: '/invitations'
    }
  ],
  guide: [
    {
      path: '/createcertificate',
      name: '证书申请',
      component: CreateCertificate,
      layout: '/guide'
    },
    {
      path: '/createcloudserver',
      name: '部署配置',
      component: CreateCloudServer,
      layout: '/guide'
    }
  ]
}

export default routes
