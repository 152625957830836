import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Table, Button, message, Modal, Tooltip, Icon} from 'antd'
import {getCertificateVersions, revokeCertificateVersion} from '../redux/certificate/version'
import {CERTIFICATE_AUTH_MODE, CERTIFICATE_VERSION_STATUS} from '../utils/constant'

class CertificateVersions extends React.Component {
  static props = {
    certificateId: PropTypes.number,
    initRef: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      current: 1,
      offset: 0,
      limit: 10
    }
    this.searchCertificateVersions()
    if (props.initRef) {
      props.initRef(this)
    }
  }

  hasCertificateVersionInGenerating = () => {
    return !!this.timer
  }

  searchCertificateVersions = (state) => {
    const {offset, limit} = Object.assign({}, this.state, state)
    const {certificateId} = this.props
    this.props.dispatch(getCertificateVersions({certificateId, offset, limit})).then((response) => {
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.status === CERTIFICATE_VERSION_STATUS.QUEUING || item.status === CERTIFICATE_VERSION_STATUS.GENERATING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchCertificateVersions()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  get tableColumns () {
    return [{
      title: '更新时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm'),
      width: 142
    }, {
      title: '任务创建',
      dataIndex: 'isAuto',
      key: 'isAuto',
      render: (text, record) => record.isAuto ? '系统自动创建' : '用户手动创建',
      width: 98
    }, {
      title: '任务状态',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (text, record) => {
        if (record.status === CERTIFICATE_VERSION_STATUS.QUEUING) {
          let queueReason = ''
          if (record.isAuto) {
            queueReason = '为避免因系统创建的自动更新任务并发过高而触发证书颁发机构的接口限流，所以需要排队进行更新，自动更新任务预计将在数小时内完成，请您耐心等待'
          } else if (record.certificateAuthMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
            queueReason = '对于单个用户来说，所有通过免DNS授权方式申请的证书，其验证域名所有权指向的是同一个CNAME解析，当使用该方式并发申请或者更新证书时，会导致该CNAME解析最终指向的TXT类型解析记录过多，导致证书颁发机构验证域名所有权失败，所以需要排队进行申请或更新，请您耐心等待'
          }
          return (
            <Tooltip title={queueReason}>
              <span style={{color: 'orange'}}>排队中&nbsp;<Icon type="clock-circle" /></span>
            </Tooltip>
          )
        } else if (record.status === CERTIFICATE_VERSION_STATUS.GENERATING) {
          return (
            <Tooltip title={'证书更新任务执行中，预计需要 3 ～ 15 分钟，请您耐心等待'}>
              <span style={{color: '#1890ff'}}>更新中&nbsp;<Icon type="loading" /></span>
            </Tooltip>
          )
        } else if (record.status === CERTIFICATE_VERSION_STATUS.GENERATED) {
          if (record.isExpired) {
            return <span style={{color: 'gray'}}>已过期</span>
          } else {
            return <span style={{color: 'green'}}>已成功</span>
          }
        } else if (record.status === CERTIFICATE_VERSION_STATUS.REVOKED) {
          return <span style={{color: 'gray'}}>已吊销</span>
        } else if (record.status === CERTIFICATE_VERSION_STATUS.ERROR) {
          return <span style={{color: 'red'}}>已失败</span>
        }
      }
    }, {
      title: '失败信息',
      dataIndex: 'error',
      key: 'error',
      render: error => {
        error = error || '-'
        return (
          <Tooltip title={error}>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '700px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
              {error}
            </div>
          </Tooltip>
        )
      }
    }, {
      title: '过期时间',
      dataIndex: 'expiredTime',
      key: 'expiredTime',
      width: 142,
      render: expiredTime => expiredTime ? moment(expiredTime).format('YYYY-MM-DD HH:mm') : '-'
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        if (record.status === CERTIFICATE_VERSION_STATUS.GENERATED) {
          if (record.isExpired) {
            return (
              <>
                <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.id)} size='small' type='link'>查看证书文件</Button>
              </>
            )
          } else {
            return (
              <>
                <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.id)} size='small' type='link'>查看证书文件</Button>
                |
                <Button onClick={this.handleRevokeCertificate.bind(this, record.certificateId, record.id)} size='small' type='link'>吊销</Button>
              </>
            )
          }
        } else if (record.status === CERTIFICATE_VERSION_STATUS.REVOKED){
          return (
            <>
              <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.id)} size='small' type='link'>查看证书文件</Button>
            </>
          )
        } else {
          return <></>
        }
      }
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.versions.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchCertificateVersions({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchCertificateVersions({
      offset,
      limit
    })
  }

  handleRevokeCertificate = (certificateId, certificateVersionId) => {
    Modal.confirm({
      title: '确认要吊销该证书版本吗?',
      content: '吊销该证书版本会导致已使用该证书版本的网站无法使用HTTPS进行访问，请谨慎吊销',
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(revokeCertificateVersion({certificateId, certificateVersionId})).then((response) => {
          if (response.errorMsg) {
            return message.error(response.errorMsg)
          }
          message.success('吊销证书成功')
    
          this.searchCertificateVersions()
        })
      },
      onCancel: () => {
      },
    })
  }

  handleVersionDetail = (certificateId, certificateVersionId) => {
    this.props.history.push(`/monitor/certificates/${certificateId}/versions/${certificateVersionId}`)
  }

  render () {
    return (
      <Table
        columns={this.tableColumns}
        dataSource={this.tableData}
        size='small'
        scroll={{x: true}}
        pagination={{
          current: this.state.current,
          pageSize: this.state.limit,
          total: this.props.versions.count,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          showTotal: total => `共${total}条`,
          onChange: this.handleChangePage,
          onShowSizeChange: this.handleChangePageSize
        }}
      />
    )
  }
}

CertificateVersions = connect((state, props) => {
  const certificateId = props.certificateId
  return {
    versions: state.certificate.version[`versions_${certificateId}`] || {count: 0, rows: []}
  }
})(CertificateVersions)

CertificateVersions = withRouter(CertificateVersions)

export default CertificateVersions
