const GET_WATCH_RECORDS_ING = 'CERTIFICATE/VERSION/GET_WATCH_RECORDS_ING'
const GET_WATCH_RECORDS_SUCCESS = 'CERTIFICATE/VERSION/GET_WATCH_RECORDS_SUCCESS'
const GET_WATCH_RECORDS_FAILURE = 'CERTIFICATE/VERSION/GET_WATCH_RECORDS_FAILURE'

const CREATE_WATCH_RECORD_ING = 'CERTIFICATE/VERSION/CREATE_WATCH_RECORD_ING'
const CREATE_WATCH_RECORD_SUCCESS = 'CERTIFICATE/VERSION/CREATE_WATCH_RECORD_SUCCESS'
const CREATE_WATCH_RECORD_FAILURE = 'CERTIFICATE/VERSION/CREATE_WATCH_RECORD_FAILURE'

const GET_WATCH_RECORD_DETAIL_ING = 'CERTIFICATE/VERSION/GET_WATCH_RECORD_DETAIL_ING'
const GET_WATCH_RECORD_DETAIL_SUCCESS = 'CERTIFICATE/VERSION/GET_WATCH_RECORD_DETAIL_SUCCESS'
const GET_WATCH_RECORD_DETAIL_FAILURE = 'CERTIFICATE/VERSION/GET_WATCH_RECORD_DETAIL_FAILURE'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_WATCH_RECORDS_SUCCESS:
      return {
        ...state,
        [`records_${action.watchId}`]: action.payload
      }
    case GET_WATCH_RECORDS_FAILURE:
      return {
        ...state,
        [`records_${action.watchId}`]: {
          count: 0,
          rows: []
        }
      }
    case GET_WATCH_RECORD_DETAIL_SUCCESS:
      return {
        ...state,
        watchRecordDetail: action.payload
      }
    case GET_WATCH_RECORD_DETAIL_FAILURE:
      return {
        ...state,
        watchRecordDetail: null
      }
    default:
      return { ...state }
  }
}

export const getWatchRecords = ({watchId, offset, limit}) => {
  return {
    types: [GET_WATCH_RECORDS_ING, GET_WATCH_RECORDS_SUCCESS, GET_WATCH_RECORDS_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/watches/${watchId}/records`, {params: {offset, limit}}),
    watchId
  }
}

export const getWatchRecordDetail = ({watchId, watchRecordId}) => {
  return {
    types: [GET_WATCH_RECORD_DETAIL_ING, GET_WATCH_RECORD_DETAIL_SUCCESS, GET_WATCH_RECORD_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/watches/${watchId}/records/${watchRecordId}`)
  }
}

export const createWatchRecord = ({watchId}) => {
  return {
    types: [CREATE_WATCH_RECORD_ING, CREATE_WATCH_RECORD_SUCCESS, CREATE_WATCH_RECORD_FAILURE],
    promise: (apiClient) => apiClient.post(`/api/watches/${watchId}/records`)
  }
}
