import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditLabel from './EditLabel'
import {changeDnsServerAlias} from '../redux/dns/server'

class DnsServerAlias extends React.Component {
  static props = {
    showIcon: PropTypes.bool,
    dnsServerId: PropTypes.number,
    dnsServerAlias: PropTypes.string,
    onChangeComplete: PropTypes.func
  }

  render() {
    const {showIcon, dnsServerId, dnsServerAlias, onChangeComplete} = this.props

    return (
      <EditLabel 
        title='编辑DNS授权名称'
        minLength={2}
        maxLength={256}
        showIcon={showIcon}
        value={dnsServerAlias}
        onChangeDispatch={changeDnsServerAlias.bind(this, dnsServerId)}
        onChangeComplete={onChangeComplete}
      />
    )
  }
}

DnsServerAlias = connect()(DnsServerAlias)

export default DnsServerAlias
