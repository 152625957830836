const GET_BEANS_PODS_ING = 'BEAN/GET_BEANS_PODS_ING'
const GET_BEANS_PODS_SUCCESS = 'BEAN/GET_BEANS_PODS_SUCCESS'
const GET_BEANS_PODS_FAILURE = 'BEAN/GET_BEANS_PODS_FAILURE'

const CREATE_BEANS_POD_ORDER_ING = 'BEAN/CREATE_BEANS_POD_ORDER_ING'
const CREATE_BEANS_POD_ORDER_SUCCESS = 'BEAN/CREATE_BEANS_POD_ORDER_SUCCESS'
const CREATE_BEANS_POD_ORDER_FAILURE = 'BEAN/CREATE_BEANS_POD_ORDER_FAILURE'

const GET_BEANS_POD_ORDER_ING = 'BEAN/GET_BEANS_POD_ORDER_ING'
const GET_BEANS_POD_ORDER_SUCCESS = 'BEAN/GET_BEANS_POD_ORDER_SUCCESS'
const GET_BEANS_POD_ORDER_FAILURE = 'BEAN/GET_BEANS_POD_ORDER_FAILURE'

const GET_BEANS_TRANSACTIONS_ING = 'BEAN/GET_BEANS_TRANSACTIONS_ING'
const GET_BEANS_TRANSACTIONS_SUCCESS = 'BEAN/GET_BEANS_TRANSACTIONS_SUCCESS'
const GET_BEANS_TRANSACTIONS_FAILURE = 'BEAN/GET_BEANS_TRANSACTIONS_FAILURE'

const defaultState = {
  pods: [],
  transactions: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_BEANS_PODS_SUCCESS:
      return {
        ...state,
        pods: action.payload
      }
    case GET_BEANS_PODS_FAILURE:
      return {
        ...state,
        pods: []
      }
    case GET_BEANS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload
      }
    case GET_BEANS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: []
      }
    default:
      return {...state}
  }
}

export const getBeansPods = () => {
  return {
    types: [GET_BEANS_PODS_ING, GET_BEANS_PODS_SUCCESS, GET_BEANS_PODS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/bean/pods')
  }
}

export const createBeansPodOrder = ({podId, paymentType}) => {
  return {
    types: [CREATE_BEANS_POD_ORDER_ING, CREATE_BEANS_POD_ORDER_SUCCESS, CREATE_BEANS_POD_ORDER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/bean/orders', {podId, paymentType})
  }
}

export const getBeansPodOrder = ({orderId}) => {
  return {
    types: [GET_BEANS_POD_ORDER_ING, GET_BEANS_POD_ORDER_SUCCESS, GET_BEANS_POD_ORDER_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/bean/orders/${orderId}`)
  }
}

export const getBeanTransactions = ({beginTime, endTime, showCharge, showConsume, showGift, showRefund, offset, limit}) => {
  return {
    types: [GET_BEANS_TRANSACTIONS_ING, GET_BEANS_TRANSACTIONS_SUCCESS, GET_BEANS_TRANSACTIONS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/bean/transactions', {params: {beginTime, endTime, showCharge, showConsume, showGift, showRefund, offset, limit}})
  }
}
