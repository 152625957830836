const GET_NOTIFICATIONS_CONFIG_ING = 'NOTIFICATION/CONFIG/GET_NOTIFICATIONS_CONFIG_ING'
const GET_NOTIFICATIONS_CONFIG_SUCCESS = 'NOTIFICATION/CONFIG/GET_NOTIFICATIONS_CONFIG_SUCCESS'
const GET_NOTIFICATIONS_CONFIG_FAILURE = 'NOTIFICATION/CONFIG/GET_NOTIFICATIONS_CONFIG_FAILURE'

const CHANGE_NOTIFICATIONS_CONFIG_ING = 'NOTIFICATION/CONFIG/CHANGE_NOTIFICATIONS_CONFIG_ING'
const CHANGE_NOTIFICATIONS_CONFIG_SUCCESS = 'NOTIFICATION/CONFIG/CHANGE_NOTIFICATIONS_CONFIG_SUCCESS'
const CHANGE_NOTIFICATIONS_CONFIG_FAILURE = 'NOTIFICATION/CONFIG/CHANGE_NOTIFICATIONS_CONFIG_FAILURE'

const defaultState = {
  notificationsConfig: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_CONFIG_SUCCESS:
    case CHANGE_NOTIFICATIONS_CONFIG_SUCCESS:
      return {
        ...state,
        notificationsConfig: action.payload
      }
    case GET_NOTIFICATIONS_CONFIG_FAILURE:
      return {
        ...state,
        notificationsConfig: []
      }
    default:
      return { ...state }
  }
}

export const getNotificationsConfig = () => {
  return {
    types: [GET_NOTIFICATIONS_CONFIG_ING, GET_NOTIFICATIONS_CONFIG_SUCCESS, GET_NOTIFICATIONS_CONFIG_FAILURE],
    promise: (apiClient) => apiClient.get('/api/notifications/config')
  }
}

export const changeNotificationConfig = ({ notificationTypeId, notificationEnabled, notificationTargetIds, systemEmailEnabled }) => {
  return {
    types: [CHANGE_NOTIFICATIONS_CONFIG_ING, CHANGE_NOTIFICATIONS_CONFIG_SUCCESS, CHANGE_NOTIFICATIONS_CONFIG_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/notifications/config/${notificationTypeId}`, {notificationEnabled, notificationTargetIds, systemEmailEnabled})
  }
}
