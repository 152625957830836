const GET_DNS_PROVIDERS_ING = 'DNS/PROVIDER/GET_DNS_PROVIDERS_ING'
const GET_DNS_PROVIDERS_SUCCESS = 'DNS/PROVIDER/GET_DNS_PROVIDERS_SUCCESS'
const GET_DNS_PROVIDERS_FAILURE = 'DNS/PROVIDER/GET_DNS_PROVIDERS_FAILURE'

const defaultState = {
  dnsProviders: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DNS_PROVIDERS_SUCCESS:
      return {
        ...state,
        dnsProviders: action.payload
      }
    case GET_DNS_PROVIDERS_ING:
    case GET_DNS_PROVIDERS_FAILURE:
      return {
        ...state,
        dnsProviders: []
      }
    default:
      return {...state}
  }
}

export const getDnsProviders = () => {
  return {
    types: [GET_DNS_PROVIDERS_ING, GET_DNS_PROVIDERS_SUCCESS, GET_DNS_PROVIDERS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/dns/providers')
  }
}
