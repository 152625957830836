import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditLabel from './EditLabel'
import { changeNotificationTargetAlias } from '../redux/notification/target'

class NotificationTargetAlias extends React.Component {
  static props = {
    showIcon: PropTypes.bool,
    notificationTargetId: PropTypes.number,
    notificationTargetAlias: PropTypes.string,
    onChangeComplete: PropTypes.func
  }

  render() {
    const {showIcon, notificationTargetId, notificationTargetAlias, onChangeComplete} = this.props

    return (
      <EditLabel 
        title='编辑通知方式名称'
        minLength={2}
        maxLength={256}
        showIcon={showIcon}
        value={notificationTargetAlias}
        onChangeDispatch={changeNotificationTargetAlias.bind(this, notificationTargetId)}
        onChangeComplete={onChangeComplete}
      />
    )
  }
}

NotificationTargetAlias = connect()(NotificationTargetAlias)

export default NotificationTargetAlias
