import React from 'react'
import { Alert, Divider } from 'antd'

import wechatImg from '../assets/img/wechat.jpeg'

class Customer extends React.Component {
  render () {
    return (
      <>
        <div>客服微信: <b>OHTTPS</b></div>
        <img src={wechatImg} alt='微信号: OHTTPS' height={150} style={{marginTop: '15px'}}/>
        <Divider />
        <div>客服邮箱: <b>service@ohttps.com</b></div>
        <Divider />
        <Alert
          message={<div>在线时间: 法定工作日 9:00 ～ 21:00</div>}
          type='info'
          showIcon
        />
      </>
    )
  }
}

export default Customer
