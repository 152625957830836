import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Row, Col, Input, Button, Icon, Tooltip, message} from 'antd'

class EditLabel extends React.Component {
  static props = {
    title: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    showIcon: PropTypes.bool,
    value: PropTypes.string,
    onChangeDispatch: PropTypes.func,
    onChangeComplete: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      showIcon: props.showIcon,
      editing: false,
      editingValue: props.value
    }
  }

  handleClickEdit = (e) => {
    this.setState({
      editing: true
    })
    setTimeout(() => {
      if (this.editCtl) {
        this.editCtl.focus()
      }
    })
  }

  handleInputChange = (e) => {
    this.setState({
      editingValue: e.target.value
    })
  }

  handleChangeConfirm = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {onChangeDispatch, onChangeComplete, minLength, maxLength} = this.props
    const {editingValue} = this.state

    if (minLength && editingValue.length < minLength) {
      return message.error(`长度不能小于${minLength}个字符`)
    }

    if (maxLength && editingValue.length > maxLength) {
      return message.error(`长度不能超过${maxLength}个字符`)
    }

    this.props.dispatch(onChangeDispatch(editingValue))
      .then(result=> {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        }
        this.setState({
          editing: false
        })
        if (onChangeComplete) {
          onChangeComplete()
        }
      })
  }

  handleMouseEnter = (e) => {
    this.setState({
      showIcon: true
    })
  }

  handleMouseLeave = (e) => {
    const {showIcon} = this.props
    this.setState({
      showIcon: showIcon
    })
  }

  handleCloseEdit = (e) => {
    const {value} = this.props
    this.setState({
      editing: false,
      editingValue: value
    })
  }

  render() {
    const {value, title, minLength, maxLength} = this.props
    const {showIcon, editing, editingValue} = this.state
    return (
      <>
        <Tooltip title={value} placement="bottomLeft">
          <span style={{display: 'flex', alignItems: 'center'}} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
            <span style={{display: 'inline-block', maxWidth: '250px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
              {value}
              &nbsp;
            </span>
            {showIcon ?
              <Icon type="form" onClick={this.handleClickEdit} /> :
              <span style={{ width: 12, display: 'inline-block' }}>&nbsp;</span>}
          </span>
        </Tooltip>
        {editing ?
          <div>
            <div style={{ width: '450px', position: 'absolute', boxShadow: '1px 1px 8px rgba(0,0,0,0.5)', zIndex: 1001, background: 'white', padding: '15px' }}>
              <Row>
                {title}：
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Input size='small' value={editingValue} ref={ref => this.editCtl = ref} onChange={this.handleInputChange} maxLength={maxLength} />
              </Row>
              <Row style={{ marginTop: '10px', color: '#999' }}>
                <span style={{ color: '#999 !important' }}>长度限制为{minLength}-{maxLength}个字符</span>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={4}>
                  <Button type='primary' onClick={this.handleChangeConfirm}>确定</Button>
                </Col>
                <Col span={4}>
                  <Button onClick={this.handleCloseEdit}>取消</Button>
                </Col>
              </Row>
            </div>
            <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0, left: 0, zIndex: 1000 }} onClick={this.handleCloseEdit} >&nbsp;</div>
          </div> :
          ''
        }
      </>
    )
  }
}

EditLabel = connect()(EditLabel)

export default EditLabel
