import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Transfer, message, Button, Row, Col, Switch, Divider, InputNumber, Tooltip} from 'antd'
import { getCloudServers } from '../redux/cloud/server'
import { getCertificateDetail, changeCertificateConfig } from '../redux/certificate'

class EditCertificateConfig extends React.Component {
  static props = {
    certificateId: PropTypes.number,
    onEditComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.searchCloudServers()
    this.searchCertificateTargetes()
    this.state = {
      autoUpdate: true,
      autoPush: true,
      autoNotify: true,
      autoUpdateDaysInAdvance: 20,
      autoNotifyDaysInAdvance: 20,
      listData: [],
      selectedCloudServerIds: []
    }
  }

  searchCloudServers = () => {
    this.props.dispatch(getCloudServers({offset: 0, limit: 1000000})).then(() => {
      this.refreshListData()
    })
  }

  searchCertificateTargetes = () => {
    const {certificateId} = this.props
    this.props.dispatch(getCertificateDetail({certificateId})).then(() => {
      this.refreshListData()
    })
  }

  refreshListData () {
    const {cloudServers, certificateDetail} = this.props

    if (!certificateDetail) {
      return
    }

    const listData = cloudServers.rows.map((item) => {
      return {
        key: item.id,
        name: item.name,
        alias: item.alias,
        cloudProviderName: item.cloudProviderName,
        cloudProviderLogo: item.cloudProviderLogo,
        cloudPushPosition: item.cloudPushPosition,
        chosen: certificateDetail.targets.some(target => target.id === item.id)
      }
    })

    const selectedCloudServerIds = certificateDetail.targets.map(target => target.id)

    this.setState({
      autoNotify: certificateDetail.autoNotify,
      autoUpdate: certificateDetail.autoUpdate,
      autoPush: certificateDetail.autoPush,
      autoNotifyDaysInAdvance: certificateDetail.autoNotifyDaysInAdvance,
      autoUpdateDaysInAdvance: certificateDetail.autoUpdateDaysInAdvance,
      listData,
      selectedCloudServerIds
    })
  }

  handleChangeSelected = (selectedCloudServerIds) => {
    this.setState({
      selectedCloudServerIds
    })
  }

  handleClickConfirm = () => {
    const {certificateId, onEditComplete} = this.props
    const {autoUpdate, autoPush, autoNotify, autoUpdateDaysInAdvance, autoNotifyDaysInAdvance, selectedCloudServerIds} = this.state
    this.props.dispatch(changeCertificateConfig({
      certificateId,
      autoUpdate: !!autoUpdate,
      autoPush: !!autoPush,
      autoNotify: !!autoNotify,
      autoUpdateDaysInAdvance,
      autoNotifyDaysInAdvance,
      cloudServerIds: selectedCloudServerIds
    })).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      message.success('配置修改成功')

      if (onEditComplete) {
        onEditComplete()
      }
    })
  }

  handleChangeAutoPush = (checked) => {
    this.setState({
      autoPush: checked
    })
  }

  handleChangeAutoUpdate = (checked) => {
    this.setState({
      autoUpdate: checked
    })
  }

  handleChangeAutoNotify = (checked) => {
    this.setState({
      autoNotify: checked
    })
  }

  handleChangeAutoNotifyDaysInAdvance = (daysInAdvance) => {
    this.setState({
      autoNotifyDaysInAdvance: daysInAdvance
    })
  }

  handleChangeAutoUpdateDaysInAdvance = (daysInAdvance) => {
    this.setState({
      autoUpdateDaysInAdvance: daysInAdvance
    })
  }

  render () {
    const {certificateDetail} = this.props
    const {autoUpdate, autoPush, autoNotify, autoUpdateDaysInAdvance, autoNotifyDaysInAdvance, listData, selectedCloudServerIds} = this.state
    if (!certificateDetail) {
      return <></>
    }

    return (
      <>
        <Row style={{marginTop: '20px'}}>
          <Col span={24} style={{display: 'flex', alignItems: 'center', height: 24}}>
            <span>证书到期前自动通知:&nbsp;&nbsp;</span>
            <Switch checkedChildren="开" unCheckedChildren="关" size="small" checked={!!autoNotify} onChange={this.handleChangeAutoNotify} />
            {autoNotify ? <span>&emsp;&emsp;提前&ensp;<InputNumber size='small' min={15} max={60} style={{width: 51}} value={autoNotifyDaysInAdvance} onChange={this.handleChangeAutoNotifyDaysInAdvance}/>&ensp;天</span> : ''}
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Col span={24} style={{display: 'flex', alignItems: 'center', height: 24}}>
            <span>证书到期前自动更新:&nbsp;&nbsp;</span>
            {
              certificateDetail.supportAutoUpdate ?
                <>
                  <Switch checkedChildren="开" unCheckedChildren="关" size="small" checked={!!autoUpdate} onChange={this.handleChangeAutoUpdate} />
                  {autoUpdate ? <span>&emsp;&emsp;提前&ensp;<InputNumber size='small' min={15} max={60} style={{width: 51}} value={autoUpdateDaysInAdvance} onChange={this.handleChangeAutoUpdateDaysInAdvance}/>&ensp;天</span> : ''}
                </> :
                <span style={{color: 'gray'}}>不支持自动更新</span>
            }
          </Col>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Col span={24} style={{display: 'flex', alignItems: 'center'}}><span>证书更新后自动部署:&nbsp;&nbsp;</span><Switch checkedChildren="开" unCheckedChildren="关" size="small" checked={autoPush} onChange={this.handleChangeAutoPush} /></Col>
        </Row>
        <Divider>部署节点配置</Divider>
        <Transfer
          dataSource={listData}
          showSearch
          filterOption={(inputValue, item) => {
            return (item.alias && item.alias.toLowerCase().includes(inputValue.toLowerCase()))
              || (item.name && item.name.toLowerCase().includes(inputValue.toLowerCase()))
              || item.cloudProviderName.toLowerCase().includes(inputValue.toLowerCase())
              || item.cloudPushPosition.toLowerCase().includes(inputValue.toLowerCase())
          }}
          titles={['所有部署节点', '已选中部署节点']}
          listStyle={{
            width: 338,
            height: 400,
          }}
          operations={['添加', '去除']}
          targetKeys={selectedCloudServerIds}
          onChange={this.handleChangeSelected}
          render={item => {
            return (
              <>
                <img src={item.cloudProviderLogo} alt='' width={15} height={15}/>&nbsp;
                <Tooltip title={`${item.cloudProviderName} - ${item.cloudPushPosition} - ${item.alias || item.name}`} placement="bottomLeft" >
                  <span style={{verticalAlign: 'middle'}}>{item.cloudProviderName}&nbsp;-&nbsp;{item.cloudPushPosition}&nbsp;-&nbsp;{item.alias || item.name}</span>
                </Tooltip>
              </>
            )
          }}
        />
        <Divider />
        <Row type='flex' justify='end' gutter={16} style={{marginTop: '20px'}}>
          <Col span={18} />
          <Col span={6}>
            <Button onClick={this.handleClickConfirm} type='primary' block>确认修改</Button>
          </Col>
        </Row>
      </>
    )
  }
}

EditCertificateConfig = connect((state) => {
  return {
    cloudServers: state.cloud.server.cloudServers,
    certificateDetail: state.certificate.certificate.certificateDetail
  }
})(EditCertificateConfig)

export default EditCertificateConfig
