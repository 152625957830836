import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Divider, message, Tooltip} from 'antd'
import moment from 'moment'
import Clipboard from 'react-clipboard.js'
import CloudServerAlias from '../../components/CloudServerAlias'
import ConfigField from '../../components/ConfigField'
import {getCloudServerDetail} from '../../redux/cloud/server'

class CloudServerDetail extends React.Component {
  constructor (props) {
    super(props)

    this.searchCloudServerDetail()
  }

  searchCloudServerDetail = () => {
    const cloudServerId = this.cloudServerId
    this.props.dispatch(getCloudServerDetail({cloudServerId}))
  }

  handleCopySuccess = () => {
    message.success('部署节点ID已复制到剪贴板')
  }

  get cloudServerId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  render () {
    const {cloudServerDetail} = this.props
    if (!cloudServerDetail) {
      return null
    }
    const { fields } = cloudServerDetail.cloudProviderConfigJson
    return (
      <div className='detail'>
        <p className='header'>
          <Icon type='cloud-upload'/>{cloudServerDetail.name}
          <Clipboard 
            className='ant-btn' 
            data-clipboard-text={cloudServerDetail.name} 
            onSuccess={this.handleCopySuccess}
            style={{marginLeft: '5px', border: 'none'}}
          >
            <Icon type='copy'/>
          </Clipboard>
        </p>
        <Row>
          <Col span={2}>节点名称：</Col>
          <Col span={10}><CloudServerAlias key={cloudServerDetail.id} showIcon  cloudServerId={cloudServerDetail.id} cloudServerAlias={cloudServerDetail.alias || cloudServerDetail.name} onChangeComplete={this.searchCloudServerDetail}/></Col>
          <Col span={2}>创建时间：</Col>
          <Col span={10}>{moment(cloudServerDetail.createTime).format('YYYY-MM-DD HH:mm')}</Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col span={2}>节点类型：</Col>
          <Col span={22}>
            <img src={cloudServerDetail.cloudProviderLogo} alt='' width={15} height={15}/>&nbsp;{cloudServerDetail.cloudProviderName} - {cloudServerDetail.cloudPushPosition}
          </Col>
        </Row>
        <Row type='flex' align='middle' style={{marginTop: '10px', display: 'flex', alignItems: 'self-start'}}>
          <Col span={2}>关联证书：</Col>
          <Col span={22} style={{display: 'flex', flexWrap: 'wrap'}}>
            {cloudServerDetail.associatedCertificates.length ? cloudServerDetail.associatedCertificates.map(item => {
              return (
                <Tooltip key={item.name} title={item.alias || item.name} placement="bottomLeft">
                  <Button type="link" style={{paddingLeft: 0, height: 21}} onClick={() => this.props.history.push(`/monitor/certificates/${item.id}`)}>
                    <span style={{display: 'inline-block', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>  
                      {item.alias || item.name}
                    </span>
                  </Button>
                </Tooltip>
              )
            }) : <Button type='link' onClick={() => {this.props.history.push('/monitor/certificates')}} style={{height: '21px', padding: '0px', border: 'none'}}>点击前往证书页面配置证书部署节点</Button>}
          </Col>
        </Row>
        <div style={{width: '600px'}}>
          <Divider>部署节点参数</Divider>
          {fields.map((item, index) => {
            const value = cloudServerDetail.accessJson[item.field]
            return (
              <Row style={{ marginTop: '10px' }} key={index}>
                <Col span={6}>
                  {item.name}
                </Col>
                <Col span={18}>
                  <ConfigField {...item} value={value} editable={false}/>
                </Col>
              </Row>
            )
          })}
        </div>
      </div>
    )
  }
}

CloudServerDetail = connect((state) => {
  return {
    cloudServerDetail: state.cloud.server.cloudServerDetail
  }
})(CloudServerDetail)

export default CloudServerDetail
