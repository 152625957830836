import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Switch, Collapse, Icon, Button, Tabs, Tag, Table, message, Modal, Input, Form, Drawer, Tooltip } from 'antd'
import { getNotificationTargets, removeNotificationTarget } from '../../redux/notification/target'
import { getNotificationProviders } from '../../redux/notification/provider'
import { changeNotificationConfig, getNotificationsConfig } from '../../redux/notification/config'
import NotificationTargetAlias from '../../components/NotificationTargetAlias'
import CreateNotificationTarget from '../../components/CreateNotificationTarget'
import UpdateNotificationTarget from '../../components/UpdateNotificationTarget'
import EditUserNotificationConfig from '../../components/EditUserNotificationConfig'
import { SYSTEM_EMAIL_NOTIFICATION_TARGET } from '../../utils/constant'

class Notifications extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      activeNotificationTargetId: null,
      createNotificationTargetDrawerVisible: false,
      updateNotificationTargetDrawerVisible: false,
      editUserNotificationConfigDrawerVisible: false
    }
      
    this.props.dispatch(getNotificationsConfig())
    this.searchNotificationProviders()
    this.searchNotificationTargets()
  }

  refreshUrl = ({searchContent, offset, limit}) => {
    this.props.history.replace(`${this.props.location.pathname}?search=${encodeURIComponent(searchContent)}&offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}`)
  }

  searchNotificationTargets = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit})

    this.props.dispatch(getNotificationTargets({searchContent, offset, limit})).then(() => {
      this.setState({
        firstLoading: false
      })
    })
  }

  searchNotificationProviders = () => {
    if (!this.props.notificationProviders) {
      this.props.dispatch(getNotificationProviders())
    }
  }

  get notificationProvidersMap () {
    if (this.props.notificationProviders) {
      return new Map(this.props.notificationProviders.map(item => [item.id, item]))
    } else {
      return new Map()
    }
  }

  get notificationTargetsTableColumns () {
    return [{
      title: '通知方式ID/名称',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleUpdateNotificationTarget.bind(this, record)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
            <br/>
            <NotificationTargetAlias key={record.id} notificationTargetId={record.id} notificationTargetAlias={record.alias || record.name} onChangeComplete={this.searchNotificationTargets}/>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'notificationProviderName',
      render: (text, record) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={record.notificationProviderLogo} alt='' width={15} height={15}/>&nbsp;{record.notificationProviderName}
          </div>
        )
      }
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleUpdateNotificationTarget.bind(this, record)} size='small' type='link'>修改</Button>
            |
            <Button onClick={this.handleDeleteNotificationTarget.bind(this, record)} size='small' type='link'>删除</Button>
          </>
        )
      }
    }]
  }

  get notificationTargetsTableData () {
    return [...(new Array(this.state.offset)), ...this.props.notificationTargets.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchNotificationTargets({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchNotificationTargets({
      offset,
      limit
    })
  }

  handleCreateNotificationTarget = () => {
    this.setState({
      createNotificationTargetDrawerVisible: true
    })
  }

  handleCreateNotificationTargetDrawerClose = () => {
    this.setState({
      createNotificationTargetDrawerVisible: false
    })
  }

  handleCreateNotificationTargetComplete = () => {
    this.setState({
      createNotificationTargetDrawerVisible: false
    })
    this.searchNotificationTargets()
  }

  handleUpdateNotificationTargetDrawerClose = () => {
    this.setState({
      updateNotificationTargetDrawerVisible: false
    })
  }

  handleUpdateNotificationTargetComplete = () => {
    this.setState({
      updateNotificationTargetDrawerVisible: false
    })
    this.searchNotificationTargets()
  }

  handleEditUserNotificationConfigDrawerClose = () => {
    this.setState({
      editUserNotificationConfigDrawerVisible: false
    })
  }

  handleEditUserNotificationConfigComplete = () => {
    this.setState({
      editUserNotificationConfigDrawerVisible: false
    })
  }

  handleDeleteNotificationTarget = (notificationTarget, e) => {
    e.preventDefault()
    e.stopPropagation()

    const notificationTargetId = notificationTarget.id
    this.props.dispatch(removeNotificationTarget({notificationTargetId})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('删除通知方式成功')

        this.searchNotificationTargets()
      }
    })
  }

  handleUpdateNotificationTarget = (notificationTarget, e) => {
    e.preventDefault()
    e.stopPropagation()

    const notificationTargetId = notificationTarget.id
    if (notificationTarget.associatedCertificatesCount > 0) {
      Modal.confirm({
        title: '确认要修改该通知方式吗?',
        content: '请务必确认当前配置已验证通过',
        okType: 'primary',
        okButtonProps: {size: 'small'},
        cancelButtonProps: {size: 'small'},
        autoFocusButton: false,
        maskClosable: true,
        onOk: () => {
          this.setState({
            activeNotificationTargetId: notificationTargetId,
            updateNotificationTargetDrawerVisible: true
          })
        },
        onCancel: () => {
        },
      })
    } else {
      this.setState({
        activeNotificationTargetId: notificationTargetId,
        updateNotificationTargetDrawerVisible: true
      })
    }
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 10
    })
    this.searchNotificationTargets({offset: 0, limit: 10})
  }

  handleNotificationEnabledChange = (notificationConfig, notificationEnabled) => {
    const { notificationTypeId, notificationTargets, systemEmailEnabled } = notificationConfig
    const notificationTargetIds = notificationTargets.map(item => item.id)
    this.props.dispatch(changeNotificationConfig({notificationTypeId, notificationEnabled, notificationTargetIds, systemEmailEnabled}))
  }

  handleEditUserNotificationConfig = (userNotificationConfig) => {
    this.setState({
      activeUserNotificationConfig: userNotificationConfig,
      editUserNotificationConfigDrawerVisible: true
    })
  }

  handleRemoveNotificatoinTargetOfConfig = (userNotificationConfig, notificationTargetId) => {
    const {notificationTypeId, notificationEnabled, notificationTargets, systemEmailEnabled} = userNotificationConfig

    if (notificationTargetId === SYSTEM_EMAIL_NOTIFICATION_TARGET.ID) {
      this.props.dispatch(changeNotificationConfig({
        notificationTypeId,
        notificationEnabled,
        notificationTargetIds: notificationTargets.map(item => item.id),
        systemEmailEnabled: false
      }))
    } else {
      this.props.dispatch(changeNotificationConfig({
        notificationTypeId,
        notificationEnabled,
        notificationTargetIds: notificationTargets.filter(item => item.id !== notificationTargetId).map(item => item.id),
        systemEmailEnabled
      }))
    }
  }

  renderNotificationSettings () {
    const {notificationsConfig} = this.props

    return (
      <>
        <Collapse
            activeKey={notificationsConfig.filter(config => config.notificationEnabled).map(config => config.notificationTypeId)}
        >
            {notificationsConfig.map(config => {
                return (
                    <Collapse.Panel
                      header={
                        <>
                          {config.notificationTypeName}
                          <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={config.notificationEnabled} style={{marginLeft: 10}} size='small' onChange={this.handleNotificationEnabledChange.bind(this, config)}/>
                        </>
                      } 
                      key={config.notificationTypeId}
                      >
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {config.systemEmailEnabled ? 
                              <Tag key={SYSTEM_EMAIL_NOTIFICATION_TARGET.ID} closable={true} style={{height: 32, lineHeight: '32px'}} onClose={this.handleRemoveNotificatoinTargetOfConfig.bind(this, config, SYSTEM_EMAIL_NOTIFICATION_TARGET.ID)}>
                                <Icon type="mail" theme="filled" style={{color: '#0052d9', width: 15, height: 15}}/>&nbsp;{SYSTEM_EMAIL_NOTIFICATION_TARGET.NOTIFICATION_PROVIDER_NAME}&nbsp;-&nbsp;{SYSTEM_EMAIL_NOTIFICATION_TARGET.NAME}
                            </Tag> : ''}
                            {config.notificationTargets && config.notificationTargets.map(item => {
                                return (
                                    <Tooltip key={item.id} title={`${item.notificationProviderName} - ${item.alias || item.name}`} placement="bottomLeft">
                                      <Tag closable={true} style={{height: 32, lineHeight: '32px', display: 'inline-block', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClose={this.handleRemoveNotificatoinTargetOfConfig.bind(this, config, item.id)}>
                                          <img src={item.notificationProviderLogo} alt='' width={15} height={15}/>&nbsp;{item.notificationProviderName}&nbsp;-&nbsp;{item.alias || item.name}
                                      </Tag>
                                    </Tooltip>
                                )
                            })}
                            <Tag style={{ background: '#fff', borderStyle: 'dashed', height: 32, lineHeight: '32px' }} onClick={this.handleEditUserNotificationConfig.bind(this, config)}>
                                <Icon type="plus" /> 绑定通知方式
                            </Tag>
                        </div>
                    </Collapse.Panel>
                )
            })}
        </Collapse>
        {this.renderEditNotificationConfigDrawer()}
      </>
    )
  }

  renderCreateNotificationTargetDrawer = () => {
    const {createNotificationTargetDrawerVisible} = this.state
    if (!createNotificationTargetDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='添加通知方式'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleCreateNotificationTargetDrawerClose}
          visible={this.state.createNotificationTargetDrawerVisible}
        >
          <CreateNotificationTarget onCreateComplete={this.handleCreateNotificationTargetComplete}/>
      </Drawer>
    )
  }

  renderUpdateNotificationTargetDrawer = () => {
    const {updateNotificationTargetDrawerVisible, activeNotificationTargetId} = this.state
    if (!updateNotificationTargetDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='修改通知方式'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleUpdateNotificationTargetDrawerClose}
          visible={this.state.updateNotificationTargetDrawerVisible}
        >
          <UpdateNotificationTarget notificationTargetId={activeNotificationTargetId} onUpdateComplete={this.handleUpdateNotificationTargetComplete}/>
      </Drawer>
    )
  }

  renderEditNotificationConfigDrawer = () => {
    const {editUserNotificationConfigDrawerVisible, activeUserNotificationConfig} = this.state
    if (!editUserNotificationConfigDrawerVisible || !activeUserNotificationConfig) {
      return
    }

    return (
      <Drawer
          title='修改通知配置'
          placement='right'
          closable={false}
          width = {800}
          onClose={this.handleEditUserNotificationConfigDrawerClose}
          visible={this.state.editUserNotificationConfigDrawerVisible}
        >
          <EditUserNotificationConfig userNotificationConfig={activeUserNotificationConfig} onEditComplete={this.handleEditUserNotificationConfigComplete}/>
      </Drawer>
    )
  }

  renderNotificationTargets () {
    const {searchContent, firstLoading} = this.state
    return (
      <>
        <Form layout='inline' style={{marginBottom: '12px', marginTop: '-4px'}}>
          <Form.Item>
            <Input.Search placeholder="输入通知方式ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <Button icon='plus' onClick={this.handleCreateNotificationTarget}>添加通知方式</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.notificationTargetsTableColumns}
          dataSource={firstLoading ? [] : this.notificationTargetsTableData}
          size='small'
          scroll={{x: true}}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.notificationTargets.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
        {this.renderCreateNotificationTargetDrawer()}
        {this.renderUpdateNotificationTargetDrawer()}
      </>
    )
  }

  render () {
    return (
      <div className='content'>
        <Tabs defaultActiveKey="1" style={{height: '100%', overflow: 'unset'}}>
            <Tabs.TabPane tab="通知设置" key="1">
                {this.renderNotificationSettings()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="通知方式" key="2">
                {this.renderNotificationTargets()}
            </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

Notifications = connect((state) => {
  return {
    notificationsConfig: state.notification.config.notificationsConfig,
    notificationProviders: state.notification.provider.notificationProviders,
    notificationTargets: state.notification.target.notificationTargets
  }
})(Notifications)

export default Notifications
