import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import routes from '../routes'
import { getMe } from '../redux/auth'
import Header from '../components/Header'
import { getConfigs } from '../redux/common'

class Guide extends React.Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem('TOKEN')
    this.state = {
      hasToken: !!token
    }
    if (token && !this.props.me) {
      this.props.dispatch(getMe())
    }

    if (this.props.location.pathname === '/guide' || this.props.location.pathname === '/guide/') {
      return this.props.history.push('/guide/certificate')
    }

    this.props.dispatch(getConfigs())
  }

  get contentHeight () {
    if (document.querySelector('.ant-layout-content')) {
      return document.querySelector('.ant-layout-content').clientHeight - 20
    } else {
      return 0
    }
  }

  renderRoutes = () => {
    return (
      <Switch>{routes.guide.map((item, index) => {
        return (
          <Route
            path={item.layout + item.path}
            component={item.component}
            key={index}
          />
        )
      })}</Switch>
    )
  }

  render () {
    if (this.state.hasToken && this.props.signing) {
      return null
    }

    if (!this.props.me) {
      return <Redirect to='/signin' />
    }

    setTimeout(() => {
      window.onresize = () => {
        if (this.contentContainer) {
          this.contentContainer.style.height = `${this.contentHeight}px`
        }
        if (this.contentScrollbar) {
          this.contentScrollbar.updateScroll()
        }
      }
      window.onresize()
    })

    return (
      <Layout style={{backgroundColor: 'white'}} className='guide'>
        <Header history={this.props.history}/>
        <Layout.Content>
          <div ref={(ref) => this.contentContainer = ref}>
            <PerfectScrollbar ref={(ref) => this.contentScrollbar = ref}>
              {this.renderRoutes()}
            </PerfectScrollbar>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

Guide = connect((state) => {
  return {
    me: state.auth.me,
    signing: state.auth.signing
  }
})(Guide)

export default Guide
