import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Form, Input, Button, message } from 'antd'
import { resetPwd } from '../../redux/auth'

class ResetPwd extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  get token () {
    return queryString.parse(this.props.location.search).token
  }

  componentDidMount () {
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickResetPwd()
        }
      }
    }
  }

  componentWillUnmount () {
    if (window) {
      window.onkeypress = null
    }
  }

  handleClickResetPwd = () => {
    const {password} = this.state

    if (!password || !password.trim()) {
      return message.error('请输入您的新密码')
    }

    const token = this.token

    this.props.dispatch(resetPwd({token, password}))
      .then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          message.success({
            content: '密码重置成功，即将跳转至登录页面',
            onClose: () => this.props.history.replace('/signin')
          })
        }
      }).catch((error) => {
        return message.error(`未知异常:${error}`)
      })
  }

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleClickResend = () => {
    this.props.history.push('/forget')
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  render () {
    return (
      <div className="area reset">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Form.Item>
              <Input.Password
                placeholder='请输入您的新密码'
                visibilityToggle={false}
                onChange={this.handleChangePassword}
              />
            </Form.Item>
            <Button type='primary' block onClick={this.handleClickResetPwd}>确认</Button>
            <Button type='link' onClick={this.handleClickResend}>链接失效？重新发送</Button>
          </Form>
        </div>
      </div>
    )
  }
}

ResetPwd = connect()(ResetPwd)

export default ResetPwd
