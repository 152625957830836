import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Drawer, Input, message } from 'antd'
import { changeWatchStatus, getWatches, removeWatch } from '../../redux/watch'
import CreateWatch from '../../components/CreateWatch'
import { OCSP_STATUS, WATCH_RECORD_STATUS, WATCH_STATUS } from '../../utils/constant'
import WatchAlias from '../../components/WatchAlias'

class Watches extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit, sortKey, sortOrder} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)
    sortKey = sortKey || ''
    sortOrder = sortOrder || ''

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      sortKey,
      sortOrder,
      createWatchDrawerVisible: false
    }
    this.searchWatches()
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  refreshUrl = ({searchContent, offset, limit, sortKey, sortOrder}) => {
    this.props.history.replace(`${this.props.location.pathname}?search=${encodeURIComponent(searchContent)}&offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}&sortKey=${encodeURIComponent(sortKey)}&sortOrder=${encodeURIComponent(sortOrder)}`)
  }

  searchWatches = (state) => {
    const {searchContent, offset, limit, sortKey, sortOrder} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit, sortKey, sortOrder})

    this.props.dispatch(getWatches({searchContent, offset, limit, sortKey, sortOrder})).then((response) => {
      this.setState({
        firstLoading: false
      })
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.latestRecordStatus === WATCH_RECORD_STATUS.WATCHING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchWatches()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  get tableColumns () {
    const {firstLoading, sortKey, sortOrder} = this.state;
    const columns = [{
      title: '监控ID',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickWatch.bind(this, record.id)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
            <br/>
            <WatchAlias key={record.id} watchId={record.id} watchAlias={record.alias || record.name} onChangeComplete={this.searchWatches}/>
          </>
        )
      }
    }, {
      title: '域名',
      dataIndex: 'domain',
      ellipsis: true,
      width: 142,
      sorter: true
    }, {
      title: 'IP',
      dataIndex: 'ip',
      ellipsis: true,
      width: 132,
      sorter: true,
      render: text => text || '-'
    }, {
      title: '端口',
      dataIndex: 'port',
      ellipsis: true,
      width: 70,
      sorter: true
    }, {
      title: '状态',
      dataIndex: 'statu',
      render: (text, record) => {
        if (record.status === WATCH_STATUS.CREATED) {
          return (
            <span style={{color: 'green'}}>已创建</span>
          )
        } else if (record.status === WATCH_STATUS.PAUSED) {
          return (
            <span style={{color: 'orange'}}>已暂停</span>
          )
        } else if (record.status === WATCH_STATUS.DELETED) {
          return (
            <span style={{color: 'red'}}>已删除</span>
          )
        }
      },
      width: 80,
      sorter: true
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm'),
      width: 142,
      sorter: true
    }, {
      title: '监测次数',
      dataIndex: 'recordsCount',
      width: 68
    }, {
      title: '证书颁发机构',
      dataIndex: 'issuer',
      ellipsis: true,
      sorter: true,
      render: text => text || '-'
    }, {
      title: '证书状态',
      dataIndex: 'expiredTime',
      width: 192,
      sorter: true,
      render: (text, record) => {
        if (record.expiredTime && new Date(record.expiredTime).getTime() <= Date.now()) {
          return (
            <div>
              <span style={{color: 'red'}}>已过期</span>
              <br/>
              <span>过期时间：{moment(record.expiredTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        }

        if (record.latestRecordOcspStatus === OCSP_STATUS.GOOD) {
            return (
              <div>
                <span style={{color: 'green'}}>生效中·{moment(record.expiredTime).diff(moment(), 'days')}天后过期</span>
                <br/>
                <span>过期时间：{moment(record.expiredTime).format('YYYY-MM-DD HH:mm')}</span>
              </div>
            )
        } else if (record.latestRecordOcspStatus === OCSP_STATUS.REVOKED) {
          return (
            <div>
              <span style={{color: 'red'}}>已吊销</span>
              <br/>
              <span>吊销时间：{moment(record.revokedTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        } else {
          if (record.expiredTime) {
            return (
              <div>
                <span style={{color: 'orange'}}>未知</span>
                <br/>
                <span>过期时间：{moment(record.expiredTime).format('YYYY-MM-DD HH:mm')}</span>
              </div>
            )
          } else {
            return '-'
          }
        }
      }
    }, {
      title: '最后监测',
      dataIndex: 'latestRecordStatus',
      width: 192,
      render: (text, record) => {
        if (record.status === WATCH_STATUS.PAUSED) {
          return (
            <div>
              <span style={{color: 'orange'}}>连续多次监测结果异常</span>
              <br/>
              <span style={{color: 'orange'}}>任务已被系统自动暂停</span>
            </div>
          )
        }

        if (record.latestRecordStatus === WATCH_RECORD_STATUS.WATCHING) {
          return (
            <div>
              <span style={{color: 'orange'}}>监测中</span>
              <br/>
              <span>监测时间：{moment(record.latestRecordTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        } else if (record.latestRecordStatus === WATCH_RECORD_STATUS.WATCHED) {
          if (record.latestRecordOcspStatus === OCSP_STATUS.GOOD) {
            return (
              <div>
                <span style={{color: 'green'}}>已监测</span>
                <br/>
                <span>监测时间：{moment(record.latestRecordTime).format('YYYY-MM-DD HH:mm')}</span>
              </div>
            )
          }
        } else if (record.latestRecordStatus === WATCH_RECORD_STATUS.ERROR) {
          return (
            <div>
              <span style={{color: 'red'}}>{record.issuer ? '查询证书吊销状态失败' : '获取站点证书失败'}</span>
              <br/>
              <span>监测时间：{moment(record.latestRecordTime).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          )
        }
      }
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            {record.status === WATCH_STATUS.PAUSED ? <Button onClick={this.handleRestartWatch.bind(this, record.id)} size='small' type='link'>恢复</Button> : ''}
            {record.status === WATCH_STATUS.PAUSED ? '|' : ''}
            <Button onClick={this.handleClickWatch.bind(this, record.id)} size='small' type='link'>查看</Button>
            |
            <Button onClick={this.handleClickDelete.bind(this, record.id)} size='small' type='link'>删除</Button>
          </>
        )
      }
    }]

    if (firstLoading) {
      for(const column of columns) {
        if (column.dataIndex === sortKey) {
          column.defaultSortOrder = sortOrder
        }
      }
    }

    return columns;
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.watches.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleCreateWatch = () => {
    this.setState({
      createWatchDrawerVisible: true
    })
  }

  handleCreateWatchDrawerClose = () => {
    this.setState({
      createWatchDrawerVisible: false
    })
  }


  handleClickWatch = (watchId, e) => {
    e.preventDefault()
    this.props.history.push(`/monitor/watches/${watchId}`)
  }

  handleClickDelete = (watchId, e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.dispatch(removeWatch({watchId})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('删除监控成功')

        this.searchWatches()
      }
    })
  }

  handleRestartWatch = (watchId, e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.dispatch(changeWatchStatus(watchId, WATCH_STATUS.CREATED)).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('恢复监控成功')

        this.searchWatches()
      }
    })
  }

  handleCreateWatchComplete = () => {
    this.setState({
      createWatchDrawerVisible: false
    })
    this.searchWatches()
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0
    })
    this.searchWatches({offset: 0})
  }

  handleChangeTable = (pagination, filters, sorter) => {
    const {current, pageSize} = pagination
    const offset = pageSize * (current - 1)
    const limit = pageSize
    const {field, order} = sorter
    const sortOrder = order || ''
    const sortKey = order ? field : ''
    this.setState({
      current,
      offset,
      limit,
      sortKey,
      sortOrder
    })
    this.searchWatches({offset, limit, sortKey, sortOrder})
  }

  renderCreateWatchDrawer = () => {
    // 这么写是为了每次显示Drawer都重新创建Drawer内的组件，解决Drawer内组件内容不更新问题
    const {createWatchDrawerVisible} = this.state
    if (!createWatchDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='添加监控'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleCreateWatchDrawerClose}
          visible={createWatchDrawerVisible}
        >
          <CreateWatch onCreateComplete={this.handleCreateWatchComplete}/>
      </Drawer>
    )
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入域名搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <Button onClick={this.handleCreateWatch} icon='plus'>添加监控</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          onChange={this.handleChangeTable}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.watches.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`
          }}
        />
        {this.renderCreateWatchDrawer()}
      </div>
    )
  }
}

Watches = connect((state) => {
  return {
    watches: state.watch.watch.watches
  }
})(Watches)

export default Watches
