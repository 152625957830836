import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditLabel from './EditLabel'
import {changeCertificateAlias} from '../redux/certificate'

class CertificateAlias extends React.Component {
  static props = {
    showIcon: PropTypes.bool,
    certificateId: PropTypes.number,
    certificateAlias: PropTypes.string,
    onChangeComplete: PropTypes.func
  }

  render() {
    const {showIcon, certificateId, certificateAlias, onChangeComplete} = this.props

    return (
      <EditLabel 
        title='编辑证书名称'
        minLength={2}
        maxLength={256}
        showIcon={showIcon}
        value={certificateAlias}
        onChangeDispatch={changeCertificateAlias.bind(this, certificateId)}
        onChangeComplete={onChangeComplete}
      />
    )
  }
}

CertificateAlias = connect()(CertificateAlias)

export default CertificateAlias
