import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Radio, message, Steps, Divider, Tag, Table, Alert, Modal} from 'antd'
import { createCloudServer, getCloudServerPushesOfCloudServer, createCertificatePushesOfCloudServer } from '../../redux/cloud/server'
import { getCloudProviders } from '../../redux/cloud/provider'
import ConfigField from '../../components/ConfigField'
import CertificateType from '../../components/CertificateType'
import CertificateDomains from '../../components/CertificateDomains'
import SelectCertificatesModal from '../../components/SelectCertificatesModal'
import { checkAccessJson, maskEmail } from '../../utils'
import { getCertificates } from '../../redux/certificate'
import { CERTIFICATE_PUSH_STATUS, CLOUD_ID, CLOUD_PUSH_POSITION } from '../../utils/constant'
import { checkCloudServer } from '../../redux/common'
import SSHPushDetail from '../../components/SSHPushDetail'

class CreateCloudServer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      cloudId: null,
      cloudProviderId: null,
      cloudServerAccessJson: null,
      currentStep: 0,
      modalVisible: false,
      selectedCertificates: [],
      cloudServer: null,
      certificatePushes: [],
      pushesCreated: false,
      checkCloudServerIng: false,
      certificatePushDetailModalVisible: false
    }

    this.props.dispatch(getCloudProviders()).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }
      const cloudProviders = result.payload

      for (const cloud of cloudProviders) {
        for (const provider of cloud.providers) {
          if (provider.visible) {
            this.setState({
              cloudId: cloud.id,
              cloudProviderId: provider.id,
              cloudServerAccessJson: provider.configJson.default || null
            })
            return
          }
        }
      }
    })

    this.props.dispatch(getCertificates({searchContent: '', offset: 0, limit: 10}))
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  get clouds () {
    const {cloudProviders} = this.props
    return cloudProviders.filter(item => item.providers.filter(item => item.visible).length > 0).map(item => {
      return {
        id: item.id,
        name: item.name,
        logo: item.logo
      }
    })
  }

  get providers () {
    const {cloudId} = this.state
    const {cloudProviders} = this.props
    const cloud = cloudProviders.find(item => item.id === cloudId)
    if (!cloud) {
      return []
    } else {
      return cloud.providers.filter(item => item.visible)
    }
  }

  get cloudProvider () {
    if (!this.state.cloudProviderId) {
      return null
    }

    for (const cloud of this.props.cloudProviders) {
      for (const provider of cloud.providers) {
        if (provider.id === this.state.cloudProviderId) {
          return {...provider, cloudName: cloud.name}
        }
      }
    }
    return null
  }

  handleChangeCloud = (e) => {
    if (e.target.value === -1) {
      return
    }

    for (const cloud of this.props.cloudProviders) {
      if (cloud.id === e.target.value) {
        this.setState({
          cloudId: e.target.value,
          cloudProviderId: cloud.providers[0].id,
          cloudServerAccessJson: cloud.providers[0].configJson.default || null
        })
        return
      }
    }
  }

  handleChangeCloudProvider = (e) => {
    const cloudProviderId = e.target.value
    const provider = this.providers.find(item => item.id === cloudProviderId)
    this.setState({
      cloudServerAccessJson: provider.configJson.default || null,
      cloudProviderId
    })
  }

  handleEditCloudServer = (field, value) => {
    const accessJson = { ...(this.state.cloudServerAccessJson || {}) }
    accessJson[field] = value
    this.setState({
      cloudServerAccessJson: accessJson,
      cloudServerVerified: false
    })
  }

  handleClickNext = () => {
    const {cloudProviderId, cloudServerAccessJson} = this.state
    const {configJson} = this.cloudProvider

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: cloudServerAccessJson})
    if (error) {
      return message.error(error)
    }

    this.setState({
      checkCloudServerIng: true
    })
    message.warn('正在校验部署节点参数配置是否正确，请耐心等待')
    this.props.dispatch(checkCloudServer({cloudProviderId, accessJson: cloudServerAccessJson}))
      .then(response => {
        this.setState({
          checkCloudServerIng: false
        })

        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('部署节点参数校验通过')
        this.setState({
          currentStep: 1
        })
      })
  }

  handleClickCreate = () => {
    const {cloudProviderId, cloudServerAccessJson, selectedCertificates} = this.state
    const cloudProvider = this.cloudProvider
    if (cloudProvider.maxAttachedCertCount && cloudProvider.maxAttachedCertCount < selectedCertificates.length) {
      return message.error(`该类型部署节点可关联证书数量不能多于${cloudProvider.maxAttachedCertCount}个`)
    }

    if (!selectedCertificates.length) {
      return message.error('您还未添加关联证书')
    }

    const error = checkAccessJson({providerConfigJson: cloudProvider.configJson, serverAccessJson: cloudServerAccessJson})
    if (error) {
      return message.error(error)
    }

    const attachedCertificateIds = selectedCertificates.map(item => item.id)
    this.props.dispatch(createCloudServer({cloudProviderId, accessJson: cloudServerAccessJson, attachedCertificateIds}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('部署节点创建成功')

        const cloudServer = response.payload
        this.setState({
          currentStep: 2,
          cloudServer
        })
      })
  }

  handleBackToCreateCloudServer = () => {
    this.setState({
      currentStep: 0
    })
  }

  handleGoMonitorCloudServers = () => {
    this.props.history.push(`/monitor/cloudservers`)
  }

  handleGoMonitorDashboard = () => {
    this.props.history.push(`/monitor/dashboard`)
  }

  handleSelectCertificates = () => {
    this.setState({
      modalVisible: true
    })
  }

  handleSelectCertificatesOk = (selectedCertificates) => {
    const oldSelectedCertificateIdsSet = new Set(this.state.selectedCertificates.map(item => item.id))
    const newSelectedCertificates = [...this.state.selectedCertificates]

    for (const item of selectedCertificates) {
      if (!oldSelectedCertificateIdsSet.has(item.id)) {
        newSelectedCertificates.push(item)
      }
    }

    this.setState({
      modalVisible: false,
      selectedCertificates: newSelectedCertificates
    })
  }

  handleSelectCertificatesCancel = () => {
    this.setState({
      modalVisible: false
    })
  }

  handleRemoveSelectedCertificate = (certificateId) => {
    this.setState({
      selectedCertificates: this.state.selectedCertificates.filter(item => item.id !== certificateId)
    })
  }

  handleClickCertificate = (certificateId) => {
    window.open(`/monitor/certificates/${certificateId}`, '_blank')
  }

  handleCreateCertificatePushes = () => {
    const {cloudServer} = this.state
    const cloudServerId = cloudServer.id
    this.props.dispatch(createCertificatePushesOfCloudServer({cloudServerId}))
      .then((response => {
        if (this.isUnmounted) {
          return
        }

        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        this.setState({
          pushesCreated: true
        })

        message.success('证书部署任务创建成功')

        this.timer = setInterval(() => {
          this.props.dispatch(getCloudServerPushesOfCloudServer({cloudServerId, offset: 0, limit: 100}))
            .then((response => {
              if (response.errorMsg) {
                return
              }
  
              const certificatePushes = response.payload.rows
              this.setState({
                certificatePushes
              })

              if (certificatePushes.every(item => item.status !== CERTIFICATE_PUSH_STATUS.PUSHING)) {
                clearInterval(this.timer)
              }
            }))
        }, 3 * 1000)
      }))
  }

  getStepStatus = ({stepIndex}) => {
    const currentStep = this.state.currentStep
    if (stepIndex > currentStep) {
      return 'wait'
    } else if (stepIndex === currentStep) {
      return 'process'
    } else {
      return 'finish'
    }
  }

  renderSteps = () => {
    return (
      <Steps
        type="navigation"
        size="small"
        current={this.state.currentStep}
        onChange={this.onChange}
        style={{
          marginBottom: 24,
          boxShadow: '0px -1px 0 0 #e8e8e8 inset',
        }}
      >
        <Steps.Step status={this.getStepStatus({stepIndex: 0})} title="选择类型" />
        <Steps.Step status={this.getStepStatus({stepIndex: 1})} title="关联证书" />
        <Steps.Step status={this.getStepStatus({stepIndex: 2})} title="部署证书" />
      </Steps>
    )
  }

  handelCloseCertificatePushDetailModal = () => {
    this.setState({
      certificatePushDetailModalVisible: false
    })
  }

  handelOpenCertificatePushDetailModal = (certificateId, certificatePushId) => {
    this.setState({
      certificateId,
      certificatePushId,
      certificatePushDetailModalVisible: true
    })
  }

  renderCertificatePushDetailModal = () => {
    const {certificateId, certificatePushId, certificatePushDetailModalVisible} = this.state
    if (!certificatePushDetailModalVisible) {
      return
    }

    return (
      <Modal
        title={'证书部署执行详情'}
        visible={true}
        width={800}
        okType='primary'
        okButtonProps={{ style: { marginRight: '5px' } }}
        onOk={this.handelCloseCertificatePushDetailModal}
        onCancel={this.handelCloseCertificatePushDetailModal}
        footer={[
          <Button type='primary' size='small' key='confirm' onClick={this.handelCloseCertificatePushDetailModal}>
            关闭
          </Button>
        ]}
      >
        <div>
          <SSHPushDetail 
            certificateId={certificateId}
            certificatePushId={certificatePushId}
          />
        </div>
      </Modal>
    )
  }

  renderCreateCloudServer = () => {
    const {cloudId, cloudProviderId, cloudServerAccessJson, checkCloudServerIng} = this.state
    if (!cloudProviderId) {
      return null
    }

    const currentCloudProvider = this.cloudProvider
    const configJson = currentCloudProvider.configJson

    const { userId, email } = this.props.me

    return (
      <div className='content'>
        {this.renderSteps()}
        <p>选择证书需要部署的位置并填写相关参数</p>
        <Divider/>
        <Radio.Group value={cloudId} buttonStyle='solid' onChange={this.handleChangeCloud}>
          {this.clouds.map((item, index) => {
              return (
                <Radio.Button value={item.id} key={index}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={item.logo} alt='' width={15} height={15} />&nbsp;{item.name}
                  </div>
                </Radio.Button>
              )
            })
          }
          <Radio.Button value={-1} key={-1} style={{top: -3.5, backgroundColor: '#ffe58f'}}>
            <div style={{display: 'flex', alignItems: 'center', height: 30}} className='tucao'>
              <form method="post" action="https://support.qq.com/products/109240/topic-detail/2512/#label=popular" target='_blank' rel='noopener noreferrer'>
                <input type="hidden" name="openid" value={userId}/>
                <input type="hidden" name="nickname" value={maskEmail({email})}/>
                <input type="hidden" name="avatar" value="https://cdn.idevbase.com/assets/images/avatar.png"/>
                <button type='submit' style={{height: 30, lineHeight: '30px'}}>
                  <Icon type="bulb" theme="twoTone"/>&nbsp;我要提需求
                </button>
              </form>
            </div>
          </Radio.Button>
        </Radio.Group>
        <br/>
        <Radio.Group value={cloudProviderId} buttonStyle='solid' onChange={this.handleChangeCloudProvider} style={{marginTop: '10px'}}>
          {this.providers.map((item, index) => {
              return (
                <Radio.Button value={item.id} key={index}>
                  <div style={{display: 'flex', alignItems: 'center'}}>{item.name || item.position}
                  </div>
                </Radio.Button>
              )
            })
          }
        </Radio.Group>
        <div style={{ marginTop: '20px' }}>
          {configJson.fields.map((item, index) => {
            const value = (cloudServerAccessJson || {})[item.field]
            if (item.showWhen) {
              // $变量会在eval中使用
              // eslint-disable-next-line no-unused-vars
              const $ = cloudServerAccessJson
              // eslint-disable-next-line no-eval
              const visible = eval(item.showWhen)
              if (!visible) {
                return null
              }
            }
            return (
              <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                <Col span={4}>
                  {item.name}
                </Col>
                <Col span={20}>
                  <ConfigField {...item} value={value} onChange={this.handleEditCloudServer} editable/>
                </Col>
              </Row>
            )
          })}
          {configJson.tips && configJson.tips.length ?
            <Row gutter={16} style={{ marginTop: '5px' }} type='flex' align='middle'>
              {configJson.tips.map(tip => (
                <Col span={24}>
                  <Alert message={tip} type="info" showIcon style={{marginTop: '5px'}}/>
                </Col>
              ))}
            </Row> : ''}
        </div>
        <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
          <Col span={18}>
            <Icon type="info-circle" style={{ color: '#faad14' }} /><a style={{ color: '#faad14' }} href={configJson.helpUrl} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何创建和使用「{currentCloudProvider.cloudName}-{currentCloudProvider.name || currentCloudProvider.position}」类型部署节点</a>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col span={5} offset={0}>
            <Button block onClick={this.handleGoMonitorDashboard.bind(this)}>回到主页</Button>
          </Col>
          <Col span={5} offset={14}>
            <Button block type='primary' loading={checkCloudServerIng} onClick={this.handleClickNext.bind(this)}>{checkCloudServerIng ? '参数校验中...' : '下一步'}</Button>
          </Col>
        </Row>
      </div>
    )
  }

  renderAddCertificates = () => {
    return (
      <div className='content'>
        {this.renderSteps()}
        <p>添加需要部署至该节点的证书</p>
        <Divider/>
        {this.state.selectedCertificates.map(certificate => {
          return (
            <Tag key={certificate.id} closable={true} onClose={this.handleRemoveSelectedCertificate.bind(this, certificate.id)} style={{height: 32, lineHeight: '32px'}}>
              {certificate.name} - <CertificateType type={certificate.type}/> - <span style={{display: 'inline-block'}}><CertificateDomains domains={certificate.domains} onlyFirst={true} maxWidth={235}/></span>
            </Tag>
          )
        })}
        <Tag style={{ background: '#fff', borderStyle: 'dashed', height: 32, lineHeight: '32px' }} onClick={this.handleSelectCertificates}>
            <Icon type="plus" /> 点击添加关联证书
        </Tag>
        <Divider/>
        <Row style={{ marginTop: '20px' }}>
          <Col span={5} offset={0}>
            <Button block onClick={this.handleBackToCreateCloudServer.bind(this)}>上一步</Button>
          </Col>
          <Col span={5} offset={14}>
            <Button block type='primary' onClick={this.handleClickCreate.bind(this)}>创建部署节点</Button>
          </Col>
        </Row>
        <SelectCertificatesModal visible={this.state.modalVisible} onOk={this.handleSelectCertificatesOk} onCancel={this.handleSelectCertificatesCancel}/>
      </div>
    )
  }

  renderCreatePushTask = () => {
    const {cloudServer, pushesCreated, certificatePushes} = this.state
    const certificatePushesMap = new Map(certificatePushes.map(item => [item.certificateId, item]))
    const tableColumns = [{
      title: '证书ID/名称',
      dataIndex: 'id',
      width: 220,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            <Button type='link' onClick={this.handleClickCertificate.bind(this, record.id)} style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'type',
      width: 70,
      render: (text, record) => {
        return <CertificateType type={record.type}/>
      }
    }, {
      title: '关联域名',
      dataIndex: 'domains',
      width: 250,
      render: (text, record) => {
        return <CertificateDomains domains={record.domains} onlyFirst={true} maxWidth={235}/>
      }
    }, {
      title: '部署状态',
      dataIndex: 'key',
      ellipsis: true,
      render: (text, record) => {
        if (!pushesCreated) {
          return '-'
        }

        if (!record.push) {
          return <span style={{color: 'orange'}}>部署中</span>
        }

        if (record.push.status === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return <span style={{color: 'orange'}}>部署中</span>
        } else if (record.push.status === CERTIFICATE_PUSH_STATUS.PUSHED) {
          return <span style={{color: 'green'}}>已成功</span>
        } else if (record.push.status === CERTIFICATE_PUSH_STATUS.ERROR) {
          return <span style={{color: 'red'}}>已失败：{record.push.error}</span>
        }
      }
    }, {
      title: '备注',
      dataIndex: 'push',
      key: 'push',
      ellipsis: true,
      render: (text, record) => {
        if (!record.push || record.push.status === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return '-'
        }

        if (record.push && cloudServer.cloudPushPosition === CLOUD_PUSH_POSITION.SSH) {
          return <Button type='link' style={{padding: '0px'}} onClick={this.handelOpenCertificatePushDetailModal.bind(this, record.push.certificateId,  record.push.id)} >点击查看执行详情</Button>
        }

        if (!record.push || !record.push.extra) {
          return '-'
        }

        switch(record.push.cloudId) {
          case CLOUD_ID.DOCKER:
            const extraInJSON = JSON.parse(record.push.extra)
            if (extraInJSON.failureNodesCount) {
              if (extraInJSON.successNodesCount) {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span>，<span style={{color: 'red'}}>更新失败{extraInJSON.failureNodesCount}个</span></span>
              } else {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'red'}}>部署失败{extraInJSON.failureNodesCount}个</span></span>
              }
            } else {
              return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span></span>
            }
          default:
            return record.push.extra
        }
      }
    }, {
      title: '', // 此列用于修复超长字段影响表格布局的bug, https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
      fixed: 'right',
      width: 1
    }]

    const tableData = cloudServer.associatedCertificates
    tableData.forEach(item => {
      item.key = item.id

      const certificatePush = certificatePushesMap.get(item.id)
      item.push = certificatePush
    })

    return (
      <div className='content'>
        {this.renderSteps()}
        <Row>
          <Col span={19} style={{lineHeight: '32px'}}>部署节点关联的证书列表</Col>
          <Col span={5}>
            {cloudServer.cloudPushPushable ? <Button block onClick={this.handleCreateCertificatePushes} disabled={pushesCreated}>立即部署以下关联证书至该部署节点</Button> : ''}
          </Col>
        </Row>
        <Divider/>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          size='small'
          pagination={{
            defaultPageSize: 5,
            total: tableData.length,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`
          }}
        />
        <Row style={{ marginTop: '20px' }}>
          <Col span={5} offset={19}>
            <Button block type='primary' onClick={this.handleGoMonitorCloudServers}>管理部署节点</Button>
          </Col>
        </Row>
        {this.renderCertificatePushDetailModal()}
      </div>
    )
  }

  render() {
    switch (this.state.currentStep) {
      case 0:
        return this.renderCreateCloudServer()
      case 1:
        return this.renderAddCertificates()
      case 2:
        return this.renderCreatePushTask()
      default:
        return <></>
    }
  }
}

CreateCloudServer = connect((state) => {
  return  {
    me: state.auth.me,
    cloudProviders: state.cloud.provider.cloudProviders,
    certificates: state.certificate.certificate.certificates
  }
})(CreateCloudServer)

export default CreateCloudServer
