import React from 'react'
import {connect} from 'react-redux'
import { getUrls } from '../redux/oauth'
import { OAUTH_TYPE } from '../utils/constant'
import { reportOAuthClick } from '../utils/report'

import githubIcon from '../assets/img/github.svg'
import gitlabIcon from '../assets/img/gitlab.png'
import giteeIcon from '../assets/img/gitee.png'

class OAuth extends React.Component {
  constructor (props) {
    super(props)
    this.props.dispatch(getUrls())
  }
 
  handleOAuthClick = () => {
    reportOAuthClick()
  }

  render() {
    const { urls = {} } = this.props
    return (
      <div className='oauth'>
        使用第三方账号登录：
        <a href={urls[OAUTH_TYPE.GITHUB]} onClick={this.handleOAuthClick}><img src={githubIcon} style={{height: 26}} alt='Github'/></a>
        <a href={urls[OAUTH_TYPE.GITLAB]} onClick={this.handleOAuthClick}><img src={gitlabIcon} style={{height: 26}} alt='Gitlab' /></a>
        <a href={urls[OAUTH_TYPE.GITEE]} onClick={this.handleOAuthClick}><img src={giteeIcon} style={{height: 26}} alt='Gitee' /></a>
      </div>
    )
  }
}

OAuth = connect((state) => {
  return {
    urls: state.oauth.urls
  }
})(OAuth)

export default OAuth
