import React from 'react'
import {connect} from 'react-redux'
import { Divider, message } from 'antd'
import { getCertificatePusheDetail } from '../redux/certificate/push'
import CodeEditor from '@uiw/react-textarea-code-editor'

import "@uiw/react-textarea-code-editor/dist.css"

class SSHPushDetail extends React.Component {
  constructor(props) {
    super(props)

    const { certificateId, certificatePushId } = props
    this.props.dispatch(getCertificatePusheDetail({ certificateId, certificatePushId })).then(result => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.setState({
        certificatePush: result.payload
      })
    })
    this.state = {
      certificatePush: null
    }
  }

  render() {
    if (!this.state.certificatePush) {
      return null
    }

    if (!this.state.certificatePush.detail) {
      return <>{this.state.certificatePush.error}</>
    }

    const { detail: {response: [{ cmd, stderr, stdout }]} } = this.state.certificatePush

    return (
      <>
        <Divider style={{marginTop: '0px'}}>完整命令（包含前置命令、系统命令、后置命令）</Divider>
        <CodeEditor
          value={cmd}
          language={'shell'}
          readOnly={true}
          disabled={true}
          padding={15}
          style={{
            backgroundColor: "#f5f5f5"
          }}
        />
        <Divider>标准输出（stdout）</Divider>
        <CodeEditor
          value={stdout}
          language={'shell'}
          readOnly={true}
          disabled={true}
          padding={15}
          style={{
            backgroundColor: "#f5f5f5"
          }}
        />
        <Divider>标准错误输出（stderr）</Divider>
        <CodeEditor
          value={stderr}
          language={'shell'}
          readOnly={true}
          disabled={true}
          padding={15}
          style={{
            backgroundColor: "#f5f5f5"
          }}
        />
      </>
    )
  }
}

SSHPushDetail = connect()(SSHPushDetail)

export default SSHPushDetail
