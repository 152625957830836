import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Table} from 'antd'

class OptionsModal extends React.Component {
  static props = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    options: PropTypes.array,
    renderOption: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      selectedOptions: []
    }
  }

  handleModalOk = () => {
    const {onOk} = this.props
    const {selectedOptions} = this.state
    onOk(selectedOptions)

    this.setState({
      selectedOptions: []
    })
  }

  handleModalCancel = () => {
    const {onCancel} = this.props
    onCancel()

    this.setState({
      selectedOptions: []
    })
  }

  handleOptionSelected = (value) => {
    this.setState({
      selectedOptions: value
    })
  }

  handleClickRow = (value) => {
    const {selectedOptions} = this.state
    if (selectedOptions.indexOf(value) >= 0) {
      this.setState({
        selectedOptions: selectedOptions.filter(item => item !== value)
      })
    } else {
      this.setState({
        selectedOptions: [...selectedOptions, value]
      })
    }
  }

  render () {
    const {title, visible, options, renderOption} = this.props

    const tableColumns = [{
      dataIndex: 'key',
      render: (text, record) => {
        if (renderOption) {
          return renderOption(record)
        } else {
          return record.label
        }
      }
    }]

    const tableData = options.map(item => {
      item.key = item.value
      return item
    })

    return (
      <Modal
        title={title}
        visible={visible}        
        okType='primary'
        okButtonProps={{size: 'small'}}
        cancelButtonProps={{size: 'small'}}
        onOk={this.handleModalOk}
        onCancel={this.handleModalCancel}
      >
        <Table
          columns={tableColumns}
          dataSource={tableData}
          size='small'
          showHeader={false}
          pagination={false}
          rowSelection={{
            selectedRowKeys: this.state.selectedOptions,
            onChange: this.handleOptionSelected,
            getCheckboxProps: record => ({
              disabled: record.disabled
            }),
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.disabled) {
                  return
                }

                this.handleClickRow(record.key)
              } 
            }
          }}
        />
      </Modal>
    )
  }
}

export default OptionsModal
