import React from 'react'
import queryString from 'query-string'
import { Switch, Route } from 'react-router-dom'
import routes from '../routes'

class Index extends React.Component {
  componentDidMount = () => {
    const {invitationCode} = queryString.parse(this.props.location.search)
    if (invitationCode) {
      localStorage.setItem('INVITATION_CODE', invitationCode)
    }
  }

  renderRoutes = () => {
    return (
      <Switch>{routes.index.map((item, index) => {
        return (
          <Route
            path={item.layout + item.path}
            component={item.component}
            key={index}
          />
        )
      })}</Switch>
    )
  }

  render () {
    return (
      <div className='www'>
        {this.renderRoutes()}
      </div>
    )
  }
}

export default Index
