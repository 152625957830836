import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button, Radio, message} from 'antd'
import { getDnsServerDetail, changeDnsServerAccessJson } from '../redux/dns/server'
import { getDnsProviders } from '../redux/dns/provider'
import ConfigField from './ConfigField'
import { checkAccessJson } from '../utils'

class UpdateDnsServer extends React.Component {
  static props = {
    dnsServerId: PropTypes.number,
    onUpdateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      dnsServerAccessJson: null,
      loading: false
    }

    this.props.dispatch(getDnsProviders())
    this.props.dispatch(getDnsServerDetail({dnsServerId: props.dnsServerId})).then((result) => {
      this.setState({
        dnsServerAccessJson: result.payload.accessJson
      })
    })
  }

  handleEditDnsServer = (field, value) => {
    const accessJson = { ...this.state.dnsServerAccessJson }
    accessJson[field] = value
    this.setState({
      dnsServerAccessJson: accessJson,
      dnsServerVerified: false
    })
  }

  handleClickUpdate = () => {
    const {dnsServerAccessJson} = this.state
    const {onUpdateComplete, dnsServerDetail, dnsProviders} = this.props
    const {dnsProviderId} = dnsServerDetail
    const dnsProvider = dnsProviders.find(item => item.id === dnsProviderId)
    const { configJson } = dnsProvider

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: dnsServerAccessJson})
    if (error) {
      return message.error(error)
    }

    this.setState({
      loading: true
    })

    message.warn('正在校验DNS授权参数配置是否正确，请耐心等待')
    this.props.dispatch(changeDnsServerAccessJson({dnsServerId: dnsServerDetail.id, accessJson: dnsServerAccessJson}))
      .then(response => {
        this.setState({
          loading: false
        })

        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('DNS授权修改成功')

        if (onUpdateComplete) {
          onUpdateComplete()
        }
      })
  }

  render() {
    const { dnsProviders } = this.props
    if (!dnsProviders.length) {
      return <></>
    }

    const {dnsServerDetail} = this.props
    if (!dnsServerDetail) {
      return <></>
    }

    const { dnsServerAccessJson, loading } = this.state
    const dnsProviderId = dnsServerDetail.dnsProviderId

    const dnsProvider = dnsProviders.find(item => item.id === dnsProviderId)
    const { fields } = dnsProvider.configJson

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group buttonStyle='solid'>
            <Radio.Button>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={dnsServerDetail.dnsProviderLogo} alt='' width={15} height={15} />&nbsp;{dnsServerDetail.dnsProviderName}
              </div>
            </Radio.Button>
          </Radio.Group>
          <div>
            {fields.map((item, index) => {
              const value = (dnsServerAccessJson || {})[item.field]
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditDnsServer} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='end' gutter={16} style={{ marginTop: '10px' }} >
            <Col span={18} />
            <Col span={6}>
              <Button onClick={this.handleClickUpdate} type='primary' loading={loading} block>{loading ? '参数校验中...' : '确认修改'}</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

UpdateDnsServer = connect((state) => {
  return  {
    dnsProviders: state.dns.provider.dnsProviders,
    dnsServerDetail: state.dns.server.dnsServerDetail
  }
})(UpdateDnsServer)

export default UpdateDnsServer
