const GET_MESSAGES_ING = 'MESSAGE/GET_MESSAGES_ING'
const GET_MESSAGES_SUCCESS = 'MESSAGE/GET_MESSAGES_SUCCESS'
const GET_MESSAGES_FAILURE = 'MESSAGE/GET_MESSAGES_FAILURE'

const SET_MESSAGES_BE_VIEWED_ING = 'MESSAGE/SET_MESSAGES_BE_VIEWED_ING'
const SET_MESSAGES_BE_VIEWED_SUCCESS = 'MESSAGE/SET_MESSAGES_BE_VIEWED_SUCCESS'
const SET_MESSAGES_BE_VIEWED_FAILURE = 'MESSAGE/SET_MESSAGES_BE_VIEWED_FAILURE'

const REMOVE_MESSAGES_ING = 'MESSAGE/REMOVE_MESSAGES_ING'
const REMOVE_MESSAGES_SUCCESS = 'MESSAGE/REMOVE_MESSAGES_SUCCESS'
const REMOVE_MESSAGES_FAILURE = 'MESSAGE/REMOVE_MESSAGES_FAILURE'

const GET_MESSAGE_DETAIL_ING = 'MESSAGE/GET_MESSAGE_DETAIL_ING'
const GET_MESSAGE_DETAIL_SUCCESS = 'MESSAGE/GET_MESSAGE_DETAIL_SUCCESS'
const GET_MESSAGE_DETAIL_FAILURE = 'MESSAGE/GET_MESSAGE_DETAIL_FAILURE'

const defaultState = {
  messages: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload
      }
    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          count: 0,
          rows: []
        }
      }
    case GET_MESSAGE_DETAIL_SUCCESS:
      return {
        ...state,
        messageDetail: action.payload
      }
    case GET_MESSAGE_DETAIL_FAILURE:
      return {
        ...state,
        messageDetail: null
      }
    default:
      return {...state}
  }
}

export const getMessages = ({offset, limit}) => {
  return {
    types: [GET_MESSAGES_ING, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAILURE],
    promise: (apiClient) => apiClient.get('/api/messages', {params: {offset, limit}})
  }
}

export const setMessagesBeViewed = ({messageIds}) => {
  return {
    types: [SET_MESSAGES_BE_VIEWED_ING, SET_MESSAGES_BE_VIEWED_SUCCESS, SET_MESSAGES_BE_VIEWED_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/messages`, {messageIds})
  }
}

export const removeMessages = ({messageIds}) => {
  return {
    types: [REMOVE_MESSAGES_ING, REMOVE_MESSAGES_SUCCESS, REMOVE_MESSAGES_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/messages`, {data: {messageIds}})
  }
}

export const getMessageDetail = ({messageId}) => {
  return {
    types: [GET_MESSAGE_DETAIL_ING, GET_MESSAGE_DETAIL_SUCCESS, GET_MESSAGE_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/messages/${messageId}`)
  }
}
