import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Input, message, Checkbox, Icon, Badge } from 'antd'
import Clipboard from 'react-clipboard.js'
import { getMyInvitations, receiveInvitationGift } from '../../redux/auth'

class MyInvitations extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit, haveGiftNotReceive} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)
    haveGiftNotReceive = haveGiftNotReceive === 'true'

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      inviteeEmail: search || '',
      haveGiftNotReceive: haveGiftNotReceive,
      current,
      offset,
      limit
    }
    this.searchMyInvitations()
  }

  refreshUrl = ({inviteeEmail, haveGiftNotReceive, offset, limit}) => {
    this.props.history.replace(`${this.props.location.pathname}?search=${encodeURIComponent(inviteeEmail)}&haveGiftNotReceive=${encodeURIComponent(haveGiftNotReceive)}&offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}`)
  }

  searchMyInvitations = (state) => {
    const {inviteeEmail, haveGiftNotReceive, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({inviteeEmail, haveGiftNotReceive, offset, limit})

    this.props.dispatch(getMyInvitations({inviteeEmail, haveGiftNotReceive, offset, limit})).then(() => {
      this.setState({
        firstLoading: false
      })
    })
  }

  get tableColumns () {
    const {configs} = this.props
    return [{
      title: '时间',
      dataIndex: 'createTime',
      render: (createTime) => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '注册用户',
      dataIndex: 'inviteeEmail'
    }, {
      title: '邀请注册奖励',
      dataIndex: 'giftBeansOfInvitation',
      render: (text, record) => {
        if (!record.giftBeansOfInvitation) {
          return '-'
        }

        if (!record.giftOfInvitationReceived) {
          return (
            <>
              {record.giftBeansOfInvitation}<span className='tag-warning-filled ml5'>待领取</span>
            </>
          )
        } else {
          return (
            <>
              {record.giftBeansOfInvitation}<span className='tag-success-line ml5'>已领取</span>
            </>
          )
        }
      }
    }, {
      title: '邀请充值奖励',
      dataIndex: 'giftBeansOfCharge',
      render: (text, record) => {
        if (!record.giftBeansOfCharge) {
          return (
            <>
              <span className='tag-info-line'>待该用户充值后您可领取{configs.giftBeansOfInviteeCharge}余额奖励</span>
            </>
          )
        }

        if (!record.giftOfChargeReceived) {
          return (
            <>
              {record.giftBeansOfCharge}<span className='tag-warning-filled ml5'>待领取</span>
            </>
          )
        } else {
          return (
            <>
              {record.giftBeansOfCharge}<span className='tag-success-line ml5'>已领取</span>
            </>
          )
        }
      }
    }, {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        let haveGiftNotReceive
        if (record.giftBeansOfInvitation && !record.giftOfInvitationReceived) {
          haveGiftNotReceive = true
        }
        if (record.giftBeansOfCharge && !record.giftOfChargeReceived) {
          haveGiftNotReceive = true
        }

        if (haveGiftNotReceive) {
          return (
            <>
              <Button onClick={this.handleReceiveInvitationGift.bind(this, record)} size='small' icon='money-collect' type='primary' style={{width: 72}}>领&nbsp;取</Button>
            </>
          )
        } else {
          if (record.giftBeansOfInvitation || record.giftBeansOfCharge) {
            return (
              <>
                <Button onClick={this.handleReceiveInvitationGift.bind(this, record)} size='small' icon='money-collect' disabled>已领取</Button>
              </>
            )
          } else {
            return (
              <>
                <Button onClick={this.handleReceiveInvitationGift.bind(this, record)} size='small' type="dashed" icon='money-collect' disabled>未领取</Button>
              </>
            )
          }
        }
      }
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.invitations.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchMyInvitations({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchMyInvitations({
      offset,
      limit
    })
  }

  handleReceiveInvitationGift = (invitation, e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.dispatch(receiveInvitationGift({invitationId: invitation.id})).then((response) => {
      if (response.errorMsg) {
        return message.error(response.errorMsg)
      } else {
        message.success(`已成功领取${response.payload.giftBeansOfReceived}余额`)

        this.searchMyInvitations()
      }
    })
  }

  handleChangeInviteeEmail = (e) => {
    this.setState({
      inviteeEmail: e.target.value
    })
  }

  handleCopyInvitationUrlSuccess = () => {
    message.success('专属邀请链接复制成功')
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 10
    })
    this.searchMyInvitations({offset: 0, limit: 10})
  }

  render () {
    const {inviteeEmail, haveGiftNotReceive, firstLoading} = this.state
    const {configs, invitationUrl, invitations} = this.props

    return (
      <div className='content my-invitations'>
        <div className='invitation-area' style={{marginBottom: '12px'}}>
          <Input
            addonBefore="我的专属邀请链接"
            addonAfter={
              <Clipboard className='ant-btn' data-clipboard-text={invitationUrl} onSuccess={this.handleCopyInvitationUrlSuccess}>
                <Icon type='copy'/>&nbsp;点击复制
              </Clipboard>
            }
            value={invitationUrl} 
          />
          <Badge status="processing" text={<>用户通过您的专属邀请链接注册成功，被邀请用户将获得 <b style={{color: 'black'}}>{configs.giftBeansOfAsInvitee + configs.giftBeansOfNewbee}</b> 初始余额；如果被邀请用户成为付费用户，您将获得 <b style={{color: 'black'}}>{configs.giftBeansOfInviteeCharge}</b> 余额奖励</>} />
        </div>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入注册用户邮箱查询" onSearch={this.handleClickSearch} enterButton value={inviteeEmail} onChange={this.handleChangeInviteeEmail} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <div className='beans-transaction-types'>
              <Checkbox onChange={e => this.setState({haveGiftNotReceive: e.target.checked})} checked={haveGiftNotReceive}>只显示未领取奖励记录</Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Button icon='search' onClick={this.handleClickSearch} type='primary'>查询</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: invitations.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
      </div>
    )
  }
}

MyInvitations = connect((state) => {
  return {
    invitationUrl: state.auth.me.invitationUrl,
    invitations: state.auth.invitations,
    configs: state.common.configs
  }
})(MyInvitations)

export default MyInvitations
