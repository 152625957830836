const GET_DNS_SERVERS_ING = 'DNS/SERVER/GET_DNS_SERVERS_ING'
const GET_DNS_SERVERS_SUCCESS = 'DNS/SERVER/GET_DNS_SERVERS_SUCCESS'
const GET_DNS_SERVERS_FAILURE = 'DNS/SERVER/GET_DNS_SERVERS_FAILURE'

const GET_DNS_SERVER_DETAIL_ING = 'DNS/SERVER/GET_DNS_SERVER_DETAIL_ING'
const GET_DNS_SERVER_DETAIL_SUCCESS = 'DNS/SERVER/GET_DNS_SERVER_DETAIL_SUCCESS'
const GET_DNS_SERVER_DETAIL_FAILURE = 'DNS/SERVER/GET_DNS_SERVER_DETAIL_FAILURE'

const CREATE_DNS_SERVER_ING = 'DNS/SERVER/CREATE_DNS_SERVER_ING'
const CREATE_DNS_SERVER_SUCCESS = 'DNS/SERVER/CREATE_DNS_SERVER_SUCCESS'
const CREATE_DNS_SERVER_FAILURE = 'DNS/SERVER/CREATE_DNS_SERVER_FAILURE'

const REMOVE_DNS_SERVER_ING = 'DNS/SERVER/REMOVE_DNS_SERVER_ING'
const REMOVE_DNS_SERVER_SUCCESS = 'DNS/SERVER/REMOVE_DNS_SERVER_SUCCESS'
const REMOVE_DNS_SERVER_FAILURE = 'DNS/SERVER/REMOVE_DNS_SERVER_FAILURE'

const CHANGE_DNS_SERVER_ACCESS_JSON_ING = 'DNS/SERVER/CHANGE_DNS_SERVER_ACCESS_JSON_ING'
const CHANGE_DNS_SERVER_ACCESS_JSON_SUCCESS = 'DNS/SERVER/CHANGE_DNS_SERVER_ACCESS_JSON_SUCCESS'
const CHANGE_DNS_SERVER_ACCESS_JSON_FAILURE = 'DNS/SERVER/CHANGE_DNS_SERVER_ACCESS_JSON_FAILURE'

const VERIFY_DNS_SERVER_ING = 'DNS/SERVER/VERIFY_DNS_SERVER_ING'
const VERIFY_DNS_SERVER_SUCCESS = 'DNS/SERVER/VERIFY_DNS_SERVER_SUCCESS'
const VERIFY_DNS_SERVER_FAILURE = 'DNS/SERVER/VERIFY_DNS_SERVER_FAILURE'

const CHANGE_DNS_SERVER_ALIAS_ING = 'DNS/SERVER/CHANGE_DNS_SERVER_ALIAS_ING'
const CHANGE_DNS_SERVER_ALIAS_SUCCESS = 'DNS/SERVER/CHANGE_DNS_SERVER_ALIAS_SUCCESS'
const CHANGE_DNS_SERVER_ALIAS_FAILURE = 'DNS/SERVER/CHANGE_DNS_SERVER_ALIAS_FAILURE'

const defaultState = {
  dnsServers: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DNS_SERVERS_SUCCESS:
      return {
        ...state,
        dnsServers: action.payload
      }
    case GET_DNS_SERVERS_FAILURE:
      return {
        ...state,
        dnsServers: {
          count: 0,
          rows: []
        }
      }
    case GET_DNS_SERVER_DETAIL_SUCCESS:
      return {
        ...state,
        dnsServerDetail: action.payload
      }
    case GET_DNS_SERVER_DETAIL_FAILURE:
      return {
        ...state,
        dnsServerDetail: null
      }
    default:
      return {...state}
  }
}

export const createDnsServer = ({dnsProviderId, accessJson}) => {
  return {
    types: [CREATE_DNS_SERVER_ING, CREATE_DNS_SERVER_SUCCESS, CREATE_DNS_SERVER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/dns/servers', {dnsProviderId, accessJson})
  }
}

export const verifyDnsServer = ({dnsServerId, dnsProviderId, accessJson, certificateType, domains}) => {
  const body = {certificateType, domains}
  if (dnsServerId) {
    body.dnsServerId = dnsServerId
  } else {
    body.dnsProviderId = dnsProviderId
    body.accessJson = accessJson
  }
  return {
    types: [VERIFY_DNS_SERVER_ING, VERIFY_DNS_SERVER_SUCCESS, VERIFY_DNS_SERVER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/dns/servers/verify', body)
  }
}

export const getDnsServers = ({searchContent, offset, limit}) => {
  return {
    types: [GET_DNS_SERVERS_ING, GET_DNS_SERVERS_SUCCESS, GET_DNS_SERVERS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/dns/servers', {params: {searchContent, offset, limit}})
  }
}

export const getDnsServerDetail = ({dnsServerId}) => {
  return {
    types: [GET_DNS_SERVER_DETAIL_ING, GET_DNS_SERVER_DETAIL_SUCCESS, GET_DNS_SERVER_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/dns/servers/${dnsServerId}`)
  }
}

export const changeDnsServerAlias = (dnsServerId, alias) => {
  return {
    types: [CHANGE_DNS_SERVER_ALIAS_ING, CHANGE_DNS_SERVER_ALIAS_SUCCESS, CHANGE_DNS_SERVER_ALIAS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/dns/servers/${dnsServerId}/alias`, {alias})
  }
}

export const changeDnsServerAccessJson = ({dnsServerId, accessJson}) => {
  return {
    types: [CHANGE_DNS_SERVER_ACCESS_JSON_ING, CHANGE_DNS_SERVER_ACCESS_JSON_SUCCESS, CHANGE_DNS_SERVER_ACCESS_JSON_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/dns/servers/${dnsServerId}/accessJson`, {accessJson})
  }
}

export const removeDnsServer = ({dnsServerId}) => {
  return {
    types: [REMOVE_DNS_SERVER_ING, REMOVE_DNS_SERVER_SUCCESS, REMOVE_DNS_SERVER_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/dns/servers/${dnsServerId}`)
  }
}
