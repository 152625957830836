import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button, message, Modal } from 'antd'
import { closeAccount, signout } from '../redux/auth'
import { sendEmail } from '../redux/common'
import { EMAIL_TEMPLATE } from '../utils/constant'

class CloseAccount extends React.Component {
  static props = {
    history: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = this.getInitState()
  }

  getInitState = () => {
    return {
      emailSended: false,
      emailCanSendAfterSeconds: 60,
      emailVerificationCode: ''
    }
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.emailTimer) {
      clearInterval(this.emailTimer)
      this.emailTimer = null
    }
  }

  handleChangeEmailVerificationCode = (e) => {
    this.setState({
      emailVerificationCode: e.target.value
    })
  }

  handleSendEmailVerification = () => {
    const { emailSended, emailCanSendAfterSeconds } = this.state
    const email = this.props.me.email
    if (emailSended && emailCanSendAfterSeconds >= 1) {
      return message.error('发送频率过高，请稍后重试')
    }

    this.props.dispatch(sendEmail({ email: email, templateId: EMAIL_TEMPLATE.VERIFICATION_CODE })).then(result => {
      if (this.isUnmounted) {
        return
      }

      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.setState({
        emailCanSendAfterSeconds: 60,
        emailSended: true
      })

      this.emailTimer = setInterval(() => {
        const { emailCanSendAfterSeconds } = this.state

        this.setState({
          emailCanSendAfterSeconds: emailCanSendAfterSeconds - 1
        })

        if (emailCanSendAfterSeconds <= 1) {
          clearInterval(this.emailTimer)
        }
      }, 1000)

      message.success('验证码已成功发送至您的邮箱，请至您的邮箱查收验证码')
    })
  }

  handleClickCloseAccount = () => {
    const {emailVerificationCode} = this.state
    if (!emailVerificationCode) {
      return message.error('请输入邮箱验证码')
    }

    Modal.confirm({
      title: '确认要注销账户吗?',
      content: '注销后账户内所有内容会被删除并无法恢复，将无法继续使用当前账户登录，同时将无法再次使用当前邮箱进行注册，请谨慎操作',
      okType: 'danger',
      icon: 'warning',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(closeAccount({emailVerificationCode}))
        .then((result) => {
          if (result.errorMsg) {
            return message.error(result.errorMsg)
          } else {
            Modal.success({
              content: '注销账户成功，感谢您的使用',
              okText: 'OK',
              onOk: () => {
                this.props.dispatch(signout())

                if (this.props.history) {
                  this.props.history.push('/')
                } else {
                  window.location.href = '/'
                }

                setTimeout(() => {
                  localStorage.removeItem('TOKEN')
                })
              }
            })
          }
        }).catch((error) => {
          return message.error(`未知异常:${error}`)
        })
      },
      onCancel: () => {
      },
    })
  }

  render () {
    return (
      <>
        <Form>
          <Form.Item>
            <Input.Search
              placeholder='请输入邮箱验证码'
              maxLength={6}
              enterButton={this.state.emailSended ? this.state.emailCanSendAfterSeconds >= 1 ? `${this.state.emailCanSendAfterSeconds}秒后可重新发送` : '重新发送验证码至邮箱' : '发送验证码至邮箱'}
              onChange={this.handleChangeEmailVerificationCode}
              onSearch={this.handleSendEmailVerification}
            />
          </Form.Item>
          <Button type='primary' block onClick={this.handleClickCloseAccount}>注销账户</Button>
        </Form>
      </>
    )
  }
}

CloseAccount = connect((state) => {
  return {
    me: state.auth.me
  }
})(CloseAccount)

export default CloseAccount
