import React from 'react'
import { Alert } from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {pathToRegexp} from 'path-to-regexp'
import { getMyAlerts, closeMyAlert } from '../redux/auth'

class MyAlert extends React.Component {
  static props = {
    currentPath: PropTypes.string
  }

  constructor (props) {
    super(props)

    if (!this.props.alerts) {
      this.props.dispatch(getMyAlerts())
    }
  }

  handleCloseAlert = (alertId) => {
    this.props.dispatch(closeMyAlert({alertId}))
  }

  render () {
    const {alerts, currentPath} = this.props
    if (!alerts) {
      return <></>
    }

    const validAlerts = alerts.filter(alert => {
      const regExp = pathToRegexp(alert.path)
      return regExp.test(currentPath)
    })

    return (
      <>
        {validAlerts.map(alert => {
          return <Alert type={alert.type.toLowerCase()} closable={alert.closable} message={alert.message} key={alert.id} onClose={this.handleCloseAlert.bind(this, alert.id)}/>
        })}
      </>
    )
  }
}

MyAlert = connect((state) => {
  return {
    alerts: state.auth.alerts
  }
})(MyAlert)

export default MyAlert
