import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, message } from 'antd'
import { sendEmail } from '../../redux/common'
import { EMAIL_TEMPLATE } from '../../utils/constant'

class Forget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: ''
    }
  }

  componentDidMount() {
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickSend()
        }
      }
    }
  }

  componentWillUnmount() {
    if (window) {
      window.onkeypress = null
    }
  }

  handleClickSend = () => {
    const { email } = this.state

    if (!email || !email.trim()) {
      return message.error('请输入您的注册邮箱')
    }

    this.props.dispatch(sendEmail({ email, templateId: EMAIL_TEMPLATE.RESET_PASSWORD }))
      .then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          message.success({
            content: '重置密码邮件发送成功',
            onClose: () => this.props.history.replace('/')
          })
        }
      }).catch((error) => {
        return message.error(`未知异常:${error}`)
      })
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handleClickSignin = () => {
    this.props.history.push('/signin')
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="area forget">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Form.Item>
              <Input
                placeholder='请输入您的注册邮箱'
                onChange={this.handleChangeEmail}
              />
            </Form.Item>
            <Button type='primary' block onClick={this.handleClickSend}>点击发送密码重置邮件</Button>
            <Button type='link' className='forget' onClick={this.handleClickSignin}>想起密码？点击登录</Button>
          </Form>
        </div>
      </div>
    )
  }
}

Forget = connect()(Forget)

export default Forget
