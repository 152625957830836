import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, message, Checkbox } from 'antd'
import { signup } from '../../redux/auth'
import { sendEmail } from '../../redux/common'
import { EMAIL_TEMPLATE } from '../../utils/constant'
import { reportSignupClick, reportSignupExp, reportSignupSuccess } from '../../utils/report'
import OAuth from '../../components/OAuth'

class Signup extends React.Component {
  constructor(props) {
    super(props)
    const urlObj = new URL(window.location.href)
    const email = urlObj.searchParams.get('email') || ''
    const domain = urlObj.searchParams.get('domain') || ''
    this.state = {
      email,
      password: '',
      verificationCode: '',
      emailSended: false,
      canSendAfterSeconds: 60,
      domain,
      termsAndPrivacyReaded: false
    }
  }

  componentDidMount() {
    reportSignupExp()
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickSignup()
        }
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
    if (window) {
      window.onkeypress = null
    }
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  handleClickSignup = () => {
    const { email, password, verificationCode, termsAndPrivacyReaded, domain } = this.state

    if (!email || !email.trim()) {
      return message.error('请输入您的邮箱')
    }

    if (!password || !password.trim()) {
      return message.error('请输入您的密码')
    }

    if (!verificationCode || !verificationCode.trim()) {
      return message.error('请输入邮箱验证码')
    }

    if (!termsAndPrivacyReaded) {
      return message.error('请先阅读并同意使用条款和隐私政策')
    }

    const invitationCode = localStorage.getItem('INVITATION_CODE') || ''
    this.props.dispatch(signup({ email, password, verificationCode, invitationCode }))
      .then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          if (invitationCode) {
            localStorage.removeItem('INVITATION_CODE')
          }
          if (domain) {
            this.props.history.replace(`/guide/createcertificate?domain=${encodeURIComponent(domain)}`)
          } else {
            this.props.history.replace('/guide/createcertificate')
          }
          reportSignupSuccess()
        }
      }).catch((error) => {
        return message.error(`未知异常:${error}`)
      })
    
    reportSignupClick()
  }

  handleChangeEmail = (e) => {
    const email = e.target.value
    this.setState({
      email,
      emailSended: false,
      canSendAfterSeconds: 60
    })
  }

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleChangeVerificationCode = (e) => {
    this.setState({
      verificationCode: e.target.value
    })
  }

  handleClickSignin = () => {
    this.props.history.push('/signin')
  }

  handleSendEmailVerification = () => {
    const { email, emailSended, canSendAfterSeconds } = this.state
    if (emailSended && canSendAfterSeconds >= 1) {
      return message.error('发送频率过高，请稍后重试')
    }

    if (!email || !email.trim()) {
      return message.error('请输入您的邮箱')
    }

    this.props.dispatch(sendEmail({ email, templateId: EMAIL_TEMPLATE.VERIFICATION_CODE })).then(result => {
      if (this.isUnmounted) {
        return
      }

      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.setState({
        canSendAfterSeconds: 60,
        emailSended: true
      })

      this.timer = setInterval(() => {
        const { canSendAfterSeconds } = this.state

        this.setState({
          canSendAfterSeconds: canSendAfterSeconds - 1
        })

        if (canSendAfterSeconds <= 1) {
          clearInterval(this.timer)
        }
      }, 1000)

      message.success('验证码已成功发送至您的邮箱')
    })
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  handleChangeTermsAndPrivacyReaded =(e) => {
    this.setState({
      termsAndPrivacyReaded: e.target.checked
    })
  }

  render() {
    const { email, emailSended, canSendAfterSeconds } = this.state
    return (
      <div className="area signup">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Form.Item>
              <Input
                value={email}
                placeholder='请输入您的邮箱'
                onChange={this.handleChangeEmail}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                placeholder='请设置您的密码'
                visibilityToggle={false}
                onChange={this.handleChangePassword}
              />
            </Form.Item>
            <Form.Item>
              <Input.Search
                placeholder='请输入邮箱验证码'
                maxLength={6}
                enterButton={emailSended ? canSendAfterSeconds >= 1 ? `${canSendAfterSeconds}秒后可重新发送` : '重新发送邮件验证码' : '发送邮件验证码'}
                onChange={this.handleChangeVerificationCode}
                onSearch={this.handleSendEmailVerification}
              />
            </Form.Item>
            <div style={{display: 'flex', justifyContent: 'fetch-start', alignItems: 'center', color: "rgba(0, 0, 0, 0,65)", fontSize: '12px', margin: '-2px 0 22px'}}>
              <Checkbox checked={this.state.termsAndPrivacyReaded} onChange={this.handleChangeTermsAndPrivacyReaded}/>
              &nbsp;我已阅读并同意
              <a href='/docs/terms' target='_blank' style={{}} >使用条款</a>、
              <a href='/docs/privacy' target='_blank' style={{color: "rgba(0, 0, 0, 0,65)", fontSize: '12px'}}  >隐私政策</a>
            </div>
            <Button type='primary' block onClick={this.handleClickSignup}>注册</Button>
            <OAuth/>
            <Button type='link' onClick={this.handleClickSignin}>已有账号？直接登录</Button>
          </Form>
        </div>
      </div>
    )
  }
}

Signup = connect()(Signup)

export default Signup
