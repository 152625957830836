import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Menu, Icon, Avatar, Dropdown, Drawer, Layout } from 'antd'
import Customer from '../components/Customer'
import SetPwd from '../components/SetPwd'
import InitPwd from './InitPwd'
import SetEmail from '../components/SetEmail'
import TuCao from '../components/TuCao'
import { getMe, signout } from '../redux/auth'
import Charge from '../components/Charge'

import avatarImg from '../assets/svg/avatar.svg'
import CloseAccount from './CloseAccount'

class Header extends React.Component {
  static props = {
    history: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      customerDrawerVisible: false,
      chargeDrawerVisible: false,
      setPwdDrawerVisible: false,
      initPwdDrawerVisible: false,
      setEmailDrawerVisible: false,
      closeAccountDrawerVisible: false
    }
  }

  get avartarMenus () {
    return (
      <Menu onClick={this.handleClickAvatarMenu}>
        {!this.props.me.hasPassword && (<Menu.Item key='init-password'><Icon type='lock'/>设置密码</Menu.Item>)}
        {this.props.me.hasPassword && (<Menu.Item key='change-password'><Icon type='lock'/>修改密码</Menu.Item>)}
        <Menu.Item key='change-email'><Icon type='mail'/>变更邮箱</Menu.Item>
        <Menu.Item key='close-account'><Icon type='user-delete'/>注销账户</Menu.Item>
        <Menu.Item key='account-signout'><Icon type='export'/>安全退出</Menu.Item>
      </Menu>
    )
  }

  get toolMenus () {
    return (
      <Menu onClick={this.handleClickToolMenu}>
        <Menu.Item key='convert-certificate'><Icon type='swap'/>证书格式转换</Menu.Item>
        <Menu.Item key='generate-ssl-config'><Icon type='control'/>SSL配置生成</Menu.Item>
        <Menu.Item key='lookup-dns-record'><Icon type='global'/>DNS解析查询</Menu.Item>
        <Menu.Item key='dns-sec-check'><Icon type='experiment'/>DNSSEC检测</Menu.Item>
      </Menu>
    )
  }

  handleClickAvatarMenu = ({key}) => {
    switch (key) {
      case 'init-password':
        this.setState({
          initPwdDrawerVisible: true
        })
        break
      case 'change-password':
        this.setState({
          setPwdDrawerVisible: true
        })
        break
      case 'change-email':
        this.setState({
          setEmailDrawerVisible: true
        })
        break
      case 'account-signout':
        this.handleClickLogout()
        break
      case 'close-account':
        this.setState({
          closeAccountDrawerVisible: true
        })
        break
      default:
        return
    }
  }

  handleClickToolMenu = ({key}) => {
    switch (key) {
      case 'convert-certificate':
        window.open('https://myssl.com/cert_convert.html')
        break
      case 'generate-ssl-config':
        window.open('https://ssl-config.mozilla.org/')
        break
      case 'lookup-dns-record':
        window.open('https://toolbox.googleapps.com/apps/dig/#')
        break
      case 'dns-sec-check':
        window.open('https://dnsviz.net/')
        break
      default:
        return
    }
  }

  handleCustomerDrawerClose = () => {
    this.setState({
      customerDrawerVisible: false
    })
  }

  handleSetPwdDrawerClose = () => {
    this.setState({
      setPwdDrawerVisible: false
    })
  }

  handleInitPwdDrawerClose = () => {
    this.setState({
      initPwdDrawerVisible: false
    })
  }

  handleChargeDrawerClose = () => {
    this.setState({
      chargeDrawerVisible: false
    })
  }

  handleSetEmailDrawerClose = () => {
    this.setState({
      setEmailDrawerVisible: false
    })
  }

  handleCloseAccountDrawerClose = () => {
    this.setState({
      closeAccountDrawerVisible: false
    })
  }

  handleClickDocMenu = () => {
    window.open('/docs')
  }

  handleClickStartMenu = () => {
    window.open('/docs/start')
  }

  handleClickHelpMenu = () => {
    this.setState({
      customerDrawerVisible: true
    })
  }

  handleClickBeansMenu = () => {
    this.setState({
      chargeDrawerVisible: true
    })
  }

  handleClickLogout = () => {
    this.props.dispatch(signout())

    if (this.props.history) {
      this.props.history.push('/signin')
    } else {
      window.location.href = '/signin'
    }
    
    setTimeout(() => {
      localStorage.removeItem('TOKEN')
    })
  }

  handleClickLogo = () => {
    const {me} = this.props
    const targetUrl = me ? '/monitor/dashboard' : '/'
    if (this.props.history) {
      this.props.history.push(targetUrl)
    } else {
      window.location.href = targetUrl
    }
  }

  handleCompleteSetPassword = () => {
    this.handleSetPwdDrawerClose()
  }

  handleCompleteInitPassword = () => {
    this.props.dispatch(getMe())
    this.handleInitPwdDrawerClose()
  }

  handleCompleteSetEmail = () => {
    this.handleSetEmailDrawerClose()
  }

  render () {
    const {me} = this.props
    return (
      <Layout.Header className='header' theme='dark' >
        <div style={me ? {} : {maxWidth: 1200, padding: '0 15px'}}>
          <div className='logo' onClick={this.handleClickLogo.bind(this)}>
            <div style={{borderRadius: '10px', height: 20, width: 20, border: "2px solid white", marginRight: '1px', boxSizing: 'border-box'}}></div>HTTPS
          </div>
          <div className='menu'>
            <TuCao userId={me.userId} email={me.email}/>
            <Dropdown overlay={this.toolMenus}>
              <div>
                <Icon type="tool"/>&nbsp;工具箱&nbsp;<Icon type="caret-down"/>
              </div>
            </Dropdown>
            <div className='menu' onClick={this.handleClickStartMenu.bind(this)}>
              <Icon type='thunderbolt'/>
              &nbsp;快速上手
            </div>
            <div className='menu' onClick={this.handleClickDocMenu.bind(this)}>
              <Icon type='file-search'/>
              &nbsp;帮助文档
            </div>
            <div className='menu' onClick={this.handleClickHelpMenu.bind(this)}>
              <Icon type="customer-service"/>
              &nbsp;联系客服
            </div>
            <div className='menu' onClick={this.handleClickBeansMenu.bind(this)}>
              <Icon type="pay-circle"/>
              &nbsp;余额充值
            </div>
            <Dropdown overlay={this.avartarMenus}>
              <div>
                <Avatar src={avatarImg} size='small'/>
                &nbsp;
                {me.nickName}
                &nbsp;
                <Icon type="caret-down"/>
              </div>
            </Dropdown>
            {
              this.state.customerDrawerVisible && 
              <Drawer
                title="联系客服"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleCustomerDrawerClose}
                visible={this.state.customerDrawerVisible}
              >
                <Customer />
              </Drawer>
            }
            {
              this.state.initPwdDrawerVisible &&
              <Drawer
                title="设置密码"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleInitPwdDrawerClose}
                visible={this.state.initPwdDrawerVisible}
              >
                <InitPwd onCompleteInitPassword={this.handleCompleteInitPassword} />
              </Drawer>
            }
            {
              this.state.setPwdDrawerVisible &&
              <Drawer
                title="修改密码"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleSetPwdDrawerClose}
                visible={this.state.setPwdDrawerVisible}
              >
                <SetPwd onCompleteSetPassword={this.handleCompleteSetPassword} />
              </Drawer>
            }
            {
              this.state.setEmailDrawerVisible &&
              <Drawer
                title="变更邮箱"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleSetEmailDrawerClose}
                visible={this.state.setEmailDrawerVisible}
              >
                <SetEmail onCompleteSetEmail={this.handleCompleteSetEmail}  />
              </Drawer>
            }
            {
              this.state.chargeDrawerVisible &&
              <Drawer
                title="余额充值"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleChargeDrawerClose}
                visible={this.state.chargeDrawerVisible}
              >
                <Charge />
              </Drawer>
            }
            {
              this.state.closeAccountDrawerVisible &&
              <Drawer
                title="注销账户"
                placement="right"
                closable={false}
                width = {400}
                onClose={this.handleCloseAccountDrawerClose}
                visible={this.state.closeAccountDrawerVisible}
              >
                <CloseAccount />
              </Drawer>
            }
          </div>
        </div>
      </Layout.Header>
    )
  }
}

Header = connect((state) => {
  return {
    me: state.auth.me
  }
})(Header)

export default Header
