import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Row, Col, Table, Button, Checkbox, Badge, Drawer } from 'antd'
import { getMessages, getMessageDetail, setMessagesBeViewed, removeMessages } from '../../redux/message'
import MessageDetail from '../../components/MessageDetail'
import { getStatistics } from '../../redux/statistics'

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      offset: 0,
      limit: 10,
      messageSelectedKeys: [],
      messageSelectedAllChecked: false,
      messageDetailDrawerVisible: false
    }

    this.searchMessages()
    this.props.dispatch(getStatistics())
  }

  searchMessages = (state) => {
    const {offset, limit} = Object.assign({}, this.state, state)
    this.props.dispatch(getMessages({offset, limit})).then(() => {
      this.setState({
        messageSelectedKeys: [],
        messageSelectedAllChecked: false
      })
    })
  }

  get tableColumns () {
    return [{
      title: <span style={{marginLeft: '14px'}}>标题内容</span>,
      dataIndex: 'title',
      render: (text, record) => {
        return (
          <a href="/" onClick={this.handleClickMessageDetail.bind(this, record.id)}>
            {record.beViewed ? 
              <span style={{marginLeft: '14px', height: '19px', lineHeight: '19px', color: 'gray'}}>{text}</span> :
              <><Badge status="processing"/><span style={{height: '19px', lineHeight: '19px', color: 'black'}}>{text}</span></>
            }
          </a>
        )
      }
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.messages.rows.map((item, index) => {
      item.key = item.id
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      offset,
      limit
    })
    this.searchMessages({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const limit = size
    this.setState({
      limit
    })
    this.searchMessages({
      limit
    })

    // TODO 目前如果size从大改为小，页面滚动条不会自动滚上去
  }

  handleMessageSelected = (selectedRowKeys) => {
    this.setState({
      messageSelectedKeys: [...selectedRowKeys]
    })
  }

  handleSelectedAllClick = (e) => {
    if (e.target.checked) {
      this.setState({
        messageSelectedKeys: this.tableData.map(item => item.id),
        messageSelectedAllChecked: true
      })
    } else {
      this.setState({
        messageSelectedKeys: [],
        messageSelectedAllChecked: false
      })
    }
  }

  handleClickMessageDetail = (messageId, e) => {
    e.preventDefault()
    this.props.dispatch(getMessageDetail({messageId}))
    this.setState({
      messageDetailDrawerVisible: true
    })
    this.props.dispatch(setMessagesBeViewed({messageIds: [messageId]})).then(() => {
      this.searchMessages()
    })
  }

  handleMessageDetailDrawerClose = () => {
    this.setState({
      messageDetailDrawerVisible: false
    })
  }
  
  handleDeleteMessages = () => {
    const {messageSelectedKeys} = this.state
    const messageIds = messageSelectedKeys.map(item => parseInt(item))
    this.props.dispatch(removeMessages({messageIds})).then(() => {
      this.searchMessages()
    })
  }

  handleViewdMessages = () => {
    const {messageSelectedKeys} = this.state
    const messageIds = messageSelectedKeys.map(item => parseInt(item))
    this.props.dispatch(setMessagesBeViewed({messageIds})).then(() => {
      this.searchMessages()
    })
  }

  handleDeleteAllMessages = () => {
    const messageIds = this.props.messages.rows.map(item => item.id)
    this.props.dispatch(removeMessages({messageIds})).then(() => {
      this.searchMessages()
    })
  }

  handleViewedAllMessages = () => {
    const messageIds = this.props.messages.rows.map(item => item.id)
    this.props.dispatch(setMessagesBeViewed({messageIds})).then(() => {
      this.searchMessages()
    })
  }

  render() {
    const {messageSelectedKeys, messageSelectedAllChecked, messageDetailDrawerVisible} = this.state
    const {messageDetail, statistics} = this.props
    return (
      <div className='content dashboard'>
        <Row gutter={8} type='flex' justify='space-between'>
          <Col span={9} style={{paddingLeft: '0'}}>
            <div className='statistics-container'>
              <Row type='flex' align='middle' justify='center' className='statistics-header'>
                <Col>证书</Col>
              </Row>
              <Row type='flex' align='middle' justify='center' className='statistics-body'>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>总数量</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value'>{statistics ? statistics.certificate.totalCount : 0}</span></Col>
                  </Row>
                </Col>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>生效中</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value statistics-success-item'>{statistics ? statistics.certificate.inEffectiveCount : 0}</span></Col>
                  </Row>
                </Col>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>即将到期</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value statistics-aleart-item'>{statistics ? statistics.certificate.willExpiredCount : 0}</span></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={9} style={{paddingRight: '0'}}>
            <div className='statistics-container'>
              <Row type='flex' align='middle' justify='center' className='statistics-header'>
                <Col>部署</Col>
              </Row>
              <Row type='flex' align='middle' justify='center' className='statistics-body'>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>部署总次数</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value'>{statistics ? statistics.certificatePush.totalCount : 0}</span></Col>
                  </Row>
                </Col>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>部署成功</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value statistics-success-item'>{statistics ? statistics.certificatePush.successCount : 0}</span></Col>
                  </Row>
                </Col>
                <Col span={8} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>部署失败</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value statistics-aleart-item'>{statistics ? statistics.certificatePush.errorCount : 0}</span></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={3} style={{paddingRight: '0'}}>
            <div className='statistics-container'>
              <Row type='flex' align='middle' justify='center' className='statistics-header'>
                <Col>DNS授权</Col>
              </Row>
              <Row type='flex' align='middle' justify='center' className='statistics-body'>
                <Col span={24} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>数量</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value'>{statistics ? statistics.dnsServer.totalCount : 0}</span></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={3} style={{paddingRight: '0'}}>
            <div className='statistics-container'>
              <Row type='flex' align='middle' justify='center' className='statistics-header'>
                <Col>部署节点</Col>
              </Row>
              <Row type='flex' align='middle' justify='center' className='statistics-body'>
                <Col span={24} className='statistics-item right-border'>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-title'>数量</span></Col>
                  </Row>
                  <Row type='flex' align='middle' justify='center'>
                    <Col><span className='statistic-item-value'>{statistics ? statistics.cloudServer.totalCount : 0}</span></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div>
          <Row type='flex' alin='middle' justify='center' className='statistics-header'>
            <Col>站内信</Col>
          </Row>
          <Row type='flex' alin='middle' justify='center' className='statistics-body'>
            <Col span={24}>
              <Table
                columns={this.tableColumns}
                dataSource={this.tableData}
                size='small'
                pagination={{
                  pageSize: this.state.limit,
                  total: this.props.messages.count,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  showSizeChanger: true,
                  showTotal: total => `共${total}条`,
                  onChange: this.handleChangePage,
                  onShowSizeChange: this.handleChangePageSize
                }}
                rowSelection={{
                  selectedRowKeys: messageSelectedKeys,
                  onChange: this.handleMessageSelected
                }}
                footer={() => 
                  <>
                    <Checkbox style={{marginLeft: '7px', marginRight: '16px'}} checked={messageSelectedAllChecked} onChange={this.handleSelectedAllClick}/>
                    <Button style={{marginRight: '5px'}} onClick={this.handleDeleteMessages}>删除</Button>
                    <Button style={{marginRight: '5px'}} onClick={this.handleViewdMessages}>标记已读</Button>
                    <Button style={{marginRight: '5px'}} onClick={this.handleViewedAllMessages}>全部已读</Button>
                    <Button style={{marginRight: '5px'}} onClick={this.handleDeleteAllMessages}>全部删除</Button>
                  </>
                }
              />
            </Col>
          </Row>
        </div>
        <Drawer
            title='站内信详情'
            placement='right'
            closable={false}
            width = {600}
            onClose={this.handleMessageDetailDrawerClose}
            visible={messageDetailDrawerVisible}
          >
            {messageDetail ? <MessageDetail messageDetail={messageDetail}/> : ''}
        </Drawer>
      </div>
    )
  }
}

Dashboard = connect((state) => {
  return {
    me: state.auth.me,
    statistics: state.statistics.statistics,
    messages: state.message.messages,
    messageDetail: state.message.messageDetail
  }
})(Dashboard)

export default Dashboard
