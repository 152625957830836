const GET_CERTIFICATE_PUSHES_ING = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHES_ING'
const GET_CERTIFICATE_PUSHES_SUCCESS = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHES_SUCCESS'
const GET_CERTIFICATE_PUSHES_FAILURE = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHES_FAILURE'

const GET_CERTIFICATE_PUSHE_DETAIL_ING = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHE_DETAIL_ING'
const GET_CERTIFICATE_PUSHE_DETAIL_SUCCESS = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHE_DETAIL_SUCCESS'
const GET_CERTIFICATE_PUSHE_DETAIL_FAILURE = 'CERTIFICATE/PUSH/GET_CERTIFICATE_PUSHE_DETAIL_FAILURE'

const GET_ALL_CERTIFICATE_PUSHES_ING = 'CERTIFICATE/PUSH/GET_ALL_CERTIFICATE_PUSHES_ING'
const GET_ALL_CERTIFICATE_PUSHES_SUCCESS = 'CERTIFICATE/PUSH/GET_ALL_CERTIFICATE_PUSHES_SUCCESS'
const GET_ALL_CERTIFICATE_PUSHES_FAILURE = 'CERTIFICATE/PUSH/GET_ALL_CERTIFICATE_PUSHES_FAILURE'

const REPUSH_CERTIFICATE_ING = 'CERTIFICATE/PUSH/REPUSH_CERTIFICATE_ING'
const REPUSH_CERTIFICATE_SUCCESS = 'CERTIFICATE/PUSH/REPUSH_CERTIFICATE_SUCCESS'
const REPUSH_CERTIFICATE_FAILURE = 'CERTIFICATE/PUSH/REPUSH_CERTIFICATE_FAILURE'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CERTIFICATE_PUSHES_SUCCESS:
      return {
        ...state,
        [`pushes_${action.certificateId}`]: action.payload
      }
    case GET_CERTIFICATE_PUSHES_FAILURE:
      return {
        ...state,
        [`pushes_${action.certificateId}`]: {
          count: 0,
          rows: []
        }
      }
    case GET_ALL_CERTIFICATE_PUSHES_SUCCESS:
      return {
        ...state,
        pushes: action.payload
      }
    case GET_ALL_CERTIFICATE_PUSHES_FAILURE:
      return {
        ...state,
        pushes: {
          count: 0,
          rows: []
        }
      }
    default:
      return { ...state }
  }
}

export const getCertificatePusheDetail = ({certificateId, certificatePushId}) => {
  return {
    types: [GET_CERTIFICATE_PUSHE_DETAIL_ING, GET_CERTIFICATE_PUSHE_DETAIL_SUCCESS, GET_CERTIFICATE_PUSHE_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/${certificateId}/pushes/${certificatePushId}`)
  }
}

export const getCertificatePushes = ({certificateId, offset, limit}) => {
  return {
    types: [GET_CERTIFICATE_PUSHES_ING, GET_CERTIFICATE_PUSHES_SUCCESS, GET_CERTIFICATE_PUSHES_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/${certificateId}/pushes`, {params: {offset, limit}}),
    certificateId
  }
}

export const getAllCertificatePushes = ({searchContent, offset, limit}) => {
  return {
    types: [GET_ALL_CERTIFICATE_PUSHES_ING, GET_ALL_CERTIFICATE_PUSHES_SUCCESS, GET_ALL_CERTIFICATE_PUSHES_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/pushes`, {params: {searchContent, offset, limit}})
  }
}

export const repushCertificate = ({certificateId, cloudServerIds}) => {
  const data = {cloudServerIds}
  return {
    types: [REPUSH_CERTIFICATE_ING, REPUSH_CERTIFICATE_SUCCESS, REPUSH_CERTIFICATE_FAILURE],
    promise: (apiClient) => apiClient.post(`/api/certificates/${certificateId}/pushes`, data)
  }
}
