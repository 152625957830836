import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Input, Tooltip, Modal } from 'antd'
import CertificateType from './CertificateType'
import CertificateAlias from './CertificateAlias'
import CertificateDomains from './CertificateDomains'
import { CERTIFICATE_BRAND, CERTIFICATE_PUSH_STATUS, CLOUD_ID, CLOUD_PUSH_POSITION } from '../utils/constant'
import { getAllCertificatePushes } from '../redux/certificate/push'
import SSHPushDetail from './SSHPushDetail'

import sectigoImg from '../assets/svg/sectigo.svg'
import letsenryptImg from '../assets/svg/letsencrypt.svg'

class MyCertificatesPush extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      activeCertificateId: null,
      editCertificateConfigDrawerVisible: false
    }
    this.searchCertificatePushes()
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  refreshUrl = ({searchContent, offset, limit}) => {
    const curUrl = new URL(window.location.href)
    curUrl.searchParams.set('search', searchContent)
    curUrl.searchParams.set('offset', offset)
    curUrl.searchParams.set('limit', limit)
    this.props.history.replace(curUrl.pathname + curUrl.search)
  }

  searchCertificatePushes = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit})

    this.props.dispatch(getAllCertificatePushes({searchContent, offset, limit})).then((response) => {
      this.setState({
        firstLoading: false
      })
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.certificatePushStatus === CERTIFICATE_PUSH_STATUS.PUSHING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchCertificatePushes()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  handelCloseCertificatePushDetailModal = () => {
    this.setState({
      certificatePushDetailModalVisible: false
    })
  }

  handelOpenCertificatePushDetailModal = (certificateId, certificatePushId) => {
    this.setState({
      certificateId,
      certificatePushId,
      certificatePushDetailModalVisible: true
    })
  }

  get tableColumns () {
    const columns = [{
      title: '证书ID/名称',
      dataIndex: 'certificateId',
      render: (text, record) => {
        return (
          <>
            <Button onClick={() => this.props.history.push(`/monitor/certificates/${record.certificateId}`)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.certificateName}</Button>
            <br/>
            <CertificateAlias key={record.certificateId} certificateId={record.certificateId} certificateAlias={record.certificateAlias || record.certificateName} onChangeComplete={this.searchCertificatePushes}/>
          </>
        )
      }
    }, {
      title: '证书类型',
      dataIndex: 'certificateType',
      sorter: true,
      render: (text, record) => {
        return <CertificateType type={record.certificateType}/>
      }
    }, {
      title: '关联域名',
      dataIndex: 'certificateDomains',
      render: (text, record) => {
        return <CertificateDomains domains={record.certificateDomains} onlyFirst={true} maxWidth={235}/>
      }
    }, {
      title: '证书品牌',
      dataIndex: 'certificateBrand',
      sorter: true,
      render: (certificateBrand, record) => {
        if (certificateBrand === CERTIFICATE_BRAND.SECTIGO) {
          return <img height={10} src={sectigoImg} alt="Sectigo" />
        } else {
          return <img height={22} style={{ marginLeft: '-2px', marginTop: '-9px' }} src={letsenryptImg} alt="Let's Encrypt" />
        }
      }
    }, {
      title: '任务创建时间',
      dataIndex: 'certificatePushCreateTime',
      key: 'certificatePushCreateTime',
      width: 142,
      render: certificatePushCreateTime => moment(certificatePushCreateTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '任务创建方式',
      dataIndex: 'certificatePushIsAuto',
      key: 'certificatePushIsAuto',
      render: (text, record) => record.certificatePushIsAuto ? '系统自动创建' : '用户手动创建',
      width: 98
    }, {
      title: '部署节点',
      dataIndex: 'certificatePushCloudServerId',
      key: 'certificatePushCloudServerId',
      render: (text, record) => {
        const cloud = `${record.certificatePushCloudProviderName} - ${record.certificatePushCloudPushPosition} - ${record.certificatePushCloudServerAlias || record.certificatePushCloudServerName}`
        return (
          <Tooltip title={cloud}>
            <Button onClick={() => this.props.history.push(`/monitor/cloudservers/${record.certificatePushCloudServerId}`)} type='link' style={{paddingLeft: 0, height: 18}}>
              <img src={record.certificatePushCloudProviderLogo} alt='' width={15} height={15}/>&nbsp;
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
                  {cloud}
                </span>
            </Button>
          </Tooltip>
        )
      }
    }, {
      title: '任务状态',
      dataIndex: 'certificatePushStatus',
      key: 'certificatePushStatus',
      width: 80,
      render: (text, record) => {
        if (record.certificatePushStatus === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return <span style={{color: 'orange'}}>部署中</span>
        } else if (record.certificatePushStatus === CERTIFICATE_PUSH_STATUS.PUSHED) {
          return <span style={{color: 'green'}}>已成功</span>
        } else if (record.certificatePushStatus === CERTIFICATE_PUSH_STATUS.ERROR) {
          return <span style={{color: 'red'}}>已失败</span>
        }
      }
    }, {
      title: '任务失败信息',
      dataIndex: 'certificatePushError',
      key: 'certificatePushError',
      ellipsis: true,
      render: certificatePushError => {
        const error = certificatePushError || '-'
        return (
          <Tooltip title={error}>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
              {error}
            </div>
          </Tooltip>
        )
      }
    }, {
      title: '任务备注',
      dataIndex: 'certificatePushExtra',
      key: 'certificatePushExtra',
      ellipsis: true,
      render: (text, record) => {
        if (record.certificatePushStatus === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return '-'
        }

        if (record.certificatePushCloudPushPosition === CLOUD_PUSH_POSITION.SSH) {
          return <Button type='link' style={{padding: '0px'}} onClick={this.handelOpenCertificatePushDetailModal.bind(this, record.certificateId, record.certificatePushId)} >点击查看执行详情</Button>
        }

        if (!record.certificatePushExtra) {
          return '-'
        }

        switch(record.certificatePushCloudId) {
          case CLOUD_ID.DOCKER:
            const extraInJSON = JSON.parse(record.certificatePushExtra)
            if (extraInJSON.failureNodesCount) {
              if (extraInJSON.successNodesCount) {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span>，<span style={{color: 'red'}}>更新失败{extraInJSON.failureNodesCount}个</span></span>
              } else {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'red'}}>部署失败{extraInJSON.failureNodesCount}个</span></span>
              }
            } else {
              return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span></span>
            }
          default:
            return record.certificatePushExtra
        }
      }
    }, {
      title: '', // 此列用于修复超长字段影响表格布局的bug, https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
      fixed: 'right',
      width: 1
    }]

    return columns
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.certificatePushes.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0
    })
    this.searchCertificatePushes({offset: 0})
  }

  handleChangeTable = (pagination) => {
    const {current, pageSize} = pagination
    const offset = pageSize * (current - 1)
    const limit = pageSize
    this.setState({
      current,
      offset,
      limit
    })
    this.searchCertificatePushes({offset, limit})
  }

  renderCertificatePushDetailModal = () => {
    const {certificateId, certificatePushId, certificatePushDetailModalVisible} = this.state
    if (!certificatePushDetailModalVisible) {
      return
    }

    return (
      <Modal
        title={'证书部署执行详情'}
        visible={true}
        width={800}
        okType='primary'
        okButtonProps={{ style: { marginRight: '5px' } }}
        onOk={this.handelCloseCertificatePushDetailModal}
        onCancel={this.handelCloseCertificatePushDetailModal}
        footer={[
          <Button type='primary' size='small' key='confirm' onClick={this.handelCloseCertificatePushDetailModal}>
            关闭
          </Button>
        ]}
      >
        <div>
          <SSHPushDetail 
            certificateId={certificateId}
            certificatePushId={certificatePushId}
          />
        </div>
      </Modal>
    )
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入关联域名或证书ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          onChange={this.handleChangeTable}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.certificatePushes.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`
          }}
        />
        {this.renderCertificatePushDetailModal()}
      </div>
    )
  }
}

MyCertificatesPush = connect((state) => {
  return {
    certificatePushes: state.certificate.push.pushes || {count: 0, rows: []}
  }
})(MyCertificatesPush)

export default MyCertificatesPush
