import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button, Radio, message} from 'antd'
import { getCloudServerDetail, changeCloudServerAccessJson } from '../redux/cloud/server'
import { getCloudProviders } from '../redux/cloud/provider'
import ConfigField from './ConfigField'
import { checkAccessJson } from '../utils'

class UpdateCloudServer extends React.Component {
  static props = {
    cloudServerId: PropTypes.number,
    onUpdateComplete: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      cloudServerAccessJson: null,
      loading: false,
    }

    this.props.dispatch(getCloudProviders())
    this.props.dispatch(getCloudServerDetail({cloudServerId: props.cloudServerId})).then((result) => {
      this.setState({
        cloudServerAccessJson: result.payload.accessJson
      })
    })
  }

  handleEditCloudServer = (field, value) => {
    const accessJson = { ...this.state.cloudServerAccessJson }
    accessJson[field] = value
    this.setState({
      cloudServerAccessJson: accessJson,
      cloudServerVerified: false
    })
  }

  handleClickUpdate = () => {
    const {cloudServerAccessJson} = this.state
    const {onUpdateComplete, cloudServerDetail, cloudProviders} = this.props
    const { cloudId, cloudProviderId } = cloudServerDetail
    const cloudProvider = cloudProviders.find(item => item.id === cloudId).providers.find(item => item.id === cloudProviderId)
    const {configJson} = cloudProvider

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: cloudServerAccessJson})
    if (error) {
      return message.error(error)
    }

    this.setState({
      loading: true
    })
    message.warn('正在校验部署节点参数配置是否正确，请耐心等待')
    this.props.dispatch(changeCloudServerAccessJson({cloudServerId: cloudServerDetail.id, accessJson: cloudServerAccessJson}))
      .then(response => {
        this.setState({
          loading: false
        })

        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('部署节点修改成功')

        if (onUpdateComplete) {
          onUpdateComplete()
        }
      })
  }

  render() {
    const { cloudProviders } = this.props
    if (!cloudProviders.length) {
      return <></>
    }

    const {cloudServerDetail} = this.props
    if (!cloudServerDetail) {
      return <></>
    }

    const { cloudServerAccessJson, loading } = this.state
    const { cloudId, cloudProviderId } = cloudServerDetail

    const cloudProvider = cloudProviders.find(item => item.id === cloudId).providers.find(item => item.id === cloudProviderId)
    const { fields } = cloudProvider.configJson

    const serverAccessJson = Object.assign(cloudProvider.configJson.default || {}, cloudServerAccessJson)

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group buttonStyle='solid'>
            <Radio.Button>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={cloudServerDetail.cloudProviderLogo} alt='' width={15} height={15} />&nbsp;{cloudServerDetail.cloudProviderName}
              </div>
            </Radio.Button>
          </Radio.Group>
          <br/>
          <Radio.Group buttonStyle='solid' style={{marginTop: '10px'}}>
            <Radio.Button>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {cloudServerDetail.cloudPushPosition}
              </div>
            </Radio.Button>
          </Radio.Group>
          <div>
            {fields.map((item, index) => {
              const value = (serverAccessJson || {})[item.field]
              if (item.showWhen) {
                // $变量会在eval中使用
                // eslint-disable-next-line no-unused-vars
                const $ = serverAccessJson
                // eslint-disable-next-line no-eval
                const visible = eval(item.showWhen)
                if (!visible) {
                  return null
                }
              }
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditCloudServer} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='end' gutter={16} style={{ marginTop: '10px' }} >
            <Col span={18} />
            <Col span={6}>
              <Button loading={loading} onClick={this.handleClickUpdate} type='primary' block>{loading ? '参数校验中...' : '确认修改'}</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

UpdateCloudServer = connect((state) => {
  return  {
    cloudProviders: state.cloud.provider.cloudProviders,
    cloudServerDetail: state.cloud.server.cloudServerDetail
  }
})(UpdateCloudServer)

export default UpdateCloudServer
