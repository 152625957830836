import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, message } from 'antd'
import { signin } from '../../redux/auth'
import { reportSigninClick, reportSigninExp, reportSigninSuccess } from '../../utils/report'
import OAuth from '../../components/OAuth'

class Signin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  componentDidMount() {
    reportSigninExp()
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.handleClickSignin()
        }
      }
    }
  }

  componentWillUnmount() {
    if (window) {
      window.onkeypress = null
    }
  }

  handleClickSignin = () => {
    const { email, password } = this.state

    if (!email || !email.trim()) {
      return message.error('请输入您的邮箱')
    }

    if (!password || !password.trim()) {
      return message.error('请输入您的密码')
    }

    this.props.dispatch(signin({ email, password }))
      .then((result) => {
        if (result.errorMsg) {
          return message.error(result.errorMsg)
        } else {
          this.props.history.replace('/monitor/dashboard')
        }
        reportSigninSuccess()
      }).catch((error) => {
        return message.error(`未知异常:${error}`)
      })

    reportSigninClick()
  }

  handleChangeEmail = (e) => {
    const email = e.target.value
    this.setState({
      email
    })
  }

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleClickForget = () => {
    this.props.history.push('/forget')
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  handlerClickSignup = () => {
    this.props.history.push('/signup')
  }

  render() {
    return (
      <div className="area signin">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Form.Item>
              <Input
                placeholder='请输入您的邮箱'
                onChange={this.handleChangeEmail}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                placeholder='请输入您的密码'
                visibilityToggle={false}
                onChange={this.handleChangePassword}
              />
            </Form.Item>
            <Button type='primary' block onClick={this.handleClickSignin}>登录</Button>
            <OAuth/>
            <div style={{display: 'flex'}}>
              <Button type='link' onClick={this.handlerClickSignup}>没有账号？点击注册</Button>
              <Button type='link' onClick={this.handleClickForget}>忘记密码？点击重置</Button>
            </div>
          </Form>
        </div>
      </div >
    )
  }
}

Signin = connect()(Signin)

export default Signin
