import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Transfer, message, Button, Row, Col, Divider, Icon} from 'antd'
import { changeNotificationConfig } from '../redux/notification/config'
import { getNotificationTargets } from '../redux/notification/target'
import { SYSTEM_EMAIL_NOTIFICATION_TARGET } from '../utils/constant'

class EditUserNotificationConfig extends React.Component {
  static props = {
    userNotificationConfig: PropTypes.object,
    onEditComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.searchNotificationTargets()

    const { notificationTargets, systemEmailEnabled } = props.userNotificationConfig
    const notificationTargetIds = notificationTargets.map(item => item.id)
    if (systemEmailEnabled) {
      notificationTargetIds.push(SYSTEM_EMAIL_NOTIFICATION_TARGET.ID)
    }

    this.state = {
      listData: [],
      notificationTargetIds 
    }
  }

  searchNotificationTargets = () => {
    this.props.dispatch(getNotificationTargets({offset: 0, limit: 1000000})).then(() => {
      this.refreshListData()
    })
  }

  refreshListData () {
    const {notificationTargets} = this.props

    const listData = notificationTargets.rows.map((item) => {
      return {
        key: item.id,
        name: item.name,
        alias: item.alias,
        notificationProviderName: item.notificationProviderName,
        notificationProviderLogo: item.notificationProviderLogo
      }
    })

    listData.unshift({
      key: SYSTEM_EMAIL_NOTIFICATION_TARGET.ID,
      name: SYSTEM_EMAIL_NOTIFICATION_TARGET.NAME,
      notificationProviderName: SYSTEM_EMAIL_NOTIFICATION_TARGET.NOTIFICATION_PROVIDER_NAME
    })

    this.setState({
      listData
    })
  }

  handleChangeSelected = (notificationTargetIds) => {
    this.setState({
      notificationTargetIds
    })
  }

  handleClickConfirm = () => {
    let {userNotificationConfig: {notificationTypeId, notificationEnabled, systemEmailEnabled},  onEditComplete} = this.props
    let {notificationTargetIds} = this.state

    if (notificationTargetIds.includes(SYSTEM_EMAIL_NOTIFICATION_TARGET.ID)) {
      systemEmailEnabled = true
      notificationTargetIds = notificationTargetIds.filter(item => item !== SYSTEM_EMAIL_NOTIFICATION_TARGET.ID)
    }

    this.props.dispatch(changeNotificationConfig({
      notificationTypeId, 
      notificationEnabled,
      notificationTargetIds,
      systemEmailEnabled
    })).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      message.success('配置修改成功')

      if (onEditComplete) {
        onEditComplete()
      }
    })
  }

  render () {
    const {listData, notificationTargetIds} = this.state
    const {userNotificationConfig} = this.props

    return (
      <> 
        <Row style={{marginTop: '20px'}}>
          <Col span={24} style={{display: 'flex', alignItems: 'center'}}>
            <span>通知类型：{userNotificationConfig.notificationTypeName}</span>
          </Col>
        </Row>
        <Divider>通知方式配置</Divider>
        <Transfer
          dataSource={listData}
          titles={['所有通知方式', '已选中通知方式']}
          listStyle={{
            width: 338,
            height: 400,
          }}
          operations={['添加', '去除']}
          targetKeys={notificationTargetIds}
          onChange={this.handleChangeSelected}
          render={item => {
            if (item.key === SYSTEM_EMAIL_NOTIFICATION_TARGET.ID) {
              return (
                <>
                  <Icon type="mail" theme="filled" style={{color: '#0052d9', width: 15, height: 15}}/>&nbsp;{SYSTEM_EMAIL_NOTIFICATION_TARGET.NOTIFICATION_PROVIDER_NAME}&nbsp;-&nbsp;{SYSTEM_EMAIL_NOTIFICATION_TARGET.NAME}
                </>
              )
            }
            return (
              <>
                <img src={item.notificationProviderLogo} alt='' width={15} height={15}/>&nbsp;{item.notificationProviderName}&nbsp;-&nbsp;{item.alias || item.name}
              </>
            )
          }}
        />
        <Divider />
        <Row type='flex' justify='end' gutter={16} style={{marginTop: '20px'}}>
          <Col span={18} />
          <Col span={6}>
            <Button onClick={this.handleClickConfirm} type='primary' block>确认修改</Button>
          </Col>
        </Row>
      </>
    )
  }
}

EditUserNotificationConfig = connect((state) => {
  return {
    notificationTargets: state.notification.target.notificationTargets
  }
})(EditUserNotificationConfig)

export default EditUserNotificationConfig
