const GET_STATISTICS_ING = 'STATISTICS/GET_STATISTICS_ING'
const GET_STATISTICS_SUCCESS = 'STATISTICS/GET_STATISTICS_SUCCESS'
const GET_STATISTICS_FAILURE = 'STATISTICS/GET_STATISTICS_FAILURE'

const defaultState = {
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload
      }
    case GET_STATISTICS_FAILURE:
      return {
        ...state,
        statistics: null
      }
    default:
      return {...state}
  }
}

export const getStatistics = () => {
  return {
    types: [GET_STATISTICS_ING, GET_STATISTICS_SUCCESS, GET_STATISTICS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/statistics')
  }
}
