import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Button, message, Modal } from 'antd'
import JustifyLabel from './JustifyLabel'
import { setEmail, signout } from '../redux/auth'
import { sendEmail } from '../redux/common'
import { EMAIL_TEMPLATE } from '../utils/constant'

class SetEmail extends React.Component {
  static props = {
    history: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = this.getInitState()
  }

  getInitState = () => {
    return {
      newEmail: '',
      oldEmailSended: false,
      newEmailSended: false,
      oldEmailCanSendAfterSeconds: 60,
      newEmailCanSendAfterSeconds: 60,
      oldEmailVerificationCode: '',
      newEmailVerificationCode: '',
    }
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.newEmailTimer) {
      clearInterval(this.newEmailTimer)
      this.newEmailTimer = null
    }
    if (this.oldEmailTimer) {
      clearInterval(this.oldEmailTimer)
      this.oldEmailTimer = null
    }
  }

  handleChangeNewEmail = (e) => {
    this.setState({
      newEmail: e.target.value
    })
  }

  handleChangeOldEmailVerificationCode = (e) => {
    this.setState({
      oldEmailVerificationCode: e.target.value
    })
  }

  handleChangeNewEmailVerificationCode = (e) => {
    this.setState({
      newEmailVerificationCode: e.target.value
    })
  }

  handleSendNewEmailVerification = () => {
    const { newEmail, newEmailSended, newEmailCanSendAfterSeconds } = this.state
    if (!newEmail || !newEmail.trim()) {
      return message.error('请输入您的新邮箱')
    }

    if (newEmailSended && newEmailCanSendAfterSeconds >= 1) {
      return message.error('发送频率过高，请稍后重试')
    }

    this.props.dispatch(sendEmail({ email: newEmail, templateId: EMAIL_TEMPLATE.VERIFICATION_CODE })).then(result => {
      if (this.isUnmounted) {
        return
      }

      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.setState({
        newEmailCanSendAfterSeconds: 60,
        newEmailSended: true
      })

      this.newEmailTimer = setInterval(() => {
        const { newEmailCanSendAfterSeconds } = this.state

        this.setState({
          newEmailCanSendAfterSeconds: newEmailCanSendAfterSeconds - 1
        })

        if (newEmailCanSendAfterSeconds <= 1) {
          clearInterval(this.newEmailTimer)
        }
      }, 1000)

      message.success('验证码已成功发送至您的新邮箱，请至您的新邮箱查收验证码')
    })
  }

  handleSendOldEmailVerification = () => {
    const { oldEmailSended, oldEmailCanSendAfterSeconds } = this.state
    const oldEmail = this.props.me.email
    if (oldEmailSended && oldEmailCanSendAfterSeconds >= 1) {
      return message.error('发送频率过高，请稍后重试')
    }

    this.props.dispatch(sendEmail({ email: oldEmail, templateId: EMAIL_TEMPLATE.VERIFICATION_CODE })).then(result => {
      if (this.isUnmounted) {
        return
      }

      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.setState({
        oldEmailCanSendAfterSeconds: 60,
        oldEmailSended: true
      })

      this.oldEmailTimer = setInterval(() => {
        const { oldEmailCanSendAfterSeconds } = this.state

        this.setState({
          oldEmailCanSendAfterSeconds: oldEmailCanSendAfterSeconds - 1
        })

        if (oldEmailCanSendAfterSeconds <= 1) {
          clearInterval(this.oldEmailTimer)
        }
      }, 1000)

      message.success('验证码已成功发送至您的原邮箱，请至您的原邮箱查收验证码')
    })
  }

  handleClickSetEmail = () => {
    const {newEmail, newEmailVerificationCode, oldEmailVerificationCode} = this.state
    const oldEmail = this.props.me.email
    if (!newEmail) {
      return message.error('请输入新邮箱')
    }
    if (newEmail === oldEmail) {
      return message.error('新邮箱与原邮箱相同，无需变更')
    }
    if (!oldEmailVerificationCode) {
      return message.error('请输入原邮箱的解绑验证码')
    }
    if (!newEmailVerificationCode) {
      return message.error('请输入新邮箱的绑定验证码')
    }

    Modal.confirm({
      title: '确认要变更邮箱吗?',
      content: '变更邮箱后当前登录的邮箱将会失效，下次登录需要使用新邮箱进行登录',
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(setEmail({newEmail, oldEmailVerificationCode, newEmailVerificationCode}))
        .then((result) => {
          if (result.errorMsg) {
            return message.error(result.errorMsg)
          } else {
            Modal.success({
              content: '邮箱变更成功，请使用新邮箱重新登录',
              okText: '前往登录页重新登录',
              onOk: () => {
                this.props.dispatch(signout())

                if (this.props.history) {
                  this.props.history.push('/signin')
                } else {
                  window.location.href = '/signin'
                }

                setTimeout(() => {
                  localStorage.removeItem('TOKEN')
                })
              }
            })
          }
        }).catch((error) => {
          return message.error(`未知异常:${error}`)
        })
      },
      onCancel: () => {
      },
    })
  }

  render () {
    return (
      <>
        <Form>
          <Form.Item>
            <Input
              addonBefore={<JustifyLabel content='新邮箱' width={70}/>}
              onChange={this.handleChangeNewEmail}
            />
          </Form.Item>
          <Form.Item>
            <Input.Search
              placeholder='请输入原邮箱验证码'
              maxLength={6}
              enterButton={this.state.oldEmailSended ? this.state.oldEmailCanSendAfterSeconds >= 1 ? `${this.state.oldEmailCanSendAfterSeconds}秒后可重新发送` : '重新发送解绑验证码至原邮箱' : '发送解绑验证码至原邮箱'}
              onChange={this.handleChangeOldEmailVerificationCode}
              onSearch={this.handleSendOldEmailVerification}
            />
          </Form.Item>
          <Form.Item>
            <Input.Search
              placeholder='请输入新邮箱验证码'
              maxLength={6}
              enterButton={this.state.newEmailSended ? this.state.newEmailCanSendAfterSeconds >= 1 ? `${this.state.newEmailCanSendAfterSeconds}秒后可重新发送` : '重新发送绑定验证码至新邮箱' : '发送绑定验证码至新邮箱'}
              onChange={this.handleChangeNewEmailVerificationCode}
              onSearch={this.handleSendNewEmailVerification}
            />
          </Form.Item>
          <Button type='primary' block onClick={this.handleClickSetEmail}>变更邮箱</Button>
        </Form>
      </>
    )
  }
}

SetEmail = connect((state) => {
  return {
    me: state.auth.me
  }
})(SetEmail)

export default SetEmail
