import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditLabel from './EditLabel'
import {changeWatchAlias} from '../redux/watch'

class WatchAlias extends React.Component {
  static props = {
    showIcon: PropTypes.bool,
    watchId: PropTypes.number,
    watchAlias: PropTypes.string,
    onChangeComplete: PropTypes.func
  }

  render() {
    const {showIcon, watchId, watchAlias, onChangeComplete} = this.props

    return (
      <EditLabel 
        title='编辑证书监控名称'
        minLength={2}
        maxLength={256}
        showIcon={showIcon}
        value={watchAlias}
        onChangeDispatch={changeWatchAlias.bind(this, watchId)}
        onChangeComplete={onChangeComplete}
      />
    )
  }
}

WatchAlias = connect()(WatchAlias)

export default WatchAlias
