import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Icon, Alert, Table, message } from 'antd'
import Clipboard from 'react-clipboard.js'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { getHttpProxyNginxConfig } from '../utils'
import { CERTIFICATE_BRAND } from '../utils/constant'

import "@uiw/react-textarea-code-editor/dist.css"

class HttpAuthModeServerProxySettings extends React.Component {
  static props = {
    domains: PropTypes.array,
    certificateBrand: PropTypes.string
  }

  handleCopyServerProxyPathSuccess = () => {
    message.success('HTTP验证请求路径已成功复制到剪贴板')
  }

  handleCopyServerProxyUrlSuccess = () => {
    message.success('代理验证地址已成功复制到剪贴板')
  }

  handleCopyServerProxyHeaderSuccess = () => {
    message.success('代理请求头已成功复制到剪贴板')
  }

  render () {
    const { domains, certificateBrand} = this.props
    const { openAcmeChallengeAliasCnameValue } = this.props.configs
    const { userCode } = this.props.me

    if (!openAcmeChallengeAliasCnameValue || !userCode) {
      return <></>
    }

    const proxyPath = certificateBrand === CERTIFICATE_BRAND.LETSENCRYPT ? '/.well-known/acme-challenge/' : '/.well-known/pki-validation/'
    const proxyHost = `https://${openAcmeChallengeAliasCnameValue.slice('_acme-challenge.'.length)}`

    const columnsHost = [
      {
        title: 'HTTP验证请求路径',
        dataIndex: 'acmeUrl',
        ellipsis: true,
        render: text => {
          if (!text) {
            return  <>&nbsp;</>
          } else {
            return (
              <div className='record-value'>
                {text}
                <span style={{height: '100%'}}>
                  <a href={text + `ohttps-${userCode}`} target="_blank" rel="noopener noreferrer">点击验证</a>&nbsp;
                  <Clipboard className='ant-btn' style={{width: 205}} data-clipboard-text={text} onSuccess={this.handleCopyServerProxyPathSuccess}>
                    <Icon type='copy'/>&nbsp;点击复制HTTP验证请求路径
                  </Clipboard>
                </span>
              </div>
            )
          }
        }
      }
    ]

    const columnsProxy = [
      {
        title: '代理验证地址',
        dataIndex: 'proxyUrl',
        render: text => {
          return (
            <div className='record-value'>
              {text}
              <Clipboard className='ant-btn' style={{width: 172}} data-clipboard-text={text} onSuccess={this.handleCopyServerProxyUrlSuccess}>
                <Icon type='copy'/>&nbsp;点击复制代理验证地址
              </Clipboard>
            </div>
          )
        }
      }
    ]

    const columnsHeaders = [
      {
        title: '代理请求头',
        dataIndex: 'proxyHeader',
        render: text => {
          return (
            <div className='record-value'>
              {text}
              <Clipboard className='ant-btn' style={{width: 158}} data-clipboard-text={text} onSuccess={this.handleCopyServerProxyHeaderSuccess}>
                <Icon type='copy'/>&nbsp;点击复制代理请求头
              </Clipboard>
            </div>
          )
        }
      }
    ]

    const rowsHost = domains.filter(item => item && item.trim().length).map(item => {
      item = item.trim()
      if (item.startsWith('*')) {
        item = item.replace('*.', '')
      }
      return {
        acmeUrl: `http://${item}${proxyPath}`
      }
    })
    if (rowsHost.length === 0) {
      rowsHost.push({
        acmeUrl: ''
      })
    }

    const proxyUrl = `${proxyHost}${proxyPath}`
    const rowsProxy = [{
      proxyUrl: proxyUrl
    }]

    const rowsHeader = [{
      proxyHeader: `x-ohttps-user: ${userCode}`
    }]

    return (
      <div>
        <div className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle'>
            <Col span={24}>
              <div>
                <Alert message={`请在服务端(CDN、Nginx、OpenResty、HTTPd等)配置中将以下${rowsHost.filter(item => item.acmeUrl).length}个域名的HTTP验证请求路径代理至OHTTPS的代理验证地址并设置相应的请求头，以实现证书申请及后续更新的自动化`} type="info" showIcon/>
                <Table
                  columns={columnsHost}
                  dataSource={rowsHost}
                  size='small'
                  pagination={false}
                  bordered
                  style={{marginTop: '10px'}}
                  className='record-type-value'
                />
                <p style={{marginTop: '10px'}}>1、请在服务端配置中将以上路径统一代理至以下地址：</p>
                <Table
                  columns={columnsProxy}
                  dataSource={rowsProxy}
                  size='small'
                  pagination={false}
                  showHeader={false}
                  bordered
                  style={{marginTop: '-2px'}}
                  className='record-type-value'
                />
                <p style={{marginTop: '10px'}}>2、请在服务端配置中将以上代理统一添加以下请求头：</p>
                <Table
                  columns={columnsHeaders}
                  dataSource={rowsHeader}
                  size='small'
                  pagination={false}
                  showHeader={false}
                  bordered
                  style={{marginTop: '-2px'}}
                  className='record-type-value'
                />
                <Alert message="请不要删除以上服务端代理配置，否则会导致相关证书生成失败或自动更新失败" type="info" showIcon style={{marginTop: '10px'}}/>
                <Alert message="可通过点击上方HTTP验证请求路径右侧的[点击验证]按钮手动检查服务端配置是否正确" type="info" showIcon style={{marginTop: '10px'}}/>
                <CodeEditor
                  value={getHttpProxyNginxConfig({domains, userCode, proxyPath, proxyHost})}
                  language={'nginx'}
                  readOnly={true}
                  padding={15}
                  style={{
                    marginTop: 10,
                    backgroundColor: "#f5f5f5"
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

HttpAuthModeServerProxySettings = connect((state) => {
  return {
    configs: state.common.configs,
    me: state.auth.me
  }
})(HttpAuthModeServerProxySettings)

export default HttpAuthModeServerProxySettings
