import { message } from 'antd'

message._success = message.success
message._error = message.error
message._info = message.info
message._warning = message.warning
message._warn = message.warn
message._loading = message.loading

const handler = {
  apply: function (target, thisArg, argumentsList) {
    let [content, duration, onClose] = argumentsList
    // 如果有传入值就用传入的，否则最短3秒，最长10秒，使用内容长度计算时长
    duration = duration || Math.min(Math.max(Math.floor((content || '').length / 10), 3), 10)
    return target(content, duration, onClose);
  }
}

message.success = new Proxy(message._success, handler)
message.error = new Proxy(message._error, handler)
message.info = new Proxy(message._info, handler)
message.warning = new Proxy(message._warning, handler)
message.warn = new Proxy(message._warn, handler)
message.loading = new Proxy(message._loading, handler)
