import React from 'react'
import PropTypes from 'prop-types'

class JustifyLabel extends React.Component {
  static props = {
    width: PropTypes.number,
    content: PropTypes.string
  }

  render () {
    return (
      <div style={{display: 'inline-block'}}>
        <span style={{display: 'flex', justifyContent: 'space-between', width: `${this.props.width}px`, overflow: 'hidden'}}>
          {this.props.content.split('').map((item, index) => {
            return (
              <span key={index}>{item}</span>
            )
          })}
        </span>
      </div>
    )
  }
}

export default JustifyLabel
