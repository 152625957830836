import React from 'react'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu, Icon } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import routes from '../routes'
import Header from '../components/Header'
import { getMe } from '../redux/auth'
import { getConfigs } from '../redux/common'
import MyAlert from '../components/MyAlert'
import {match} from 'path-to-regexp'

class Monitor extends React.Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem('TOKEN')
    this.state = {
      hasToken: !!token
    }
    if (token && !this.props.me) {
      this.props.dispatch(getMe())
    }

    if (this.props.location.pathname === '/monitor' || this.props.location.pathname === '/monitor/') {
      return this.props.history.push('/monitor/dashboard')
    }

    this.props.dispatch(getConfigs())
  }

  get activeMenuKey () {
    for (let i = 0; i < routes.monitor.length; i++) {
      const pathMatch = match(routes.monitor[i].layout + routes.monitor[i].path, { decode: decodeURIComponent })
      if (pathMatch(this.props.location.pathname)) {
        return routes.monitor[i].menuKey
      }
    }
    return routes.monitor[0].menuKey
  }

  get contentHeight () {
    if (document.querySelector('.ant-layout-content')) {
      return document.querySelector('.ant-layout-content').clientHeight - 20
    } else {
      return 0
    }
  }

  renderMenus = () => {
    return (
      <Menu mode="inline" theme='dark' selectedKeys={[this.activeMenuKey]}>
        {routes.monitor.filter(item => item.icon).map((item) => {
          return (
            <Menu.Item key={item.menuKey}>
              <NavLink to={item.layout + item.path}>
                <Icon type={item.icon} />
                <span className="nav-text">{item.name}</span>
              </NavLink>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  renderRoutes = () => {
    return (
      <Switch>{routes.monitor.map((item, index) => {
        return (
          <Route
            path={item.layout + item.path}
            component={item.component}
            key={index}
          />
        )
      })}</Switch>
    )
  }

  renderMyAlerts = () => {
    return <MyAlert currentPath={this.props.location.pathname}/>
  }

  render () {
    if (this.state.hasToken && this.props.signing) {
      return null
    }

    if (!this.props.me) {
      return <Redirect to='/signin' />
    }

    setTimeout(() => {
      window.onresize = () => {
        if (this.contentContainer) {
          this.contentContainer.style.height = `${this.contentHeight}px`
        }
        if (this.siderContainer) {
          this.siderContainer.style.height = `${this.contentHeight + 20}px`
        }
        if (this.contentScrollbar) {
          this.contentScrollbar.updateScroll()
        }
      }
      window.onresize()
    })

    return (
      <Layout className='monitor'>
        <Header history={this.props.history}/>
        <Layout>
          <Layout.Sider theme='dark' collapsible collapsedWidth={48} width={180}>
            <div ref={(ref) => this.siderContainer = ref} style={{position: 'relative'}}>
              {this.renderMenus()}
            </div>
          </Layout.Sider>
          <Layout.Content>
            <div ref={(ref) => this.contentContainer = ref}>
              <PerfectScrollbar ref={(ref) => this.contentScrollbar = ref}>
                <div>
                  {this.renderMyAlerts()}
                  {this.renderRoutes()}
                </div>
              </PerfectScrollbar>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

Monitor = connect((state) => {
  return {
    me: state.auth.me,
    signing: state.auth.signing
  }
})(Monitor)

export default Monitor
