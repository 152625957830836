import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Icon, Alert, Table, message, Button } from 'antd'
import Clipboard from 'react-clipboard.js'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { ACME_CHALLENGE_TYPE } from '../utils/constant'

import "@uiw/react-textarea-code-editor/dist.css"

class HttpManAuthModeServerFileSettings extends React.Component {
  static props = {
    challengesDetail: PropTypes.array
  }

  handleCopyServerFielPathSuccess = () => {
    message.success('HTTP文件验证请求路径已成功复制到剪贴板')
  }

  handleCopyServerProxyUrlSuccess = () => {
    message.success('代理验证地址已成功复制到剪贴板')
  }

  handleCopyServerProxyHeaderSuccess = () => {
    message.success('代理请求头已成功复制到剪贴板')
  }

  handleCopySuccess = () => {
    message.success(`域名/IP所有权验证文件内容已成功复制到剪贴板`)
  }

  render() {
    const { certificate: { certificateThirdOrder }, challengesDetail: challengesDetailInProps } = this.props
    if (!certificateThirdOrder && !challengesDetailInProps) {
      return <></>
    }

    const { orderDetail: { _challengesDetail } } = certificateThirdOrder || { orderDetail: { _challengesDetail: null } }
    const challengesDetail = (challengesDetailInProps || _challengesDetail).filter(item => item.type === ACME_CHALLENGE_TYPE.HTTP_01)

    const columnsHost = [
      {
        title: 'HTTP文件验证请求路径',
        dataIndex: 'fileUrl',
        ellipsis: true,
        render: text => {
          if (!text) {
            return <>&nbsp;</>
          } else {
            return (
              <div className='record-value'>
                {text}
                <span style={{ height: '100%' }}>
                  <a href={text} target="_blank" rel="noopener noreferrer">点击验证</a>&nbsp;
                  <Clipboard className='ant-btn' style={{ width: 215 }} data-clipboard-text={text} onSuccess={this.handleCopyServerProxyPathSuccess}>
                    <Icon type='copy' />&nbsp;点击复制HTTP文件验证请求路径
                  </Clipboard>
                </span>
              </div>
            )
          }
        }
      }
    ]


    const rowsHost = challengesDetail.map(item => {
      return {
        fileUrl: item.data.url
      }
    })

    const fileContent = challengesDetail[0].data.data
    const fileName = challengesDetail[0].data.url.split('/').slice(-1)[0]

    return (
      <div>
        <div className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle'>
            <Col span={24}>
              <div>
                <Alert message={`请在服务器中创建域名/IP所有权验证文件并使其可以通过以下链接访问`} type="info" showIcon />
                <Table
                  columns={columnsHost}
                  dataSource={rowsHost}
                  size='small'
                  pagination={false}
                  bordered
                  style={{ marginTop: '10px' }}
                  className='record-type-value'
                />
                <div style={{ border: '1px solid #d9d9d9', marginTop: '15px' }}>
                  <div style={{ borderBottom: '1px solid #d9d9d9', position: 'relative', paddingLeft: '8px', height: '36px' }}>
                    <div style={{ lineHeight: '37px', height: '37px' }}>域名/IP所有权验证文件（文件内容如下）：&nbsp;<strong>{fileName}</strong>&nbsp;</div>
                    <Clipboard
                      className='ant-btn'
                      data-clipboard-text={fileContent}
                      onSuccess={this.handleCopySuccess.bind(this)}
                      style={{ height: '37px', position: 'absolute', top: '-1px', right: '142px' }}
                    >
                      <Icon type='copy' />&nbsp;点击复制文件内容
                    </Clipboard>
                    <Button
                      style={{ height: '37px', position: 'absolute', top: '-1px', right: '-1px' }}
                      onClick={() => this.certKeyFileDownload.click()}>
                      <Icon type='download' />
                      <a
                        style={{ display: 'none' }}
                        download={fileName}
                        href={URL.createObjectURL(new Blob([fileContent]))}
                        ref={(ref) => this.certKeyFileDownload = ref}>
                      </a>
                      &nbsp;点击下载验证文件
                    </Button>
                  </div>
                  <CodeEditor
                    value={fileContent}
                    language={'txt'}
                    readOnly={true}
                    padding={15}
                    style={{
                      backgroundColor: "#f5f5f5"
                    }}
                  />
                </div>
                <Alert message="请不要删除以上服务端域名/IP所有权验证文件，否则会导致相关证书生成失败" type="info" showIcon style={{ marginTop: '10px' }} />
                <Alert message={`对于Sectigo证书品牌，每次证书生成或更新任务所需添加的服务端文件不同`} type="info" showIcon style={{ marginTop: '10px' }} />
                <Alert message="可通过点击上方HTTP文件验证请求路径右侧的[点击验证]按钮手动检查服务端文件配置是否正确" type="info" showIcon style={{ marginTop: '10px' }} />
                <Alert message="HTTP文件验证模式不支持证书的自动更新，如需证书的自动更新功能请使用其它域名所有权验证模式" type="info" showIcon style={{ marginTop: '10px' }} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

HttpManAuthModeServerFileSettings = connect((state) => {
  return {
    certificate: state.certificate.certificate
  }
})(HttpManAuthModeServerFileSettings)

export default HttpManAuthModeServerFileSettings
