import React from 'react'
import {connect} from 'react-redux'
import { Tabs, Badge } from 'antd'
import querystring from 'query-string'
import AllCertificates from '../../components/AllCertificates'
import AllCertificatesVersion from '../../components/AllCertificatesVersion'
import AllCertificatesPush from '../../components/AllCertificatesPush'
import { getAllCertificateVersionsInProcessing } from '../../redux/certificate/version'

class Certificates extends React.Component {

  constructor (props) {
    super(props)
    this.searchAllCertificateVersionsInProcessing()
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  handleChangeActiveTab = (activeKey) => {
    const curUrl = new URL(window.location.href)
    curUrl.searchParams.set('activeTab', activeKey)
    this.props.history.replace(curUrl.pathname + curUrl.search)
  }

  searchAllCertificateVersionsInProcessing = () => {
    this.props.dispatch(getAllCertificateVersionsInProcessing()).then((response) => {
      if (response.payload) {
        if (response.payload.count > 0) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchAllCertificateVersionsInProcessing()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  render () {
    let {activeTab} = querystring.parse(this.props.location.search)
    activeTab = activeTab || '1'

    return (
      <div className='content'>
        <Tabs defaultActiveKey={activeTab} style={{height: '100%', overflow: 'unset'}} onChange={this.handleChangeActiveTab.bind(this)}>
            <Tabs.TabPane tab="证书列表" key="1">
              <AllCertificates {...this.props} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={
              <>
                证书更新任务{this.props.certificateVersionsInProcessing.count ? <Badge status="processing" style={{position: 'absolute', top: '9px', right: '0px'}}/> : ''}
              </>
            } key="2">
              <AllCertificatesVersion {...this.props}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="证书部署任务" key="3">
              <AllCertificatesPush {...this.props}/>
            </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

Certificates = connect((state) => {
  return {
    certificateVersionsInProcessing: state.certificate.version.versionsInProcessing || {count: 0}
  }
})(Certificates)

export default Certificates
