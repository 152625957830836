const report = ({eventType, eventName, ...eventArgs}) => {
  try {
    const transactionName = `REPORT-${eventType}-${eventName}`
    const transaction = window.sentry.startTransaction({
      op: 'mark',
      name: transactionName,
      tags: eventArgs
    })
    transaction.finish()
  } catch (e) {
  }
}

const reportCLK = ({eventName, ...eventArgs}) => {
  report({
    eventType: 'CLK',
    eventName: eventName,
    ...eventArgs
  })
}

const reportEXP = ({eventName, ...eventArgs}) => {
  report({
    eventType: 'EXP',
    eventName: eventName,
    ...eventArgs
  })
}

const reportSUC = ({eventName, ...eventArgs}) => {
  report({
    eventType: 'SUC',
    eventName: eventName,
    ...eventArgs
  })
}

export const setReportContext = ({userId, nickName, email} = {}) => {
  try {
    if (!userId) {
      window.sentry.setUser(null)
    } else {
      window.sentry.setUser({id: userId, username: nickName, email})
    }
  } catch (e) {
  }
}

export const reportSigninClick = () => {
  reportCLK({
    eventName: 'signin'
  })
}

export const reportSignupClick = () => {
  reportCLK({
    eventName: 'signup'
  })
}

export const reportOAuthClick = () => {
  reportCLK({
    eventName: 'oauth'
  })
}

export const reportSigninExp = () => {
  reportEXP({
    eventName: 'signin'
  })
}

export const reportSignupExp = () => {
  reportEXP({
    eventName: 'signup'
  })
}

export const reportOAuthExp = () => {
  reportEXP({
    eventName: 'oauth'
  })
}

export const reportSigninSuccess = () => {
  reportSUC({
    eventName: 'signin'
  })
}

export const reportSignupSuccess = () => {
  reportSUC({
    eventName: 'signup'
  })
}

export const reportOAuthSuccess = () => {
  reportSUC({
    eventName: 'oauth'
  })
}

export const reportChargeQRExp = (eventData) => {
  reportEXP({
    eventName: 'charge-qr',
    ...eventData
  })
}

export const reportChargeQRClick = (eventData) => {
  reportCLK({
    eventName: 'charge-qr',
    ...eventData
  })
}
