const GET_CERTIFICATE_VERSIONS_ING = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSIONS_ING'
const GET_CERTIFICATE_VERSIONS_SUCCESS = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSIONS_SUCCESS'
const GET_CERTIFICATE_VERSIONS_FAILURE = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSIONS_FAILURE'

const GET_ALL_CERTIFICATE_VERSIONS_ING = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_ING'
const GET_ALL_CERTIFICATE_VERSIONS_SUCCESS = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_SUCCESS'
const GET_ALL_CERTIFICATE_VERSIONS_FAILURE = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_FAILURE'

const GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_ING = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_ING'
const GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_SUCCESS = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_SUCCESS'
const GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_FAILURE = 'CERTIFICATE/VERSION/GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_FAILURE'

const CREATE_CERTIFICATE_VERSION_ING = 'CERTIFICATE/VERSION/CREATE_CERTIFICATE_VERSION_ING'
const CREATE_CERTIFICATE_VERSION_SUCCESS = 'CERTIFICATE/VERSION/CREATE_CERTIFICATE_VERSION_SUCCESS'
const CREATE_CERTIFICATE_VERSION_FAILURE = 'CERTIFICATE/VERSION/CREATE_CERTIFICATE_VERSION_FAILURE'

const REVOKE_CERTIFICATE_VERSION_ING = 'CERTIFICATE/VERSION/REVOKE_CERTIFICATE_VERSION_ING'
const REVOKE_CERTIFICATE_VERSION_SUCCESS = 'CERTIFICATE/VERSION/REVOKE_CERTIFICATE_VERSION_SUCCESS'
const REVOKE_CERTIFICATE_VERSION_FAILURE = 'CERTIFICATE/VERSION/REVOKE_CERTIFICATE_VERSION_FAILURE'

const GET_CERTIFICATE_VERSION_DETAIL_ING = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSION_DETAIL_ING'
const GET_CERTIFICATE_VERSION_DETAIL_SUCCESS = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSION_DETAIL_SUCCESS'
const GET_CERTIFICATE_VERSION_DETAIL_FAILURE = 'CERTIFICATE/VERSION/GET_CERTIFICATE_VERSION_DETAIL_FAILURE'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CERTIFICATE_VERSIONS_SUCCESS:
      return {
        ...state,
        [`versions_${action.certificateId}`]: action.payload
      }
    case GET_CERTIFICATE_VERSIONS_FAILURE:
      return {
        ...state,
        [`versions_${action.certificateId}`]: {
          count: 0,
          rows: []
        }
      }
    case GET_ALL_CERTIFICATE_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: action.payload
      }
    case GET_ALL_CERTIFICATE_VERSIONS_FAILURE:
      return {
        ...state,
        versions: {
          count: 0,
          rows: []
        }
      }
    case GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_SUCCESS:
      return {
        ...state,
        versionsInProcessing: action.payload
      }
    case GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_FAILURE:
      return {
        ...state,
        versionsInProcessing: {
          count: 0
        }
      }
    case GET_CERTIFICATE_VERSION_DETAIL_SUCCESS:
      return {
        ...state,
        certificateVersionDetail: action.payload
      }
    case GET_CERTIFICATE_VERSION_DETAIL_FAILURE:
      return {
        ...state,
        certificateVersionDetail: null
      }
    default:
      return { ...state }
  }
}

export const getCertificateVersions = ({certificateId, offset, limit}) => {
  return {
    types: [GET_CERTIFICATE_VERSIONS_ING, GET_CERTIFICATE_VERSIONS_SUCCESS, GET_CERTIFICATE_VERSIONS_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/${certificateId}/versions`, {params: {offset, limit}}),
    certificateId
  }
}

export const getAllCertificateVersions = ({searchContent, offset, limit}) => {
  return {
    types: [GET_ALL_CERTIFICATE_VERSIONS_ING, GET_ALL_CERTIFICATE_VERSIONS_SUCCESS, GET_ALL_CERTIFICATE_VERSIONS_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/versions`, {params: {searchContent, offset, limit}})
  }
}

export const getAllCertificateVersionsInProcessing = () => {
  return {
    types: [GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_ING, GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_SUCCESS, GET_ALL_CERTIFICATE_VERSIONS_IN_PROCESSING_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/versionsInProcessing`)
  }
}

export const getCertificateVersionDetail = ({certificateId, certificateVersionId}) => {
  return {
    types: [GET_CERTIFICATE_VERSION_DETAIL_ING, GET_CERTIFICATE_VERSION_DETAIL_SUCCESS, GET_CERTIFICATE_VERSION_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/${certificateId}/versions/${certificateVersionId}`)
  }
}

export const createCertificateVersion = ({certificateId, certificateThirdOrderId, isForRetry}) => {
  return {
    types: [CREATE_CERTIFICATE_VERSION_ING, CREATE_CERTIFICATE_VERSION_SUCCESS, CREATE_CERTIFICATE_VERSION_FAILURE],
    promise: (apiClient) => apiClient.post(`/api/certificates/${certificateId}/versions`, {certificateThirdOrderId, isForRetry})
  }
}

export const revokeCertificateVersion = ({certificateId, certificateVersionId}) => {
  return {
    types: [REVOKE_CERTIFICATE_VERSION_ING, REVOKE_CERTIFICATE_VERSION_SUCCESS, REVOKE_CERTIFICATE_VERSION_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/certificates/${certificateId}/versions/${certificateVersionId}`)
  }
}
