import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Icon, Button, message } from 'antd'
import JustifyLabel from './JustifyLabel'
import { setPassword } from '../redux/auth'

class SetPwd extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitState()
  }

  getInitState = () => {
    return {
      oldPassword: '',
      newPassword: '',
      rePassword: ''
    }
  }

  handleChangeOldPassword = (e) => {
    this.setState({
      oldPassword: e.target.value
    })
  }

  handleChangeNewPassword = (e) => {
    this.setState({
      newPassword: e.target.value
    })
  }

  handleChangeRePassword = (e) => {
    this.setState({
      rePassword: e.target.value
    })
  }

  handleClickSetPassword = () => {
    const {oldPassword, newPassword, rePassword} = this.state
    if (!oldPassword) {
      return message.error('请输入原密码')
    }
    if (!newPassword) {
      return message.error('请输入新密码')
    }
    if (!rePassword) {
      return message.error('请确认新密码')
    }
    if (newPassword !== rePassword) {
      return message.error('两次输入的新密码不一致')
    }
    this.props.dispatch(setPassword({oldPassword, newPassword})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('密码修改成功')
        this.props.onCompleteSetPassword()
        this.setState(this.getInitState())
      }
    }).catch((error) => {
      return message.error(`未知异常:${error}`)
    })
  }

  render () {
    return (
      <>
        <Form>
          <Form.Item>
            <Input.Password
              addonBefore={<JustifyLabel content='原密码' width={70}/>}
              prefix={<Icon type='lock' />}
              value={this.state.oldPassword}
              onChange={this.handleChangeOldPassword}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              addonBefore={<JustifyLabel content='新密码' width={70}/>}
              prefix={<Icon type='lock' />}
              value={this.state.newPassword}
              onChange={this.handleChangeNewPassword}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              addonBefore={<JustifyLabel content='确认新密码' width={70}/>}
              prefix={<Icon type='lock' />}
              value={this.state.rePassword}
              onChange={this.handleChangeRePassword}
            />
          </Form.Item>
          <Button type='primary' block onClick={this.handleClickSetPassword}>修改密码</Button>
        </Form>
      </>
    )
  }
}

SetPwd = connect()(SetPwd)

export default SetPwd
