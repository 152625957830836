import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Input, Modal, Tooltip, Icon, message } from 'antd'
import CertificateType from './CertificateType'
import CertificateAlias from './CertificateAlias'
import CertificateDomains from './CertificateDomains'
import { CERTIFICATE_AUTH_MODE, CERTIFICATE_BRAND, CERTIFICATE_VERSION_STATUS } from '../utils/constant'
import { getAllCertificateVersions, revokeCertificateVersion } from '../redux/certificate/version'

import sectigoImg from '../assets/svg/sectigo.svg'
import letsenryptImg from '../assets/svg/letsencrypt.svg'

class MyCertificatesVersion extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      activeCertificateId: null,
      editCertificateConfigDrawerVisible: false
    }
    this.searchCertificateVersions()
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  refreshUrl = ({searchContent, offset, limit}) => {
    const curUrl = new URL(window.location.href)
    curUrl.searchParams.set('search', searchContent)
    curUrl.searchParams.set('offset', offset)
    curUrl.searchParams.set('limit', limit)
    this.props.history.replace(curUrl.pathname + curUrl.search)
  }

  searchCertificateVersions = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit})

    this.props.dispatch(getAllCertificateVersions({searchContent, offset, limit})).then((response) => {
      this.setState({
        firstLoading: false
      })
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.GENERATING || item.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.QUEUING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchCertificateVersions()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  get tableColumns () {
    const columns = [{
      title: '证书ID/名称',
      dataIndex: 'certificateId',
      render: (text, record) => {
        return (
          <>
            <Button onClick={() => this.props.history.push(`/monitor/certificates/${record.certificateId}`)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.certificateName}</Button>
            <br/>
            <CertificateAlias key={record.certificateId} certificateId={record.certificateId} certificateAlias={record.certificateAlias || record.certificateName} onChangeComplete={this.searchCertificateVersions}/>
          </>
        )
      }
    }, {
      title: '证书类型',
      dataIndex: 'certificateType',
      sorter: true,
      render: (text, record) => {
        return <CertificateType type={record.certificateType}/>
      }
    }, {
      title: '关联域名',
      dataIndex: 'certificateDomains',
      render: (text, record) => {
        return <CertificateDomains domains={record.certificateDomains} onlyFirst={true} maxWidth={235}/>
      }
    }, {
      title: '证书品牌',
      dataIndex: 'certificateBrand',
      sorter: true,
      render: (certificateBrand, record) => {
        if (certificateBrand === CERTIFICATE_BRAND.SECTIGO) {
          return <img height={10} src={sectigoImg} alt="Sectigo" />
        } else {
          return <img height={22} style={{ marginLeft: '-2px', marginTop: '-9px' }} src={letsenryptImg} alt="Let's Encrypt" />
        }
      }
    }, {
      title: '任务创建时间',
      dataIndex: 'certificateVersionCreateTime',
      key: 'certificateVersionCreateTime',
      render: certificateVersionCreateTime => moment(certificateVersionCreateTime).format('YYYY-MM-DD HH:mm'),
      width: 142
    }, {
      title: '任务创建方式',
      dataIndex: 'certificateVersionIsAuto',
      key: 'certificateVersionIsAuto',
      render: (text, record) => record.certificateVersionIsAuto ? '系统自动创建' : '用户手动创建',
      width: 98
    }, {
      title: '任务状态',
      dataIndex: 'certificateVersionStatus',
      key: 'status',
      width: 80,
      render: (text, record) => {
        if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.QUEUING) {
          let queueReason = ''
          if (record.certificateVersionIsAuto) {
            queueReason = '为避免因系统创建的自动更新任务并发过高而触发证书颁发机构的接口限流，所以需要排队进行更新，自动更新任务预计将在数小时内完成，请您耐心等待'
          } else if (record.certificateAuthMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
            queueReason = '对于单个用户来说，所有通过免DNS授权方式申请的证书，其验证域名所有权指向的是同一个CNAME解析，当使用该方式并发申请或者更新证书时，会导致该CNAME解析最终指向的TXT类型解析记录过多，导致证书颁发机构验证域名所有权失败，所以需要排队进行申请或更新，请您耐心等待'
          }
          return (
            <Tooltip title={queueReason}>
              <span style={{color: 'orange'}}>排队中&nbsp;<Icon type="clock-circle" /></span>
            </Tooltip>
          )
        } else if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.GENERATING) {
          return (
            <Tooltip title={'证书更新任务执行中，预计需要 3 ～ 15 分钟，请您耐心等待'}>
              <span style={{color: '#1890ff'}}>更新中&nbsp;<Icon type="loading" /></span>
            </Tooltip>
          )
        } else if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.GENERATED) {
          if (record.isExpired) {
            return <span style={{color: 'gray'}}>已过期</span>
          } else {
            return <span style={{color: 'green'}}>已成功</span>
          }
        } else if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.REVOKED) {
          return <span style={{color: 'gray'}}>已吊销</span>
        } else if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.ERROR) {
          return <span style={{color: 'red'}}>已失败</span>
        }
      }
    }, {
      title: '任务失败信息',
      dataIndex: 'certificateVersionError',
      key: 'certificateVersionError',
      render: certificateVersionError => {
        const error = certificateVersionError || '-'
        return (
          <Tooltip title={error}>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
              {error}
            </div>
          </Tooltip>
        )
      }
    }, {
      title: '新版本过期时间',
      dataIndex: 'certificateVersionExpiredTime',
      key: 'certificateVersionExpiredTime',
      width: 142,
      render: certificateVersionExpiredTime => certificateVersionExpiredTime ? moment(certificateVersionExpiredTime).format('YYYY-MM-DD HH:mm') : '-'
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.GENERATED) {
          if (record.certificateVersionIsExpired) {
            return (
              <>
                <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.certificateVersionId)} size='small' type='link'>查看证书文件</Button>
              </>
            )
          } else {
            return (
              <>
                <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.certificateVersionId)} size='small' type='link'>查看证书文件</Button>
                |
                <Button onClick={this.handleRevokeCertificateVersion.bind(this, record.certificateId, record.certificateVersionId)} size='small' type='link'>吊销</Button>
              </>
            )
          }
        } else if (record.certificateVersionStatus === CERTIFICATE_VERSION_STATUS.REVOKED){
          return (
            <>
              <Button onClick={this.handleVersionDetail.bind(this, record.certificateId, record.certificateVersionId)} size='small' type='link'>查看证书文件</Button>
            </>
          )
        } else {
          return <></>
        }
      }
    }]

    return columns
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.certificateVersions.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleRevokeCertificateVersion = (certificateId, certificateVersionId) => {
    Modal.confirm({
      title: '确认要吊销该证书版本吗?',
      content: '吊销该证书版本会导致已使用该证书版本的网站无法使用HTTPS进行访问，请谨慎吊销',
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(revokeCertificateVersion({certificateId, certificateVersionId})).then((response) => {
          if (response.errorMsg) {
            return message.error(response.errorMsg)
          }
          message.success('吊销证书成功')
    
          this.searchCertificateVersions()
        })
      },
      onCancel: () => {
      },
    })
  }

  handleVersionDetail = (certificateId, certificateVersionId) => {
    this.props.history.push(`/monitor/certificates/${certificateId}/versions/${certificateVersionId}`)
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0
    })
    this.searchCertificateVersions({offset: 0})
  }

  handleChangeTable = (pagination) => {
    const {current, pageSize} = pagination
    const offset = pageSize * (current - 1)
    const limit = pageSize
    this.setState({
      current,
      offset,
      limit
    })
    this.searchCertificateVersions({offset, limit})
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入关联域名或证书ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          onChange={this.handleChangeTable}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.certificateVersions.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`
          }}
        />
      </div>
    )
  }
}

MyCertificatesVersion = connect((state) => {
  return {
    certificateVersions: state.certificate.version.versions || {count: 0, rows: []}
  }
})(MyCertificatesVersion)

export default MyCertificatesVersion
