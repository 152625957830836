import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button, Icon, message, Input} from 'antd'
import { createWatch } from '../redux/watch'

class CreateWatch extends React.Component {
  static props = {
    onCreateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      domain: '',
      ip: null,
      port: null,
      domainChecking: false
    }
  }

  handleChangeDomain = (e) => {
    this.setState({
      domain: e.target.value,
      domainChecking: false
    })
  }

  handleChangeIP = (e) => {
    this.setState({
      ip: e.target.value,
      domainChecking: false
    })
  }

  handleChangePort = (e) => {
    this.setState({
      port: e.target.value,
      domainChecking: false
    })
  }

  handleClickCreate = () => {
    const {domain, ip, port} = this.state
    const {onCreateComplete} = this.props

    this.setState({
      domainChecking: true
    })
  
    this.props.dispatch(createWatch({domain, ip, port: port || 443}))
      .then(response => {
        this.setState({
          domainChecking: false
        })
        
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('监控添加成功')
  
        if (onCreateComplete) {
          onCreateComplete()
        }
      })
  }

  render() {
    const {domain, ip, port, domainChecking} = this.state

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={6}>
              域名
            </Col>
            <Col span={18}>
              <Input placeholder='请输入需要监控的域名' allowClear value={domain} onChange={this.handleChangeDomain} />
            </Col>
          </Row>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={6}>
              IP
            </Col>
            <Col span={18}>
              <Input placeholder='如需指定监控的IP, 则在此输入，默认无需设置' allowClear value={ip} onChange={this.handleChangeIP} />
            </Col>
          </Row>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={6}>
              端口
            </Col>
            <Col span={18}>
              <Input placeholder='如需指定监控的端口, 则在此输入，默认使用443端口' allowClear value={port} onChange={this.handleChangePort} />
            </Col>
          </Row>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={24}>
              <Icon type="info-circle" style={{ color: '#faad14' }} /><span style={{ color: '#faad14' }}>&nbsp;添加监控前请先确认您所输入域名对应的站点已开启HTTPS并可正常访问</span>
            </Col>
          </Row>
          <Row type='flex' justify='end' gutter={16}>
            <Col span={18} />
            <Col span={6}>
              <Button onClick={this.handleClickCreate} type='primary' loading={domainChecking} block>添加监控</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

CreateWatch = connect()(CreateWatch)

export default CreateWatch
