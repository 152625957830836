import React from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Tabs, Row, Col, Button, Icon, message} from 'antd'
import WatchRecords from '../../components/WatchRecords'
import { getWatchDetail } from '../../redux/watch'
import { createWatchRecord } from '../../redux/watch/record'

class WatchDetail extends React.Component {
  constructor (props) {
    super(props)

    this.searchWatchDetail()
    this.state = {
      tabActiveKey: 'records'
    }
  }

  searchWatchDetail = () => {
    const watchId = this.watchId
    this.props.dispatch(getWatchDetail({watchId}))
  }

  get watchId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  handleChangeTab = (activeKey) => {
    this.setState({
      tabActiveKey: activeKey
    })
  }

  handleClickRenewWatch = () => {
    const watchId = this.watchId
    this.props.dispatch(createWatchRecord({watchId})).then(result => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      }

      this.watchRecordsCtl.searchWatchRecords()
      message.success('证书生成任务已创建成功')
    })
  }

  initWatchRecordsRef = (ref) => {
    this.watchRecordsCtl = ref
  }

  // 取消显示该按钮，防止用户过快点击造成OCSP服务器速率限制
  renderTabBarExtraContent = () => {
    const {tabActiveKey} = this.state

    if (tabActiveKey === 'records') {
      return (
        <>
          <Button type='primary' size='small' style={{padding: '0 20px'}} onClick={this.handleClickRenewWatch}>重新监测</Button>
        </>
      )
    }
  }

  render () {
    const {watchDetail} = this.props

    if (!watchDetail) {
      return null
    }

    return (
      <div className='detail'>
        <div>
          <p className='header'><Icon type='eye'/>{watchDetail.name}</p>
          <Row type='flex' align='middle'>
            <Col span={2}>监控名称：</Col>
            <Col span={6}>{watchDetail.name}</Col>
            <Col span={2}>创建时间：</Col>
            <Col span={10}>{moment(watchDetail.createTime).format('YYYY-MM-DD HH:mm')}</Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>监控域名：</Col>
            <Col span={22}>{watchDetail.domain}</Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>监控IP：</Col>
            <Col span={22}>{watchDetail.ip || '-'}</Col>
          </Row>
          <Row type='flex' align='middle' style={{marginTop: '10px'}}>
            <Col span={2}>监控端口：</Col>
            <Col span={22}>{watchDetail.port}</Col>
          </Row>
        </div>
        <Tabs style={{marginTop: '20px'}} onChange={this.handleChangeTab}>
          <Tabs.TabPane tab="监测记录" key="records">
            <WatchRecords watchId={watchDetail.id} key={watchDetail.id} initRef={this.initWatchRecordsRef}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

WatchDetail = connect((state) => {
  return {
    watchDetail: state.watch.watch.watchDetail
  }
}) (WatchDetail)

export default WatchDetail
