import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Radio, message, Modal} from 'antd'
import { createNotificationTarget } from '../redux/notification/target'
import { getNotificationProviders } from '../redux/notification/provider'
import ConfigField from './ConfigField'
import { checkAccessJson, maskEmail } from '../utils'
import { checkNotificationTarget } from '../redux/common'

class CreateNotificationTarget extends React.Component {
  static props = {
    onCreateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      notificationProviderId: null,
      notificationTargetAccessJson: null
    }

    this.props.dispatch(getNotificationProviders())
  }

  get notificationProviderId() {
    const { notificationProviders } = this.props
    const { notificationProviderId } = this.state
    if (notificationProviderId) {
      return notificationProviderId
    } else {
      return notificationProviders[0].id
    }
  }

  handleChangeNotificationProvider = (e) => {
    if (e.target.value === -1) {
      return
    }

    this.setState({
      notificationTargetAccessJson: null,
      notificationProviderId: e.target.value
    })
  }

  handleEditNotificationTarget = (field, value) => {
    const accessJson = { ...(this.state.notificationTargetAccessJson || {}) }
    accessJson[field] = value
    this.setState({
      notificationTargetAccessJson: accessJson,
      notificationTargetVerified: false
    })
  }

  handleClickCreate = () => {
    const {notificationTargetAccessJson} = this.state
    const {notificationProviders, onCreateComplete} = this.props
    const notificationProviderId = this.notificationProviderId
    const {configJson} = notificationProviders.find(item => item.id === notificationProviderId)

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: notificationTargetAccessJson})
    if (error) {
      return message.error(error)
    }

    Modal.confirm({
      title: '确定要添加该通知方式吗？',
      content: `添加前请确认已通过发送测试消息功能来验证配置填写正确，防止因配置错误导致无法接收通知`,
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(createNotificationTarget({notificationProviderId, accessJson: notificationTargetAccessJson}))
          .then(response => {
            if (response.errorMsg) {
              return message.error(response.errorMsg)
            }

            message.success('通知方式添加成功')

            if (onCreateComplete) {
              onCreateComplete()
            }
          })
      },
      onCancel: () => {
      },
    })
  }

  handleClickCheck = () => {
    const {notificationTargetAccessJson} = this.state
    const {notificationProviders} = this.props
    const notificationProviderId = this.notificationProviderId
    const {configJson} = notificationProviders.find(item => item.id === notificationProviderId)

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: notificationTargetAccessJson})
    if (error) {
      return message.error(error)
    }

    this.props.dispatch(checkNotificationTarget({notificationProviderId, accessJson: notificationTargetAccessJson}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('测试通知已发送成功，请确认是否已正确接收')
      })
  }

  render() {
    const { notificationProviders } = this.props
    if (!notificationProviders.length) {
      return <></>
    }

    const { notificationTargetAccessJson } = this.state
    const notificationProviderId = this.notificationProviderId

    const notificationProvider = notificationProviders.find(item => item.id === notificationProviderId)
    const { fields } = notificationProvider.configJson

    const { userId, email } = this.props.me

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group value={notificationProviderId} buttonStyle='solid' onChange={this.handleChangeNotificationProvider}>
            {notificationProviders.filter(item => item.visible).map((item, index) => {
              if (item.configJson) {
                return (
                  <Radio.Button value={item.id} key={index}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <img src={item.logo} alt='' width={15} height={15} />&nbsp;{item.name}
                    </div>
                  </Radio.Button>
                )
              } else {
                return (
                  <Radio.Button value={item.id} key={index} disabled>
                    <img src={item.logo} alt='' width={20} height={20} />{item.name}&nbsp;<span style={{ fontSize: '12px', background: '#f50', color: 'white', borderRadius: '10px', lineHeight: '20px', height: '20px', padding: '1px 8px' }}>即将上线</span>
                  </Radio.Button>
                )
              }
            })}
            <Radio.Button value={-1} key={-1} style={{top: -3.5, backgroundColor: '#ffe58f'}}>
              <div style={{display: 'flex', alignItems: 'center', height: 30}} className='tucao'>
                <form method="post" action="https://support.qq.com/products/109240/topic-detail/2512/#label=popular" target='_blank' rel='noopener noreferrer'>
                  <input type="hidden" name="openid" value={userId}/>
                  <input type="hidden" name="nickname" value={maskEmail({email})}/>
                  <input type="hidden" name="avatar" value="https://cdn.idevbase.com/assets/images/avatar.png"/>
                  <button type='submit' style={{height: 30, lineHeight: '30px'}}>
                    <Icon type="bulb" theme="twoTone"/>&nbsp;我要提需求
                  </button>
                </form>
              </div>
            </Radio.Button>
          </Radio.Group>
          <div>
            {fields.map((item, index) => {
              const value = (notificationTargetAccessJson || {})[item.field]
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditNotificationTarget} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={18}>
              <Icon type="info-circle" style={{ color: '#faad14' }} /><a style={{ color: '#faad14' }} href={notificationProvider.configJson.helpUrl} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何配置{notificationProvider.name}</a>
            </Col>
          </Row>
          <Row type='flex' justify='end' gutter={16}>
            <Col style={{display: 'flex'}}>
              <Button onClick={this.handleClickCheck} block>发送测试消息</Button>
              <Button onClick={this.handleClickCreate} type='primary' block style={{marginLeft: '10px'}}>添加通知方式</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

CreateNotificationTarget = connect((state) => {
  return  {
    me: state.auth.me,
    notificationProviders: state.notification.provider.notificationProviders,
  }
})(CreateNotificationTarget)

export default CreateNotificationTarget
