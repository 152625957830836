import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button, Radio, message, Modal} from 'antd'
import { getNotificationTargetDetail, changeNotificationTargetAccessJson} from '../redux/notification/target'
import { getNotificationProviders } from '../redux/notification/provider'
import ConfigField from './ConfigField'
import { checkAccessJson } from '../utils'
import { checkNotificationTarget } from '../redux/common'

class UpdateNotificationTarget extends React.Component {
  static props = {
    notificationTargetId: PropTypes.number,
    onUpdateComplete: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      notificationTargetAccessJson: null
    }

    this.props.dispatch(getNotificationProviders())
    this.props.dispatch(getNotificationTargetDetail({notificationTargetId: props.notificationTargetId})).then((result) => {
      this.setState({
        notificationTargetAccessJson: result.payload.accessJson
      })
    })
  }

  handleEditNotificationTarget = (field, value) => {
    const accessJson = { ...this.state.notificationTargetAccessJson }
    accessJson[field] = value
    this.setState({
      notificationTargetAccessJson: accessJson,
      notificationTargetVerified: false
    })
  }

  handleClickCheck = () => {
    const {notificationTargetAccessJson} = this.state
    const {notificationProviders, notificationTargetDetail} = this.props
    const {notificationProviderId} = notificationTargetDetail
    const {configJson} = notificationProviders.find(item => item.id === notificationProviderId)

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: notificationTargetAccessJson})
    if (error) {
      return message.error(error)
    }

    this.props.dispatch(checkNotificationTarget({notificationProviderId, accessJson: notificationTargetAccessJson}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg)
        }

        message.success('测试通知已发送成功，请确认是否已正确接收')
      })
  }

  handleClickUpdate = () => {
    const {notificationTargetAccessJson} = this.state
    const {onUpdateComplete, notificationTargetDetail, notificationProviders} = this.props
    const {notificationProviderId} = notificationTargetDetail
    const notificationProvider = notificationProviders.find(item => item.id === notificationProviderId)
    const { configJson } = notificationProvider

    const error = checkAccessJson({providerConfigJson: configJson, serverAccessJson: notificationTargetAccessJson})
    if (error) {
      return message.error(error)
    }

    Modal.confirm({
      title: '确定要修改该通知方式配置吗？',
      content: `修改前请确认已通过发送测试消息功能来验证配置填写正确，防止因配置错误导致无法接收通知`,
      okType: 'primary',
      okButtonProps: {size: 'small'},
      cancelButtonProps: {size: 'small'},
      autoFocusButton: false,
      maskClosable: true,
      onOk: () => {
        this.props.dispatch(changeNotificationTargetAccessJson({notificationTargetId: notificationTargetDetail.id, accessJson: notificationTargetAccessJson}))
          .then(response => {
            if (response.errorMsg) {
              return message.error(response.errorMsg)
            }

            message.success('通知方式配置修改成功')

            if (onUpdateComplete) {
              onUpdateComplete()
            }
          })
      },
      onCancel: () => {
      },
    })
  }

  render() {
    const { notificationProviders } = this.props
    if (!notificationProviders.length) {
      return <></>
    }

    const {notificationTargetDetail} = this.props
    if (!notificationTargetDetail) {
      return <></>
    }

    const { notificationTargetAccessJson } = this.state
    const notificationProviderId = notificationTargetDetail.notificationProviderId

    const notificationProvider = notificationProviders.find(item => item.id === notificationProviderId)
    const { fields } = notificationProvider.configJson

    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <Radio.Group buttonStyle='solid'>
            <Radio.Button>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={notificationTargetDetail.notificationProviderLogo} alt='' width={15} height={15} />&nbsp;{notificationTargetDetail.notificationProviderName}
              </div>
            </Radio.Button>
          </Radio.Group>
          <div>
            {fields.map((item, index) => {
              const value = (notificationTargetAccessJson || {})[item.field]
              return (
                <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                  <Col span={6}>
                    {item.name}
                  </Col>
                  <Col span={18}>
                    <ConfigField {...item} value={value} onChange={this.handleEditNotificationTarget} editable/>
                  </Col>
                </Row>
              )
            })}
          </div>
          <Row type='flex' justify='end' gutter={16} style={{ marginTop: '10px' }} >
            <Col style={{display: 'flex'}}>
              <Button onClick={this.handleClickCheck} block>发送测试消息</Button>
              <Button onClick={this.handleClickUpdate} type='primary' block style={{marginLeft: '10px'}}>确认修改</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

UpdateNotificationTarget = connect((state) => {
  return  {
    notificationProviders: state.notification.provider.notificationProviders,
    notificationTargetDetail: state.notification.target.notificationTargetDetail
  }
})(UpdateNotificationTarget)

export default UpdateNotificationTarget
