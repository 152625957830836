import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, Input, message, Drawer, Modal } from 'antd'
import { getDnsServers, removeDnsServer } from '../../redux/dns/server'
import { getDnsProviders } from '../../redux/dns/provider'
import DnsServerAlias from '../../components/DnsServerAlias'
import CreateDnsServer from '../../components/CreateDnsServer'
import UpdateDnsServer from '../../components/UpdateDnsServer'

class DnsServers extends React.Component {
  constructor (props) {
    super(props)
    let {search, offset, limit} = querystring.parse(this.props.location.search)
    search = search || ''
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      searchContent: search,
      current,
      offset,
      limit,
      activeDnsServerId: null,
      createDnsServerDrawerVisible: false,
      updateDnsServerDrawerVisible: false
    }

    this.searchDnsProviders()
    this.searchDnsServers()
  }

  refreshUrl = ({searchContent, offset, limit}) => {
    this.props.history.replace(`${this.props.location.pathname}?search=${encodeURIComponent(searchContent)}&offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}`)
  }

  searchDnsServers = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)

    this.refreshUrl({searchContent, offset, limit})

    this.props.dispatch(getDnsServers({searchContent, offset, limit})).then(() => {
      this.setState({
        firstLoading: false
      })
    })
  }

  searchDnsProviders = () => {
    if (!this.props.dnsProviders) {
      this.props.dispatch(getDnsProviders())
    }
  }

  get dnsProvidersMap () {
    if (this.props.dnsProviders) {
      return new Map(this.props.dnsProviders.map(item => [item.id, item]))
    } else {
      return new Map()
    }
  }

  get tableColumns () {
    return [{
      title: 'DNS授权ID/名称',
      dataIndex: 'id',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickDnsServer.bind(this, record.id)} type='link' style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
            <br/>
            <DnsServerAlias key={record.id} dnsServerId={record.id} dnsServerAlias={record.alias || record.name} onChangeComplete={this.searchDnsServers}/>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'dnsProviderName',
      render: (text, record) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={record.dnsProviderLogo} alt='' width={15} height={15}/>&nbsp;{record.dnsProviderName}
          </div>
        )
      }
    }, {
      title: '关联证书数量',
      dataIndex: 'associatedCertificatesCount'
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Button onClick={this.handleClickDnsServer.bind(this, record.id)} size='small' type='link'>查看</Button>
            |
            <Button onClick={this.handleUpdateDnsServer.bind(this, record)} size='small' type='link'>修改</Button>
            |
            <Button onClick={this.handleDeleteDnsServer.bind(this, record)} size='small' type='link'>删除</Button>
          </>
        )
      }
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.dnsServers.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchDnsServers({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchDnsServers({
      offset,
      limit
    })
  }

  handleCreateDnsServer = () => {
    this.setState({
      createDnsServerDrawerVisible: true
    })
  }

  handleCreateDnsServerDrawerClose = () => {
    this.setState({
      createDnsServerDrawerVisible: false
    })
  }

  handleCreateDnsServerComplete = () => {
    this.setState({
      createDnsServerDrawerVisible: false
    })
    this.searchDnsServers()
  }

  handleUpdateDnsServerDrawerClose = () => {
    this.setState({
      updateDnsServerDrawerVisible: false
    })
  }

  handleUpdateDnsServerComplete = () => {
    this.setState({
      updateDnsServerDrawerVisible: false
    })
    this.searchDnsServers()
  }

  handleDeleteDnsServer = (dnsServer, e) => {
    e.preventDefault()
    e.stopPropagation()

    const dnsServerId = dnsServer.id
    this.props.dispatch(removeDnsServer({dnsServerId})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('删除DNS授权成功')

        this.searchDnsServers()
      }
    })
  }

  handleClickDnsServer = (dnsServerId) => {
    this.props.history.push(`/monitor/dnsservers/${dnsServerId}`)
  }

  handleUpdateDnsServer = (dnsServer, e) => {
    e.preventDefault()
    e.stopPropagation()

    const dnsServerId = dnsServer.id
    if (dnsServer.associatedCertificatesCount > 0) {
      Modal.confirm({
        title: '确认要修改该DNS授权吗?',
        content: `${dnsServer.associatedCertificatesCount}个证书已使用该DNS授权，修改DNS授权可能会导致证书更新失败`,
        okType: 'primary',
        okButtonProps: {size: 'small'},
        cancelButtonProps: {size: 'small'},
        autoFocusButton: false,
        maskClosable: true,
        onOk: () => {
          this.setState({
            activeDnsServerId: dnsServerId,
            updateDnsServerDrawerVisible: true
          })
        },
        onCancel: () => {
        },
      })
    } else {
      this.setState({
        activeDnsServerId: dnsServerId,
        updateDnsServerDrawerVisible: true
      })
    }
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 10
    })
    this.searchDnsServers({offset: 0, limit: 10})
  }

  renderCreateDnsServerDrawer = () => {
    const {createDnsServerDrawerVisible} = this.state
    if (!createDnsServerDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='添加授权'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleCreateDnsServerDrawerClose}
          visible={this.state.createDnsServerDrawerVisible}
        >
          <CreateDnsServer onCreateComplete={this.handleCreateDnsServerComplete}/>
      </Drawer>
    )
  }

  renderUpdateDnsServerDrawer = () => {
    const {updateDnsServerDrawerVisible, activeDnsServerId} = this.state
    if (!updateDnsServerDrawerVisible) {
      return
    }

    return (
      <Drawer
          title='修改授权'
          placement='right'
          closable={false}
          width = {600}
          onClose={this.handleUpdateDnsServerDrawerClose}
          visible={this.state.updateDnsServerDrawerVisible}
        >
          <UpdateDnsServer dnsServerId={activeDnsServerId} onUpdateComplete={this.handleUpdateDnsServerComplete}/>
      </Drawer>
    )
  }

  render () {
    const {searchContent, firstLoading} = this.state
    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <Input.Search placeholder="输入DNS授权ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={searchContent} onChange={this.handleChangeSearchContent} style={{width: '450px'}}/>
          </Form.Item>
          <Form.Item>
            <Button icon='plus' onClick={this.handleCreateDnsServer}>添加授权</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          scroll={{x: true}}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.dnsServers.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
        {this.renderCreateDnsServerDrawer()}
        {this.renderUpdateDnsServerDrawer()}
      </div>
    )
  }
}

DnsServers = connect((state) => {
  return {
    dnsProviders: state.dns.provider.dnsProviders,
    dnsServers: state.dns.server.dnsServers
  }
})(DnsServers)

export default DnsServers
