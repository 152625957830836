import md5 from 'md5'
import { setReportContext } from '../../utils/report'

const SIGNUP_ING = 'AUTH/SIGNUP_ING'
const SIGNUP_SUCCESS = 'AUTH/SIGNUP_SUCCESS'
const SIGNUP_FAILURE = 'AUTH/SIGNUP_FAILURE'

const SIGNIN_ING = 'AUTH/SIGNIN_ING'
const SIGNIN_SUCCESS = 'AUTH/SIGNIN_SUCCESS'
const SIGNIN_FAILURE = 'AUTH/SIGNIN_FAILURE'

const GETME_ING = 'AUTH/GETME_ING'
const GETME_SUCCESS = 'AUTH/GETME_SUCCESS'
const GETME_FAILURE = 'AUTH/GETME_FAILURE'

const GET_MY_BEANS_ING = 'AUTH/GET_MY_BEANS_ING'
const GET_MY_BEANS_SUCCESS = 'AUTH/GET_MY_BEANS_SUCCESS'
const GET_MY_BEANS_FAILURE = 'AUTH/GET_MY_BEANS_FAILURE'

const GET_MY_ALERTS_ING = 'AUTH/GET_MY_ALERTS_ING'
const GET_MY_ALERTS_SUCCESS = 'AUTH/GET_MY_ALERTS_SUCCESS'
const GET_MY_ALERTS_FAILURE = 'AUTH/GET_MY_ALERTS_FAILURE'

const CLOSE_MY_ALERT_ING = 'AUTH/CLOSE_MY_ALERT_ING'
const CLOSE_MY_ALERT_SUCCESS = 'AUTH/CLOSE_MY_ALERT_SUCCESS'
const CLOSE_MY_ALERT_FAILURE = 'AUTH/CLOSE_MY_ALERT_FAILURE'

const GET_MY_INVITATIONS_ING = 'AUTH/GET_MY_INVITATIONS_ING'
const GET_MY_INVITATIONS_SUCCESS = 'AUTH/GET_MY_INVITATIONS_SUCCESS'
const GET_MY_INVITATIONS_FAILURE = 'AUTH/GET_MY_INVITATIONS_FAILURE'

const RECEIVE_INVITATION_GIFT_ING = 'AUTH/RECEIVE_INVITATION_GIFT_ING'
const RECEIVE_INVITATION_GIFT_SUCCESS = 'AUTH/RECEIVE_INVITATION_GIFT_SUCCESS'
const RECEIVE_INVITATION_GIFT_FAILURE = 'AUTH/RECEIVE_INVITATION_GIFT_FAILURE'

const SIGNOUT_ING = 'AUTH/SIGNOUT_ING'
const SIGNOUT_SUCCESS = 'AUTH/SIGNOUT_SUCCESS'
const SIGNOUT_FAILURE = 'AUTH/SIGNOUT_FAILURE'

const SET_PASSWORD_ING = 'AUTH/SET_PASSWORD_ING'
const SET_PASSWORD_SUCCESS = 'AUTH/SET_PASSWORD_SUCCESS'
const SET_PASSWORD_FAILURE = 'AUTH/SET_PASSWORD_FAILURE'

const INIT_PASSWORD_ING = 'AUTH/INIT_PASSWORD_ING'
const INIT_PASSWORD_SUCCESS = 'AUTH/INIT_PASSWORD_SUCCESS'
const INIT_PASSWORD_FAILURE = 'AUTH/INIT_PASSWORD_FAILURE'

const SET_EMAIL_ING = 'AUTH/SET_EMAIL_ING'
const SET_EMAIL_SUCCESS = 'AUTH/SET_EMAIL_SUCCESS'
const SET_EMAIL_FAILURE = 'AUTH/SET_EMAIL_FAILURE'

const CLOSE_ACCOUNT_ING = 'AUTH/CLOSE_ACCOUNT_ING'
const CLOSE_ACCOUNT_SUCCESS = 'AUTH/CLOSE_ACCOUNT_SUCCESS'
const CLOSE_ACCOUNT_FAILURE = 'AUTH/CLOSE_ACCOUNT_FAILURE'

const RESET_PASSWORD_ING = 'AUTH/RESET_PASSWORD_ING'
const RESET_PASSWORD_SUCCESS = 'AUTH/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILURE = 'AUTH/RESET_PASSWORD_FAILURE'

const defaultState = {
  me: null,
  signing: true,
  invitations: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIGNIN_ING:
    case SIGNUP_ING:
    case GETME_ING:
      return {
        ...state,
        signing: true
      }
    case SIGNIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case GETME_SUCCESS:
      {
        const {userId, nickName, email} = action.payload
        setReportContext({userId, nickName, email})
      }
      return {
        ...state,
        me: action.payload,
        signing: false
      }
    case SIGNIN_FAILURE:
    case SIGNUP_FAILURE:
    case GETME_FAILURE:
      return {
        ...state,
        signing: false
      }
    case SIGNOUT_SUCCESS:
    case SIGNOUT_FAILURE:
      return {
        ...state,
        me: null
      }
    case GET_MY_BEANS_SUCCESS:
      return {
        ...state,
        beans: action.payload.beans
      }
    case GET_MY_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.payload
      }
    case CLOSE_MY_ALERT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.filter(item => item.id !== action.alertId)
      }
    case GET_MY_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitations: action.payload
      }
    case GET_MY_INVITATIONS_FAILURE:
      return {
        ...state,
        invitations: {
          count: 0,
          rows: []
        }
      }
    default:
      return {...state}
  }
}

export const signup = ({email, password, verificationCode, invitationCode}) => {
  password = md5(password)
  return {
    types: [SIGNUP_ING, SIGNUP_SUCCESS, SIGNUP_FAILURE],
    promise: (apiClient) => apiClient.post('/api/auth/signup', {email, password, verificationCode, invitationCode})
  }
}

export const signin = ({email, password}) => {
  password = md5(password)
  return {
    types: [SIGNIN_ING, SIGNIN_SUCCESS, SIGNIN_FAILURE],
    promise: (apiClient) => apiClient.post('/api/auth/signin', {email, password})
  }
}

export function signout () {
  return {
    types: [SIGNOUT_ING, SIGNOUT_SUCCESS, SIGNIN_FAILURE],
    promise: (apiClient) => apiClient.post('/api/auth/signout')
  }
}

export const getMe = () => {
  return {
    types: [GETME_ING, GETME_SUCCESS, GETME_FAILURE],
    promise: (apiClient) => apiClient.get('/api/users/me')
  }
}

export const getMyBeans = () => {
  return {
    types: [GET_MY_BEANS_ING, GET_MY_BEANS_SUCCESS, GET_MY_BEANS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/users/me/beans')
  }
}

export const getMyAlerts = () => {
  return {
    types: [GET_MY_ALERTS_ING, GET_MY_ALERTS_SUCCESS, GET_MY_ALERTS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/users/me/alerts')
  }
}

export const closeMyAlert = ({alertId}) => {
  return {
    types: [CLOSE_MY_ALERT_ING, CLOSE_MY_ALERT_SUCCESS, CLOSE_MY_ALERT_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/users/me/alerts/${alertId}`),
    alertId
  }
}

export const getMyNotificationsConfig = () => {
  return {
    types: [GET_MY_ALERTS_ING, GET_MY_ALERTS_SUCCESS, GET_MY_ALERTS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/users/me/alerts')
  }
}

export const getMyInvitations = ({inviteeEmail, haveGiftNotReceive, offset, limit}) => {
  return {
    types: [GET_MY_INVITATIONS_ING, GET_MY_INVITATIONS_SUCCESS, GET_MY_INVITATIONS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/users/me/invitations', {params: {inviteeEmail, haveGiftNotReceive, offset, limit}})
  }
}

export const receiveInvitationGift = ({invitationId}) => {
  return {
    types: [RECEIVE_INVITATION_GIFT_ING, RECEIVE_INVITATION_GIFT_SUCCESS, RECEIVE_INVITATION_GIFT_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/users/me/invitations/${invitationId}`)
  }
}

export const setPassword = ({oldPassword, newPassword}) => {
  oldPassword = md5(oldPassword)
  newPassword = md5(newPassword)
  return {
    types: [SET_PASSWORD_ING, SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE],
    promise: (apiClient) => apiClient.put('/api/users/me/password', {oldPassword, newPassword})
  }
}

export const initPassword = ({password}) => {
  password = md5(password)
  return {
    types: [INIT_PASSWORD_ING, INIT_PASSWORD_SUCCESS, INIT_PASSWORD_FAILURE],
    promise: (apiClient) => apiClient.put('/api/users/me/password', {newPassword: password})
  }
}

export const setEmail = ({newEmail, oldEmailVerificationCode, newEmailVerificationCode}) => {
  return {
    types: [SET_EMAIL_ING, SET_EMAIL_SUCCESS, SET_EMAIL_FAILURE],
    promise: (apiClient) => apiClient.put('/api/users/me/email', {newEmail, oldEmailVerificationCode, newEmailVerificationCode})
  }
}

export const closeAccount = ({emailVerificationCode}) => {
  return {
    types: [CLOSE_ACCOUNT_ING, CLOSE_ACCOUNT_SUCCESS, CLOSE_ACCOUNT_FAILURE],
    promise: (apiClient) => apiClient.delete('/api/users/me/account', {data: {emailVerificationCode}})
  }
}

export const resetPwd = ({token, password}) => {
  password = md5(password)
  return {
    types: [RESET_PASSWORD_ING, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    promise: (apiClient) => apiClient.post('/api/users/me/password', {token, password})
  }
}
