import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import axios from '../utils/axios'
import auth from './auth'
import oauth from './oauth'
import certificate from './certificate'
import certificateVersion from './certificate/version'
import certificatePush from './certificate/push'
import dnsServer from './dns/server'
import dnsProvider from './dns/provider'
import cloudServer from './cloud/server'
import cloudProvider from './cloud/provider'
import notificationProvider from './notification/provider'
import notificationTarget from './notification/target'
import notificationConfig from './notification/config'
import statistics from './statistics'
import message from './message'
import bean from './bean'
import common from './common'
import watch from './watch'
import watchRecord from './watch/record'

const rootReducer = combineReducers({
  auth,
  oauth,
  certificate: combineReducers({
    certificate,
    push: certificatePush,
    version: certificateVersion
  }),
  dns: combineReducers({
    server: dnsServer,
    provider: dnsProvider
  }),
  cloud: combineReducers({
    server: cloudServer,
    provider: cloudProvider
  }),
  watch: combineReducers({
    watch,
    record: watchRecord
  }),
  notification: combineReducers({
    config: notificationConfig,
    provider: notificationProvider,
    target: notificationTarget
  }),
  statistics,
  message,
  bean,
  common
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const fetch = (apiClient) => {
  return ({dispatch, getState}) => {
    return (next) => {
      return (action) => {
        if (typeof action === 'function') {
          return action(dispatch, getState)
        }
  
        const { promise, types, ...rest } = action
        if (!promise) {
          return next(action)
        }
  
        const [REQUEST, SUCCESS, FAILURE] = types
        next({...rest, type: REQUEST})
        const actionPromise = promise(apiClient)
        actionPromise.then(response => {
          next({...rest, payload: response.payload, type: SUCCESS})
        }).catch(error => {
          next({...rest, error, type: FAILURE})
        })
        return actionPromise
      }
    }
  }
}

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, fetch(axios))
  )
)

export default store
