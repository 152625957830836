import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Modal, Table, Form, Checkbox, Button, Input, message} from 'antd'
import CertificateType from './CertificateType'
import CertificateDomains from './CertificateDomains'
import { getCertificates } from '../redux/certificate'

class SelectCertificatesModal extends React.Component {
  static props = {
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      selectedCertificates: [],
      current: 1,
      offset: 0,
      limit: 5,
      searchContent: ''
    }

    this.searchCertificates()
  }

  handleModalOk = () => {
    const {onOk} = this.props
    const {selectedCertificates} = this.state
    onOk(selectedCertificates)

    this.setState({
      selectedCertificates: []
    })
  }

  handleModalCancel = () => {
    const {onCancel} = this.props
    onCancel()

    this.setState({
      selectedCertificates: []
    })
  }

  searchCertificates = (state) => {
    const {searchContent, offset, limit} = Object.assign({}, this.state, state)
    this.props.dispatch(getCertificates({searchContent, offset, limit}))
  }

  handleClickCertificate = (certificateId) => {
    window.open(`/monitor/certificates/${certificateId}`, '_blank')
  }

  get tableColumns () {
    return [{
      title: '选中',
      dataIndex: 'checked',
      width: 50,
      render: (text, record) => {
        return (
          <>
            <Checkbox checked={record.isValid ? record.checked : false} disabled={!record.isValid}/>
          </>
        )
      }
    }, {
      title: '证书ID/名称',
      dataIndex: 'id',
      width: 220,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            <Button type='link' onClick={this.handleClickCertificate.bind(this, record.id)} style={{paddingLeft: '0px', paddingRight: '0px'}}>{record.name}</Button>
          </>
        )
      }
    }, {
      title: '类型',
      dataIndex: 'type',
      width: 70,
      render: (text, record) => {
        return <CertificateType type={record.type}/>
      }
    }, {
      title: '关联域名',
      dataIndex: 'domains',
      width: 250,
      render: (text, record) => {
        return <CertificateDomains domains={record.domains} onlyFirst={true} maxWidth={235}/>
      }
    }, {
      title: '状态',
      dataIndex: 'expiredTime',
      width: 70,
      render: (text, record) => {
        if (!record.expiredTime) {
          return '-'
        }

        if (new Date(record.expiredTime).getTime() <= Date.now()) {
          return (
            <div>
              <span style={{color: 'red'}}>已过期</span>
            </div>
          )
        } else {
          return (
            <div>
              <span style={{color: 'green'}}>生效中</span>
            </div>
          )
        }
      }
    }]
  }

  get tableData () {
    const selectedCertificateIdsSet = new Set(this.state.selectedCertificates.map(item => item.id))
    return [...(new Array(this.state.offset)), ...this.props.certificates.rows.map((item, index) => {
      item.key = item.id
      item.checked = selectedCertificateIdsSet.has(item.id)
      item.isValid = item.expiredTime && (new Date(item.expiredTime).getTime() > Date.now())
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchCertificates({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchCertificates({
      offset,
      limit
    })
  }

  handleChangeSearchContent = (e) => {
    this.setState({
      searchContent: e.target.value
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 5
    })
    this.searchCertificates({offset: 0, limit: 5})
  }

  handleClickRow = (row) => {
    if (!row.isValid) {
      return message.warn('只能选择生效中状态的证书')
    }

    const {selectedCertificates} = this.state
    if (selectedCertificates.indexOf(row) >= 0) {
      this.setState({
        selectedCertificates: selectedCertificates.filter(item => item.id !== row.id)
      })
    } else {
      this.setState({
        selectedCertificates: [...selectedCertificates, row]
      })
    }
  }

  render () {
    const {visible} = this.props

    return (
      <Modal
        title={'请选择证书'}
        visible={visible}        
        okType='primary'
        okButtonProps={{size: 'small'}}
        cancelButtonProps={{size: 'small'}}
        onOk={this.handleModalOk}
        onCancel={this.handleModalCancel}
        width={720}
      >
        <Form layout='inline'>
          <Form.Item>
            <Input.Search placeholder="输入关联域名或证书ID/名称搜索" onSearch={this.handleClickSearch} enterButton value={this.state.searchContent} onChange={this.handleChangeSearchContent} style={{width: '680px'}}/>
          </Form.Item>
        </Form>
        <br/>
        <Table
          columns={this.tableColumns}
          dataSource={this.tableData}
          size='small'
          onRow={(record) => {
            return {
              onClick: () => {
                this.handleClickRow(record)
              } 
            }
          }}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.certificates.count,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
      </Modal>
    )
  }
}

SelectCertificatesModal = connect((state) => {
  return {
    certificates: state.certificate.certificate.certificates
  }
})(SelectCertificatesModal)

export default SelectCertificatesModal
