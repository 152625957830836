import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditLabel from './EditLabel'
import {changeCloudServerAlias} from '../redux/cloud/server'

class CloudServerAlias extends React.Component {
  static props = {
    showIcon: PropTypes.bool,
    cloudServerId: PropTypes.number,
    cloudServerAlias: PropTypes.string,
    onChangeComplete: PropTypes.func
  }

  render() {
    const {showIcon, cloudServerId, cloudServerAlias, onChangeComplete} = this.props

    return (
      <EditLabel 
        title='编辑部署节点名称'
        minLength={2}
        maxLength={256}
        showIcon={showIcon}
        value={cloudServerAlias}
        onChangeDispatch={changeCloudServerAlias.bind(this, cloudServerId)}
        onChangeComplete={onChangeComplete}
      />
    )
  }
}

CloudServerAlias = connect()(CloudServerAlias)

export default CloudServerAlias
