import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Icon, Button, Divider, Tooltip} from 'antd'
import moment from 'moment'
import DnsServerAlias from '../../components/DnsServerAlias'
import ConfigField from '../../components/ConfigField'
import {getDnsServerDetail} from '../../redux/dns/server'

class DnsServerDetail extends React.Component {
  constructor (props) {
    super(props)

    this.searchDnsServerDetail()
  }

  searchDnsServerDetail = () => {
    const dnsServerId = this.dnsServerId
    this.props.dispatch(getDnsServerDetail({dnsServerId}))
  }

  get dnsServerId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  render () {
    const {dnsServerDetail} = this.props
    if (!dnsServerDetail) {
      return null
    }
    const { fields } = dnsServerDetail.dnsProviderConfigJson
    return (
      <div className='detail'>
        <p className='header'><Icon type='global'/>{dnsServerDetail.name}</p>
        <Row>
          <Col span={2}>授权名称：</Col>
          <Col span={10}><DnsServerAlias key={dnsServerDetail.id} showIcon  dnsServerId={dnsServerDetail.id} dnsServerAlias={dnsServerDetail.alias || dnsServerDetail.name} onChangeComplete={this.searchDnsServerDetail}/></Col>
          <Col span={2}>创建时间：</Col>
          <Col span={10}>{moment(dnsServerDetail.createTime).format('YYYY-MM-DD HH:mm')}</Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col span={2}>授权类型：</Col>
          <Col span={22}>
            <img src={dnsServerDetail.dnsProviderLogo} alt='' width={15} height={15}/>&nbsp;{dnsServerDetail.dnsProviderName}
          </Col>
        </Row>
        <Row type='flex' align='middle' style={{marginTop: '10px', display: 'flex', alignItems: 'self-start'}}>
          <Col span={2}>关联证书：</Col>
          <Col span={22} style={{display: 'flex', flexWrap: 'wrap'}}>
            {dnsServerDetail.associatedCertificates.length ? dnsServerDetail.associatedCertificates.map(item => {
              return (
                <Tooltip key={item.name} title={item.alias || item.name} placement="bottomLeft">
                  <Button type="link" style={{paddingLeft: 0, height: 23}} onClick={() => this.props.history.push(`/monitor/certificates/${item.id}`)}>
                    <span style={{display: 'inline-block', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      {item.alias || item.name}
                    </span>
                  </Button>
                </Tooltip>
              )
            }) : '-'}
          </Col>
        </Row>
        <div style={{width: '600px'}}>
          <Divider>DNS授权参数</Divider>
          {fields.map((item, index) => {
            const value = dnsServerDetail.accessJson[item.field]
            return (
              <Row style={{ marginTop: '10px' }} key={index}>
                <Col span={6}>
                  {item.name}
                </Col>
                <Col span={18}>
                  <ConfigField {...item} value={value} editable={false}/>
                </Col>
              </Row>
            )
          })}
        </div>
      </div>
    )
  }
}

DnsServerDetail = connect((state) => {
  return {
    dnsServerDetail: state.dns.server.dnsServerDetail
  }
})(DnsServerDetail)

export default DnsServerDetail
