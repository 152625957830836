const GET_NOTIFICATION_TARGETES_ING = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGETES_ING'
const GET_NOTIFICATION_TARGETES_SUCCESS = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGETES_SUCCESS'
const GET_NOTIFICATION_TARGETES_FAILURE = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGETES_FAILURE'

const GET_NOTIFICATION_TARGET_DETAIL_ING = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGET_DETAIL_ING'
const GET_NOTIFICATION_TARGET_DETAIL_SUCCESS = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGET_DETAIL_SUCCESS'
const GET_NOTIFICATION_TARGET_DETAIL_FAILURE = 'NOTIFICATION/TARGET/GET_NOTIFICATION_TARGET_DETAIL_FAILURE'

const CREATE_NOTIFICATION_TARGET_ING = 'NOTIFICATION/TARGET/CREATE_NOTIFICATION_TARGET_ING'
const CREATE_NOTIFICATION_TARGET_SUCCESS = 'NOTIFICATION/TARGET/CREATE_NOTIFICATION_TARGET_SUCCESS'
const CREATE_NOTIFICATION_TARGET_FAILURE = 'NOTIFICATION/TARGET/CREATE_NOTIFICATION_TARGET_FAILURE'

const REMOVE_NOTIFICATION_TARGET_ING = 'NOTIFICATION/TARGET/REMOVE_NOTIFICATION_TARGET_ING'
const REMOVE_NOTIFICATION_TARGET_SUCCESS = 'NOTIFICATION/TARGET/REMOVE_NOTIFICATION_TARGET_SUCCESS'
const REMOVE_NOTIFICATION_TARGET_FAILURE = 'NOTIFICATION/TARGET/REMOVE_NOTIFICATION_TARGET_FAILURE'

const CHANGE_NOTIFICATION_TARGET_ALIAS_ING = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ALIAS_ING'
const CHANGE_NOTIFICATION_TARGET_ALIAS_SUCCESS = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ALIAS_SUCCESS'
const CHANGE_NOTIFICATION_TARGET_ALIAS_FAILURE = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ALIAS_FAILURE'

const CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_ING = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_ING'
const CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_SUCCESS = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_SUCCESS'
const CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_FAILURE = 'NOTIFICATION/TARGET/CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_FAILURE'

const defaultState = {
  notificationTargets: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_TARGETES_SUCCESS:
      return {
        ...state,
        notificationTargets: action.payload
      }
    case GET_NOTIFICATION_TARGETES_FAILURE:
      return {
        ...state,
        notificationTargets: {
          count: 0,
          rows: []
        }
      }
    case GET_NOTIFICATION_TARGET_DETAIL_SUCCESS:
      return {
        ...state,
        notificationTargetDetail: action.payload
      }
    case GET_NOTIFICATION_TARGET_DETAIL_FAILURE:
      return {
        ...state,
        notificationTargetDetail: null
      }
    default:
      return { ...state }
  }
}

export const getNotificationTargets = ({ searchContent, offset, limit, sortKey, sortOrder }) => {
  return {
    types: [GET_NOTIFICATION_TARGETES_ING, GET_NOTIFICATION_TARGETES_SUCCESS, GET_NOTIFICATION_TARGETES_FAILURE],
    promise: (apiClient) => apiClient.get('/api/notifications/targets', { params: { searchContent, offset, limit, sortKey, sortOrder } }),
    offset,
    limit
  }
}

export const getNotificationTargetDetail = ({ notificationTargetId }) => {
  return {
    types: [GET_NOTIFICATION_TARGET_DETAIL_ING, GET_NOTIFICATION_TARGET_DETAIL_SUCCESS, GET_NOTIFICATION_TARGET_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/notifications/targets/${notificationTargetId}`),
    notificationTargetId
  }
}

export const createNotificationTarget = ({ notificationProviderId, accessJson }) => {
  return {
    types: [CREATE_NOTIFICATION_TARGET_ING, CREATE_NOTIFICATION_TARGET_SUCCESS, CREATE_NOTIFICATION_TARGET_FAILURE],
    promise: (apiClient) => apiClient.post('/api/notifications/targets', {notificationProviderId, accessJson})
  }
}

export const removeNotificationTarget = ({notificationTargetId}) => {
  return {
    types: [REMOVE_NOTIFICATION_TARGET_ING, REMOVE_NOTIFICATION_TARGET_SUCCESS, REMOVE_NOTIFICATION_TARGET_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/notifications/targets/${notificationTargetId}`)
  }
}

export const changeNotificationTargetAlias = (notificationTargetId, alias) => {
  return {
    types: [CHANGE_NOTIFICATION_TARGET_ALIAS_ING, CHANGE_NOTIFICATION_TARGET_ALIAS_SUCCESS, CHANGE_NOTIFICATION_TARGET_ALIAS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/notifications/targets/${notificationTargetId}/alias`, {alias})
  }
}

export const changeNotificationTargetAccessJson = ({notificationTargetId, accessJson}) => {
  return {
    types: [CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_ING, CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_SUCCESS, CHANGE_NOTIFICATION_TARGET_ACCESS_JSON_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/notifications/targets/${notificationTargetId}/accessJson`, {accessJson})
  }
}
