import React from 'react'
import PropTypes from 'prop-types'

class CertificateDomains extends React.Component {
  static props = {
    domains: PropTypes.array,
    onlyFirst: PropTypes.bool,
    maxWidth: PropTypes.number
  }

  render () {
    const {domains, onlyFirst, maxWidth} = this.props

    if (onlyFirst) {
      return (
        <div style={{display: 'flex', height: '20px', lineHeight: '20px'}}>
          <a style={{maxWidth: maxWidth - 20, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} className='domain-tag' href={`https://${domains[0].replace(/^\*\./, '')}`} target='_blank' rel='noopener noreferrer'>
            {domains[0]}
          </a>
          <span style={{color: '#f50', border: '1px solid', padding: '0 3px'}}>{domains.length}</span> 
        </div>
      )
    } else {
      return (
        <div style={{maxWidth: maxWidth}}>
          {domains.map(item => <a key={item} className='domain-tag' href={`https://${item.replace(/^\*\./, '')}`} target='_blank' rel='noopener noreferrer'>{item}</a>)}
        </div>
      )
    }
  }
}

export default CertificateDomains
