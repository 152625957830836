import React from 'react'
import PropTypes from 'prop-types'
import {Input, Select} from 'antd'
import CodeEditor from '@uiw/react-textarea-code-editor'
import md5 from 'md5'
import { CONFIG_FIELD_TYPE } from '../utils/constant'
import "@uiw/react-textarea-code-editor/dist.css"

class ConfigField extends React.Component {
  static props = {
    field: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      value: nextProps.value
    })
  }

  handleOnChange = (field, eOrValue) => {
    const {type, editable, onChange} = this.props
    if (!editable) {
      return
    }

    let value
    switch (type) {
      case CONFIG_FIELD_TYPE.INPUT:
      case CONFIG_FIELD_TYPE.PASSWORD:
        case CONFIG_FIELD_TYPE.CODE:
        value = eOrValue.target.value
        break
      case CONFIG_FIELD_TYPE.SELECT:
        value = eOrValue
        break
      case CONFIG_FIELD_TYPE.RANDOM:
        value = typeof eOrValue ==='string' ? eOrValue : eOrValue.target.value
        break
      default:
        return
    }

    this.setState({
      value
    })
    onChange(field, value)
  }

  render () {
    const {field, name, type, disabled, editable, nullable, options, language, placeholder} = this.props
    const {value} = this.state

    switch (type) {
      case CONFIG_FIELD_TYPE.INPUT:
        return (
          <Input value={value} placeholder={placeholder || `请输入${name}${nullable ? ', 非必填' : ''}`} onChange={this.handleOnChange.bind(this, field)} disabled={disabled}/>
        )
      case CONFIG_FIELD_TYPE.PASSWORD:
        return (
          <Input.Password value={value} placeholder={placeholder || `请输入${name}${nullable ? ', 非必填' : ''}`} onChange={this.handleOnChange.bind(this, field)} disabled={disabled}/>
        )
      case CONFIG_FIELD_TYPE.SELECT:
        return (
          <Select
            allowClear
            value={value}
            placeholder={placeholder || `请选择${name}${nullable ? ', 非必填' : ''}`}
            onChange={this.handleOnChange.bind(this, field)}
            style={{ width: '100%' }}
            disabled={disabled}
          >
            {options.map((item, index) => {
              return (
                <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
              )
            })}
          </Select>
        )
      case CONFIG_FIELD_TYPE.RANDOM:
        if (editable) {
          return  (
            <Input.Search
              value={value}
              enterButton='点击生成'
              disabled={disabled}
              maxLength={32}
              placeholder={placeholder || `请点击生成32位${name}`} 
              onChange={this.handleOnChange.bind(this, field)}
              onSearch={this.handleOnChange.bind(this, field, md5(Date.now()))}/>
          )
        } else {
          return (
            <Input value={value}/>
          )
        }
      case CONFIG_FIELD_TYPE.CODE:
        return  (
          <CodeEditor
            value={value}
            language={language}
            readOnly={disabled || !editable}
            disabled={disabled || !editable}
            placeholder={placeholder || `请输入${name}${nullable ? ', 非必填' : ''}`}
            onChange={this.handleOnChange.bind(this, field)}
            padding={15}
            style={{
              backgroundColor: "#f5f5f5"
            }}
          />
        )
      default:
        return (<></>)
    }
  }
}

export default ConfigField
