import React from 'react'
import PropTypes from 'prop-types'

class MessageDetail extends React.Component {
  props = {
    messageDetail: PropTypes.object
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  getMessageHtml = () => {
    const { messageDetail } = this.props
    return {__html: messageDetail.html}
  }

  render() {
    
    return (
      <div dangerouslySetInnerHTML={this.getMessageHtml()} />
    )
  }
}

export default MessageDetail
