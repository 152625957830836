const GET_CLOUD_SERVERS_ING = 'CLOUD/SERVER/GET_CLOUD_SERVERS_ING'
const GET_CLOUD_SERVERS_SUCCESS = 'CLOUD/SERVER/GET_CLOUD_SERVERS_SUCCESS'
const GET_CLOUD_SERVERS_FAILURE = 'CLOUD/SERVER/GET_CLOUD_SERVERS_FAILURE'

const GET_CLOUD_SERVER_DETAIL_ING = 'CLOUD/SERVER/GET_CLOUD_SERVER_DETAIL_ING'
const GET_CLOUD_SERVER_DETAIL_SUCCESS = 'CLOUD/SERVER/GET_CLOUD_SERVER_DETAIL_SUCCESS'
const GET_CLOUD_SERVER_DETAIL_FAILURE = 'CLOUD/SERVER/GET_CLOUD_SERVER_DETAIL_FAILURE'

const GET_CLOUD_SERVER_PUSHES_ING = 'CLOUD/SERVER/GET_CLOUD_SERVER_PUSHES_ING'
const GET_CLOUD_SERVER_PUSHES_SUCCESS = 'CLOUD/SERVER/GET_CLOUD_SERVER_PUSHES_SUCCESS'
const GET_CLOUD_SERVER_PUSHES_FAILURE = 'CLOUD/SERVER/GET_CLOUD_SERVER_PUSHES_FAILURE'

const CREATE_CLOUD_SERVER_PUSHES_ING = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_PUSHES_ING'
const CREATE_CLOUD_SERVER_PUSHES_SUCCESS = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_PUSHES_SUCCESS'
const CREATE_CLOUD_SERVER_PUSHES_FAILURE = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_PUSHES_FAILURE'

const CREATE_CLOUD_SERVER_ING = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_ING'
const CREATE_CLOUD_SERVER_SUCCESS = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_SUCCESS'
const CREATE_CLOUD_SERVER_FAILURE = 'CLOUD/SERVER/CREATE_CLOUD_SERVER_FAILURE'

const REMOVE_CLOUD_SERVER_ING = 'CLOUD/SERVER/REMOVE_CLOUD_SERVER_ING'
const REMOVE_CLOUD_SERVER_SUCCESS = 'CLOUD/SERVER/REMOVE_CLOUD_SERVER_SUCCESS'
const REMOVE_CLOUD_SERVER_FAILURE = 'CLOUD/SERVER/REMOVE_CLOUD_SERVER_FAILURE'

const CHANGE_CLOUD_SERVER_ALIAS_ING = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ALIAS_ING'
const CHANGE_CLOUD_SERVER_ALIAS_SUCCESS = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ALIAS_SUCCESS'
const CHANGE_CLOUD_SERVER_ALIAS_FAILURE = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ALIAS_FAILURE'

const CHANGE_CLOUD_SERVER_ACCESS_JSON_ING = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ACCESS_JSON_ING'
const CHANGE_CLOUD_SERVER_ACCESS_JSON_SUCCESS = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ACCESS_JSON_SUCCESS'
const CHANGE_CLOUD_SERVER_ACCESS_JSON_FAILURE = 'CLOUD/SERVER/CHANGE_CLOUD_SERVER_ACCESS_JSON_FAILURE'

const defaultState = {
  cloudServers: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CLOUD_SERVERS_SUCCESS:
      return {
        ...state,
        cloudServers: action.payload
      }
    case GET_CLOUD_SERVERS_FAILURE:
      return {
        ...state,
        cloudServers: {
          count: 0,
          rows: []
        }
      }
    case GET_CLOUD_SERVER_DETAIL_SUCCESS:
      return {
        ...state,
        cloudServerDetail: action.payload
      }
    case GET_CLOUD_SERVER_DETAIL_FAILURE:
      return {
        ...state,
        cloudServerDetail: null
      }
    case GET_CLOUD_SERVER_PUSHES_SUCCESS:
      return {
        ...state,
        [`pushes_${action.cloudServerId}`]: action.payload
      }
    case GET_CLOUD_SERVER_PUSHES_FAILURE:
      return {
        ...state,
        [`pushes_${action.cloudServerId}`]: null
      }
    default:
      return {...state}
  }
}

export const createCloudServer = ({cloudProviderId, accessJson, attachedCertificateIds, sourceCloudServerId}) => {
  return {
    types: [CREATE_CLOUD_SERVER_ING, CREATE_CLOUD_SERVER_SUCCESS, CREATE_CLOUD_SERVER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/cloud/servers', {cloudProviderId, accessJson, attachedCertificateIds, sourceCloudServerId})
  }
}

export const getCloudServers = ({searchContent, offset, limit}) => {
  return {
    types: [GET_CLOUD_SERVERS_ING, GET_CLOUD_SERVERS_SUCCESS, GET_CLOUD_SERVERS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/cloud/servers', {params: {searchContent, offset, limit}})
  }
}

export const getCloudServerDetail = ({cloudServerId}) => {
  return {
    types: [GET_CLOUD_SERVER_DETAIL_ING, GET_CLOUD_SERVER_DETAIL_SUCCESS, GET_CLOUD_SERVER_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/cloud/servers/${cloudServerId}`)
  }
}

export const getCloudServerPushesOfCloudServer = ({cloudServerId, offset, limit}) => {
  return {
    types: [GET_CLOUD_SERVER_PUSHES_ING, GET_CLOUD_SERVER_PUSHES_SUCCESS, GET_CLOUD_SERVER_PUSHES_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/cloud/servers/${cloudServerId}/pushes`, {params: {offset, limit}}),
    cloudServerId
  }
}

export const createCertificatePushesOfCloudServer = ({cloudServerId}) => {
  return {
    types: [CREATE_CLOUD_SERVER_PUSHES_ING, CREATE_CLOUD_SERVER_PUSHES_SUCCESS, CREATE_CLOUD_SERVER_PUSHES_FAILURE],
    promise: (apiClient) => apiClient.post(`/api/cloud/servers/${cloudServerId}/pushes`),
    cloudServerId
  }
}

export const changeCloudServerAlias = (cloudServerId, alias) => {
  return {
    types: [CHANGE_CLOUD_SERVER_ALIAS_ING, CHANGE_CLOUD_SERVER_ALIAS_SUCCESS, CHANGE_CLOUD_SERVER_ALIAS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/cloud/servers/${cloudServerId}/alias`, {alias})
  }
}

export const changeCloudServerAccessJson = ({cloudServerId, accessJson}) => {
  return {
    types: [CHANGE_CLOUD_SERVER_ACCESS_JSON_ING, CHANGE_CLOUD_SERVER_ACCESS_JSON_SUCCESS, CHANGE_CLOUD_SERVER_ACCESS_JSON_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/cloud/servers/${cloudServerId}/accessJson`, {accessJson})
  }
}

export const removeCloudServer = ({cloudServerId}) => {
  return {
    types: [REMOVE_CLOUD_SERVER_ING, REMOVE_CLOUD_SERVER_SUCCESS, REMOVE_CLOUD_SERVER_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/cloud/servers/${cloudServerId}`)
  }
}
