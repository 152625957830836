import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Icon, Button, message } from 'antd'
import JustifyLabel from './JustifyLabel'
import { initPassword } from '../redux/auth'

class InitPwd extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitState()
  }

  getInitState = () => {
    return {
      password: '',
      rePassword: ''
    }
  }

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleChangeRePassword = (e) => {
    this.setState({
      rePassword: e.target.value
    })
  }

  handleClickInitPassword = () => {
    const {password, rePassword} = this.state
    if (!password) {
      return message.error('请输入密码')
    }
    if (!rePassword) {
      return message.error('请确认密码')
    }
    if (password !== rePassword) {
      return message.error('两次输入的密码不一致')
    }
    this.props.dispatch(initPassword({password})).then((result) => {
      if (result.errorMsg) {
        return message.error(result.errorMsg)
      } else {
        message.success('密码设置成功')
        this.props.onCompleteInitPassword()
        this.setState(this.getInitState())
      }
    }).catch((error) => {
      return message.error(`未知异常:${error}`)
    })
  }

  render () {
    return (
      <>
        <Form>
          <Form.Item>
            <Input.Password
              addonBefore={<JustifyLabel content='密码' width={70}/>}
              prefix={<Icon type='lock' />}
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              addonBefore={<JustifyLabel content='确认密码' width={70}/>}
              prefix={<Icon type='lock' />}
              value={this.state.rePassword}
              onChange={this.handleChangeRePassword}
            />
          </Form.Item>
          <Button type='primary' block onClick={this.handleClickInitPassword}>设置密码</Button>
        </Form>
      </>
    )
  }
}

InitPwd = connect()(InitPwd)

export default InitPwd
