import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, Icon, Input, Button, message, Divider} from 'antd'
import moment from 'moment'
import {getWatchRecordDetail} from '../../redux/watch/record'

class WatchRecord extends React.Component {
  constructor (props) {
    super(props)

    this.searchWatchRecordDetail()
  }

  searchWatchRecordDetail = () => {
    this.props.dispatch(getWatchRecordDetail({watchId: this.watchId, watchRecordId: this.watchRecordId}))
  }

  get watchId () {
    return this.props.location.pathname.split('/').slice(-3, -2)[0]
  }

  get watchRecordId () {
    return this.props.location.pathname.split('/').slice(-1)[0]
  }

  handleCopySuccess = (file) => {
    message.success(`证书文件 ${file} 复制成功`)
  }

  render () {
    const {watchRecordDetail} = this.props
    if (!watchRecordDetail) {
      return null
    }

    return (
      <div className='detail'>
        <div>
          <p className='header'><Icon type="eye" /><Button type='link' onClick={() => {this.props.history.push(`/monitor/watches/${watchRecordDetail.watchId}`)}}>{watchRecordDetail.watch.name}</Button>@{moment(watchRecordDetail.createTime).format('YYYY-MM-DD HH:mm')}</p>
          {watchRecordDetail.error ?
            <>
              <Row type='flex' align='middle' style={{marginTop: '10px'}}>
                <Col span={2}>失败信息：</Col>
                <Col span={22}>{watchRecordDetail.error}</Col>
              </Row>
            </> :
            <>
              <div>
                <Divider orientation="left"><b>主题信息</b></Divider>
                {watchRecordDetail.certificate.subject.CN ?
                  <Row>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>通用名称(CN):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.CN}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.subject.O ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>组织(O):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.O}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.subject.OU ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>部门(OU):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.OU}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.subject.L ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>城市(L):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.L}</Col>
                  </Row> 
                  : ''}
                {watchRecordDetail.certificate.subject.ST ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>省份(ST):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.ST}</Col> 
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.subject.C ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>国家(C):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.subject.C}</Col>
                  </Row>
                  : ''}
                <Divider orientation="left"><b>签发者信息</b></Divider>
                {watchRecordDetail.certificate.issuer.CN ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>通用名称(CN):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.CN}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.issuer.O ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>组织(O):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.O}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.issuer.OU ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>部门(OU):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.OU}</Col>
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.issuer.L ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>城市(L):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.L}</Col>
                  </Row> 
                  : ''}
                {watchRecordDetail.certificate.issuer.ST ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>省份(ST):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.ST}</Col> 
                  </Row>
                  : ''}
                {watchRecordDetail.certificate.issuer.C ?
                  <Row style={{marginTop: '5px'}}>
                    <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>国家(C):</Col>
                    <Col span={15}>{watchRecordDetail.certificate.issuer.C}</Col>
                  </Row>
                  : ''}
                <Divider orientation="left"><b>证书信息</b></Divider>
                <Row>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>序列号:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.serialNumber}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>信任状态:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.trusted ? <span style={{background: 'green', color: 'white'}}>&nbsp;可信&nbsp;</span> : <span style={{background: 'orange', color: 'white'}}>&nbsp;不可信&nbsp;</span>}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>加密算法:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.asn1Curve || watchRecordDetail.certificate.nistCurve ?  'ECDSA' : 'RSA'}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>私钥长度:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.bits}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>SHA1指纹:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.fingerprint}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>SHA256指纹:</Col>
                  <Col span={15}>{watchRecordDetail.certificate.fingerprint256}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>颁发日期:</Col>
                  <Col span={15}>{moment(watchRecordDetail.certificate.valid_from).format('YYYY-MM-DD HH:mm:ss')}</Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>截止日期:</Col>
                  <Col span={15}>{moment(watchRecordDetail.certificate.valid_to).format('YYYY-MM-DD HH:mm:ss')}</Col>
                </Row>
                {
                  watchRecordDetail.certificate.subjectaltname.split(',').map(item => item.replace('DNS:', '').trim()).map(item => {
                    return (
                      <Row style={{marginTop: '5px'}} key={item}>
                        <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>DNS名称:</Col>
                        <Col span={15}>{item}</Col>
                      </Row>
                    )
                  })
                }
                <Row style={{marginTop: '5px'}}>
                  <Col span={3} style={{textAlign: 'right', paddingRight: '10px'}}>证书文件:</Col>
                  <Col span={15}><Input.TextArea value={watchRecordDetail.certificate.certificateInPem} autoSize style={{width: 800}}/></Col>
                </Row>
              </div>
            </>}
        </div>
      </div>
    )
  }
}

WatchRecord = connect ((state) => {
  return {
    watchRecordDetail: state.watch.record.watchRecordDetail
  }
})(WatchRecord)

export default WatchRecord
