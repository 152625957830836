const GET_CLOUD_PROVIDERS_ING = 'CLOUD/PROVIDER/GET_CLOUD_PROVIDERS_ING'
const GET_CLOUD_PROVIDERS_SUCCESS = 'CLOUD/PROVIDER/GET_CLOUD_PROVIDERS_SUCCESS'
const GET_CLOUD_PROVIDERS_FAILURE = 'CLOUD/PROVIDER/GET_CLOUD_PROVIDERS_FAILURE'

const defaultState = {
  cloudProviders: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CLOUD_PROVIDERS_SUCCESS:
      return {
        ...state,
        cloudProviders: action.payload
      }
    case GET_CLOUD_PROVIDERS_ING:
    case GET_CLOUD_PROVIDERS_FAILURE:
      return {
        ...state,
        cloudProviders: []
      }
    default:
      return {...state}
  }
}

export const getCloudProviders = () => {
  return {
    types: [GET_CLOUD_PROVIDERS_ING, GET_CLOUD_PROVIDERS_SUCCESS, GET_CLOUD_PROVIDERS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/cloud/providers')
  }
}
