import axios from 'axios'
import { ERRORS } from './constant';

const makeFormData = (params) => {
  const form = new window.FormData()
  for (const key of Object.keys(params)) {
    form.append(key, params[key])
  }
  return form
}

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('TOKEN')
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`
  }

  if (config.contentType === 'form-data') {
    config.data = makeFormData(config.data)
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  const token = res.headers['x-user-token']
  if (token) {
    localStorage.setItem('TOKEN', token)
  }

  if (res.data.errorCode === ERRORS.CM0004) {
    localStorage.removeItem('TOKEN')
    window.location.href = '/signin'
  }

  return res.data
}, (error) => {
  if (error.response) {
    return Promise.reject(`${error.response.status}:${error.response.statusText}`)
  } else {
    window.sentry.addBreadcrumb({
      category: 'xhr-unknown-exception',
      message: `${error}`,
      level: 'error'
    });
    return Promise.reject('服务器处理异常，请稍后重试')
  }
})

export default axios
