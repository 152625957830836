import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import querystring from 'query-string'
import { Form, Table, Button, DatePicker, Checkbox, Drawer } from 'antd'
import { getBeanTransactions } from '../../redux/bean'
import BeansConsumeRules from '../../components/BeansConsumeRules'
import Charge from '../../components/Charge'
import { getMyBeans } from '../../redux/auth'

class BeanTransactions extends React.Component {
  constructor (props) {
    super(props)
    let {beginTime, endTime, offset, limit, showCharge, showConsume, showGift, showRefund} = querystring.parse(this.props.location.search)
    beginTime = beginTime || null
    endTime = endTime || null
    offset = parseInt(offset || 0)
    limit = parseInt(limit || 10)
    showCharge = showCharge !== 'false'
    showConsume = showConsume !== 'false'
    showGift = showGift !== 'false'
    showRefund = showRefund !== 'false'

    const current = parseInt(offset / limit) + 1

    this.state = {
      firstLoading: true,
      beginTime,
      endTime,
      current,
      offset,
      limit,
      showCharge,
      showConsume,
      showGift,
      showRefund,
      chargeDrawerVisible: false,
      beansConsumeRulesDrawerVisible: false
    }
    this.searchBeanTransactions()
    this.props.dispatch(getMyBeans())
  }

  componentDidMount () {
    if (window) {
      window.onkeypress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
          this.searchBeanTransactions()
        }
      }
    }
  }

  componentWillUnmount () {
    if (window) {
      window.onkeypress = null
    }
  }

  refreshUrl = ({beginTime, endTime, offset, limit, showCharge, showConsume, showGift, showRefund}) => {
    this.props.history.replace(`${this.props.location.pathname}?beginTime=${encodeURIComponent(beginTime ? moment(beginTime).format('YYYY-MM-DD') : '')}` +
                                                                `&endTime=${encodeURIComponent(endTime ? moment(endTime).format('YYYY-MM-DD') : '')}` +
                                                                `&showCharge=${encodeURIComponent(showCharge)}` +
                                                                `&showConsume=${encodeURIComponent(showConsume)}` +
                                                                `&showGift=${encodeURIComponent(showGift)}` +
                                                                `&showRefund=${encodeURIComponent(showRefund)}` +
                                                                `&offset=${encodeURIComponent(offset)}` +
                                                                `&limit=${encodeURIComponent(limit)}`)
  }

  searchBeanTransactions = (state) => {
    const {beginTime, endTime, offset, limit, showCharge, showConsume, showGift, showRefund} = Object.assign({}, this.state, state)

    this.refreshUrl({beginTime, endTime, offset, limit, showCharge, showConsume, showGift, showRefund})

    this.props.dispatch(getBeanTransactions({
      beginTime: beginTime ? moment(beginTime).format('YYYY-MM-DD') : null, 
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : null,
      showCharge,
      showConsume,
      showGift,
      showRefund,
      offset,
      limit
    })).then(() => {
      this.setState({
        firstLoading: false
      })
    })
  }

  get tableColumns () {
    return [{
      title: '时间',
      dataIndex: 'createTime',
      render: (createTime) => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '类型',
      dataIndex: 'type'
    }, {
      title: '数额',
      dataIndex: 'beans',
      render: (beans, record) => {
        if (beans > 0) {
          return <span className='bean-income'>+{beans}</span>
        } else {
          return <span className='bean-consume'>{beans}{record.isRefunded ? <span className='bean-income'>(已退款)</span> : null}</span>
        }
      }
    }, {
      title: '余额',
      dataIndex: 'beansAfter'
    }, {
      title: '详情',
      dataIndex: 'remark'
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.transactions.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchBeanTransactions({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchBeanTransactions({
      offset,
      limit
    })
  }

  handleChangeDateRange = (dates) => {
    const beginTime = dates[0]
    const endTime = dates[1]
    this.setState({
      beginTime,
      endTime
    })
  }

  handleShowBeansConsumeRulesDrawer = () => {
    this.setState({
      beansConsumeRulesDrawerVisible: true
    })
  }

  handleBeansConsumeRulesDrawerClose = () => {
    this.setState({
      beansConsumeRulesDrawerVisible: false
    })
  }

  handleShowChargeDrawer = () => {
    this.setState({
      chargeDrawerVisible: true
    })
  }

  handleChargeDrawerClose = () => {
    this.setState({
      chargeDrawerVisible: false
    })
  }

  handleClickSearch = () => {
    this.setState({
      current: 1,
      offset: 0,
      limit: 10
    })
    this.searchBeanTransactions({offset: 0, limit: 10})
  }

  render () {
    const {beginTime, endTime, showCharge, showConsume, showGift, showRefund, firstLoading} = this.state

    return (
      <div className='content'>
        <Form layout='inline' style={{marginBottom: '12px'}}>
          <Form.Item>
            <DatePicker.RangePicker showTime={false} format={'YYYY-MM-DD'} onChange={this.handleChangeDateRange} value={[beginTime ? moment(beginTime) : null, endTime ? moment(endTime) : null]}/>
          </Form.Item>
          <Form.Item>
            <div className='beans-transaction-types'>
              <Checkbox onChange={e => this.setState({showCharge: e.target.checked})} checked={showCharge}>充值</Checkbox>
              <Checkbox onChange={e => this.setState({showConsume: e.target.checked})} checked={showConsume}>消耗</Checkbox>
              <Checkbox onChange={e => this.setState({showRefund: e.target.checked})} checked={showRefund}>退款</Checkbox>
              <Checkbox onChange={e => this.setState({showGift: e.target.checked})} checked={showGift}>赠送</Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Button type='primary' icon='search' onClick={this.handleClickSearch}>查询明细</Button>
          </Form.Item>
          <Form.Item>
            <Button type='default' icon='pay-circle' onClick={this.handleShowChargeDrawer}>余额充值<span style={{color: '#52c41a'}}>[当前余额: {this.props.beans}]</span></Button>
          </Form.Item>
          <Form.Item>
            <Button type='default' icon='alert' onClick={this.handleShowBeansConsumeRulesDrawer}>查看余额消耗规则</Button>
          </Form.Item>
        </Form>
        <Table
          columns={this.tableColumns}
          dataSource={firstLoading ? [] : this.tableData}
          size='small'
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.transactions.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
        <Drawer
            title='余额消耗规则'
            placement='right'
            closable={false}
            width = {400}
            onClose={this.handleBeansConsumeRulesDrawerClose}
            visible={this.state.beansConsumeRulesDrawerVisible}
          >
            <BeansConsumeRules />
          </Drawer>
        <Drawer
            title="余额充值"
            placement="right"
            closable={false}
            width = {400}
            onClose={this.handleChargeDrawerClose}
            visible={this.state.chargeDrawerVisible}
          >
            <Charge />
          </Drawer>
      </div>
    )
  }
}

BeanTransactions = connect((state) => {
  return {
    beans: state.auth.beans,
    transactions: state.bean.transactions
  }
})(BeanTransactions)

export default BeanTransactions
